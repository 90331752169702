<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            Fotos Anteriores
          </v-col>
          <v-col cols="12" sm="6" md="6" class="text-right">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brand"
                      :loading="isBrandSearching"
                      :items="filtered_brands"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="selected.fotosAnteriosPdfFile.file"
                      accept=".pdf,.ppt,.pptx"
                      required
                      :rules="[fileRules]"
                    >
                      <template
                        v-if="
                          selected.fotosAnteriosPdfFile.fotosAnteriosPdfFile
                        "
                        #label
                      >
                        {{
                          selected.fotosAnteriosPdfFile.fotosAnteriosPdfFile
                            .file
                        }}
                      </template>
                    </v-file-input>
                    <v-btn
                      v-if="selected.fotosAnteriosPdfFile.fotosAnteriosPdfFile"
                      color="blue darken-1"
                      class="mb-2"
                      @click="
                        downloadPDF(
                          selected.fotosAnteriosPdfFile.fotosAnteriosPdfFile
                        )
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ `${$t("Download")} ${$t("PDF File")}` }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_start"
                      v-model="date_menu_start"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startDate"
                          v-bind:label="$t('start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startDate"
                        no-title
                        @input="date_menu_start = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_start"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_end"
                      v-model="date_menu_end"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endDate"
                          v-bind:label="$t('end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endDate"
                        no-title
                        @input="date_menu_end = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_end"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.status"
                      v-bind:label="$t('salesforce.status')"
                      :items="status_type"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row v-if="selected.id">
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="mt-2 text-center">
                      <v-col cols="12" sm="12" md="12">
                        <v-btn
                          color="success"
                          dark
                          class="mb-2 ml-2"
                          :loading="isSelectingIdPOSperBrand"
                          @click="onUploadExcelIdPOSperBrand"
                        >
                          <b-icon
                            icon="file-excel-fill"
                            aria-hidden="true"
                          ></b-icon>
                          {{ buttonTextIdPOSperLabel }}
                        </v-btn>
                        <input
                          ref="uploader_idpos_per_brand"
                          class="d-none"
                          type="file"
                          accept=".xls,.xlsx"
                          @change="onFileChangedIdPOSperBrand"
                        />
                        <v-btn
                          color="warning"
                          dark
                          class="mb-2 ml-2"
                          @click="downloadexcelposes"
                        >
                          <b-icon
                            icon="file-excel-fill"
                            aria-hidden="true"
                          ></b-icon>
                          Export
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-data-table
                          :headers="posTableColumns"
                          :items="posTableData"
                          :items-per-page="10"
                          :footer-props="{
                            'items-per-page-options': [10, 50, 100]
                          }"
                          :options.sync="options"
                          class="elevation-1"
                        >
                          <template
                            v-for="(col, i) in filters"
                            v-slot:[`header.${i}`]="{ header }"
                          >
                            <div
                              style="display: inline-block; padding: 16px 0;"
                              v-bind:key="i"
                            >
                              <span>{{ header.text }}</span>
                            </div>
                            <div
                              style="float: right; margin-top: 8px"
                              v-bind:key="`A` + i"
                            >
                              <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-y
                                transition="slide-y-transition"
                                left
                                fixed
                                style="position: absolute; right: 0"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="indigo"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon
                                      small
                                      :color="
                                        activeFilters[header.value] &&
                                        activeFilters[header.value].length <
                                          filters[header.value].length
                                          ? 'red'
                                          : 'default'
                                      "
                                    >
                                      mdi-filter-variant
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list flat dense class="pa-0">
                                  <v-list-item-group
                                    multiple
                                    v-model="activeFilters[header.value]"
                                    class="py-2"
                                  >
                                    <div class="search_input">
                                      <v-text-field
                                        hide-details
                                        v-model="searchString[header.value]"
                                        placeholder="Search ..."
                                        class="pl-2 pr-2"
                                        @input="searchItem(header.value)"
                                      ></v-text-field>
                                    </div>
                                    <template
                                      v-for="(item, j) in filters[header.value]"
                                    >
                                      <v-list-item
                                        v-bind:key="`A${j}`"
                                        :value="item"
                                        :ripple="false"
                                      >
                                        <template
                                          v-slot:default="{ active, toggle }"
                                        >
                                          <v-list-item-action>
                                            <v-checkbox
                                              :input-value="active"
                                              :true-value="item"
                                              @click="toggle"
                                              color="primary"
                                              :ripple="false"
                                              dense
                                            ></v-checkbox>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              v-text="item"
                                            ></v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-list-item>
                                    </template>
                                  </v-list-item-group>
                                  <v-row no-gutters>
                                    <v-col cols="6">
                                      <v-btn
                                        text
                                        block
                                        @click="toggleAll(header.value)"
                                        color="success"
                                        >{{ $t("toggleall") }}</v-btn
                                      >
                                    </v-col>
                                    <v-col cols="6">
                                      <v-btn
                                        text
                                        block
                                        @click="clearAll(header.value)"
                                        color="warning"
                                        >{{ $t("clearall") }}</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-list>
                              </v-menu>
                            </div>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(selected)"
                :loading="isSaving"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-if="copyItemId > 0" v-model="dialogCopy" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">Copy</v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="copyBrandIds"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brand"
                    :loading="isBrandSearching"
                    :items="filtered_brands"
                    hide-no-data
                    multiple
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeCopyDialog">{{
              $t("cancel")
            }}</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="copyItemConfirm"
              :loading="isBtnCopying"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "Anteriores",
  components: {},
  data: function() {
    return {
      status_type: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ],
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      copyItemId: -1,
      copyBrandIds: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** create or edit dialog options **********/
      showEdit: false,
      dialogCopy: false,
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],

      isSaving: false,
      isBtnCopying: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,

      totaltableData: 0,
      tableData: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "brand",
        "description",
        "startDate",
        "endDate",
        "status"
      ],
      filterCriteria: {
        brand: {},
        description: {},
        startDate: {},
        endDate: {},
        status: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      showEmpty: false,

      // postable in dialog
      labelPosArr: [],
      posTableData: [],
      tempdata: [],
      originalposTableData: [],
      options: {},
      filters: { id: [], name: [], status: [] },
      initFilterList: { id: [], name: [], status: [] },
      activeFilters: {},
      searchString: { id: "", name: "", status: "" },

      isSelectingIdPOSperBrand: false,
      selectedFileIdposperBrand: null,
      defaultbuttonTextIdPOSperBrand: "Import"
    };
  },
  watch: {
    dialogCopy(val) {
      val || this.closeCopyDialog();
    },
    async search_brand(val) {
      this.search_brand_list(val);
    },
    options: {
      handler() {
        this.sortPosTableChange();
      },
      deep: true
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    buttonTextIdPOSperLabel() {
      return this.isSelectingIdPOSperBrand
        ? "Uploading..."
        : this.defaultbuttonTextIdPOSperBrand;
    },
    columns() {
      return [
        {
          key: "brandId",
          title: `ID ${this.$t("brands.brand")}`,
          field: "brandId",
          align: "left",
          width: 100,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "brand",
          title: `${this.$t("brands.brand")} ${this.$t("brands.name")}`,
          field: "brand",
          align: "left",
          width: 150,
          sortBy: "",
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`brands/anterios/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200
          // sortBy: "",
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <filterableAjaxCheckboxes
          //           v-model={this.filterCriteria["description"]}
          //           apiUrl={`brands/anterios/getfilterlist`}
          //           columnName={`description`}
          //           isFullText={false}
          //           filterCriteria={this.filterCriteria["description"]}
          //         />
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "description")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "startDate",
          title: this.$t("start_date"),
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endDate",
          title: this.$t("end_date"),
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "pdfFile",
          title: this.$t("PDF File"),
          field: "fotosAnteriosPdfFile",
          align: "center",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row.fotosAnteriosPdfFile && (
                  <v-btn
                    color="primary"
                    dark
                    fab
                    on-click={() => this.downloadPDF(row.fotosAnteriosPdfFile)}
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                  </v-btn>
                )}
              </div>
            );
          }
        },
        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        },
        {
          key: "copy",
          title: this.$t("copy"),
          field: "copy",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  dark
                  color="success darken-1"
                  text
                  on-click={() => this.onItemCopy(row.id)}
                >
                  <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    },
    posTableColumns() {
      return [
        {
          text: "IdPOS",
          value: "id",
          width: "25%",
          filter: value => {
            return this.activeFilters.id
              ? this.activeFilters.id.includes(value)
              : true;
          }
        },
        {
          text: "POS " + this.$t("brands.name"),
          value: "name",
          width: "50%",
          filter: value => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          }
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "25%",
          filter: value => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          }
        }
      ];
    }
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.posTableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "id") {
          this.filters[col] = this.initFilterList[col].filter(
            item => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter(item =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.posTableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortPosTableChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.posTableData = this.tempdata;
      }
    },
    initTable(newVal) {
      this.posTableData = newVal;
      this.originalposTableData = newVal;
      this.tempdata = newVal;
      this.initFilters();
    },
    initPosTable(data) {
      this.initTable(data);
    },
    onUploadExcelIdPOSperBrand() {
      this.isSelectingIdPOSperBrand = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdPOSperBrand = false;
        },
        { once: false }
      );
      this.$refs.uploader_idpos_per_brand.click();
    },
    async onFileChangedIdPOSperBrand(e) {
      this.selectedFileIdposperBrand = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingIdPOSperBrand = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperBrand);
        this.$refs.uploader_idpos_per_brand.value = "";
        try {
          const response = await ApiService.post(
            `brands/anterios/uploadexcelIdposperbrand/` + this.selected.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
            let selectedIndex = this.tableData.findIndex(
              el => el.id === this.selected.id
            );
            if (selectedIndex >= 0) {
              this.createSelectedModel(this.tableData[selectedIndex]);
            }
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdPOSperBrand = false;
        } catch (error) {
          console.log(error);
          this.isSelectingIdPOSperBrand = false;
        }
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#data-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/anterios";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        status: _.get(item, "status"),
        description: _.get(item, "description"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        pdfFile: _.get(item, "pdfFile"),
        fotosAnteriosPdfFile: {
          file: null,
          fotosAnteriosPdfFile: _.get(item, "fotosAnteriosPdfFile")
        },
        brand_fotos_anteriores_pos: _.get(item, "brand_fotos_anteriores_pos")
      };
      if (model.brand_fotos_anteriores_pos) {
        model.brand_fotos_anteriores_pos = model.brand_fotos_anteriores_pos.map(
          el => {
            return { ...el.po, status: el.status };
          }
        );
        this.initPosTable(model.brand_fotos_anteriores_pos);
      }
      this.search_brand_list("");
      console.log(model);
      return model;
    },
    onItemEdit(item) {
      console.log(item);
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onChangePDFFile(file) {
      this.selected.fotosAnteriosPdfFile.file = file;
    },
    async search_brand_list(val) {
      let fetch_url = `brands/anterios/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["brand", "fotosAnteriosPdfFile"]);
        try {
          this.isSaving = true;
          let status = null;
          let fotosAnteriosPdfFile = item.fotosAnteriosPdfFile.file;
          if (fotosAnteriosPdfFile) {
            let { data } = await ApiService.uploadFile(fotosAnteriosPdfFile);
            body.pdfFile = data.id;
          } else if (!item.fotosAnteriosPdfFile.fotosAnteriosPdfFile) {
            logError("Se requiere un archivo PDF.");
            this.isSaving = false;
            return;
          }
          console.log(body);
          if (body.id) {
            status = await ApiService.put(`brands/anterios/${body.id}`, body);
          } else {
            status = await ApiService.post(`brands/anterios`, body);
          }
          logInfo("éxito");
          this.showEdit = false;
          this.isSaving = false;
          this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },
    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      if (this.deleteItemId > 0 && window.confirm(this.$t("confirmdelete"))) {
        ApiService.delete(`brands/anterios/${this.deleteItemId}`).then(() => {
          logInfo("eliminada!");
          this.getDataFromApi();
        });
      }
    },
    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    async onItemCopy(id) {
      this.copyItemId = id;
      this.copyBrandIds = [];
      this.dialogCopy = true;
    },
    closeCopyDialog() {
      this.dialogCopy = false;
      this.$nextTick(() => {
        this.copyItemId = -1;
      });
    },
    async copyItemConfirm() {
      if (this.copyItemId > 0 && this.copyBrandIds.length > 0) {
        this.isBtnCopying = true;
        try {
          let status = await ApiService.post(
            `brands/anterios/copydata/${this.copyItemId}`,
            {
              brandIds: this.copyBrandIds
            }
          );
          this.isBtnCopying = false;
          this.closeCopyDialog();
          if (status) {
            const { success, message, data } = status;
            if (success) {
              logInfo("éxito");
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
        } catch (error) {
          this.closeCopyDialog();
          this.isBtnCopying = false;
        }
      } else {
        logError(this.$t("You must select one more brand."));
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async downloadexcelposes() {
      let fetch_url = `brands/anterios/downloadexcelposes/${this.selected.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "data.xlsx");
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
