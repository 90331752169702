<template>
  <div class="mb-2">
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <div class="title_left">
              <span class="text-h5">{{ formTitle }}</span>
            </div>
            <div class="title_right">
              <v-btn
                :color="current_approvalStatus_class"
                dark
                large
                class="btn_status"
              >
                {{ selected.status }}
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row v-if="selected.initialStatus === 'Pdte Firma Empleado'">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-btn
                    color="blue darken-1"
                    @click="downloadDocTemplate(selected.id)"
                    :loading="isDownloadTemplate"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ $t("Download Document to Sign") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="selected.initialStatus !== 'Pdte Aprob Gastos'">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-file-input
                    show-size
                    v-bind:label="$t('Upload Signed Document')"
                    @change="onChangeSignedDocument"
                    v-model="selected.attachment_document.file"
                    accept=".pdf,image/jpeg,image/png"
                    v-if="
                      !selected.isChecking &&
                        (selected.initialStatus === 'Pdte Firma Empleado' ||
                          selected.initialStatus === 'Incidencia')
                    "
                    :rules="[fileRules]"
                  ></v-file-input>
                  <v-btn
                    v-if="selected.attachment_document.lqdocument !== null"
                    color="blue darken-1"
                    @click="
                      downloadSignedDoc(selected.attachment_document.lqdocument)
                    "
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ $t("Download Signed Document") }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  selected.initialStatus !== 'Pdte Aprob Gastos' ||
                    selected.initialStatus !== 'Pdte Firma Empleado'
                "
              >
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-file-input
                    show-size
                    v-bind:label="$t('Upload Responsable Signed Document')"
                    @change="onChangeResponsableSignedDocument"
                    v-model="selected.responsable_sign_document.file"
                    accept=".pdf,image/jpeg,image/png"
                    v-if="
                      selected.isChecking &&
                        selected.initialStatus === 'Pdte Firma Responsable'
                    "
                    :rules="[fileRules]"
                  ></v-file-input>
                  <v-btn
                    v-if="
                      selected.responsable_sign_document.responsabledocument !==
                        null
                    "
                    color="blue darken-1"
                    class="mb-2"
                    @click="
                      downloadSignedDoc(
                        selected.responsable_sign_document.responsabledocument
                      )
                    "
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ $t("Download Responsable Signed Document") }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.gpv_comment"
                    v-bind:label="$t('comments') + ' ' + $t('User')"
                    :readonly="selected.isChecking"
                    ref="textarea_gpv_comment"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (selected.isChecking ? 'readonly' : '')
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="selected.isChecking && selected.isEditable">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.status"
                    v-bind:label="$t('salesforce.status')"
                    :items="liquidationStatus"
                    item-text="name"
                    filled
                    required
                    :rules="requiredRules"
                    @change="onChangeApprovalStatus(selected.status)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selected.id !== undefined">
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.spv_comment"
                    v-bind:label="
                      $t('comments') + ' ' + $t('expensesParentUserName')
                    "
                    :readonly="
                      !selected.isChecking ||
                        (selected.isChecking && !selected.isEditable)
                    "
                    ref="textarea_spv_comment"
                    v-bind:class="
                      'spv_comment_text ' +
                        (!selected.isChecking ||
                        (selected.isChecking && !selected.isEditable)
                          ? 'readonly'
                          : '')
                    "
                    :required="
                      selected.isChecking && selected.status === 'Incidencia'
                    "
                    :rules="
                      selected.isChecking && selected.status === 'Incidencia'
                        ? requiredRules
                        : []
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-if="selected && selected_image"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="downloadImage(selected, 'startPhoto')"
          >
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ $t("Download") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeImageDialog">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExportSage" max-width="700px">
      <v-card>
        <v-card-title class="text-h6"
          >{{ $t("Choose the month and year of the export") }} -
          SAGE</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-autocomplete
                  v-model="selectedSage.companyCode"
                  :label="$t('Company')"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  filled
                  clearable
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-checkbox
                  v-bind:label="$t('Active User')"
                  v-model="selectedSage.isOnlyActiveUsers"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="6" md="5" offset-md="1">
                <v-select
                  v-model="selectedSage.month"
                  item-text="name"
                  item-value="id"
                  :items="month_list"
                  :hide-details="true"
                  filled
                  label=""
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="selectedSage.year"
                  :items="year_list"
                  :hide-details="true"
                  filled
                  label=""
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogExportSage">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDownloadSage"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExportRRHH" max-width="700px">
      <v-card>
        <v-card-title class="text-h6"
          >{{ $t("Choose the month and year of the export") }} -
          RRHH</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-autocomplete
                  v-model="selectedRRHH.companyCode"
                  :label="$t('Company')"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  filled
                  clearable
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-checkbox
                  v-bind:label="$t('Active User')"
                  v-model="selectedRRHH.isOnlyActiveUsers"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="6" md="5" offset-md="1">
                <v-select
                  v-model="selectedRRHH.month"
                  item-text="name"
                  item-value="id"
                  :items="month_list"
                  :hide-details="true"
                  filled
                  label=""
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="selectedRRHH.year"
                  :items="year_list"
                  :hide-details="true"
                  filled
                  label=""
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogExportRRHH">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDownloadRRHH"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExportPDF" max-width="700px">
      <v-card>
        <v-card-title class="text-h6">{{
          $t("Choose the month and year of the export")
        }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-autocomplete
                  v-model="selectedPDF.companyCode"
                  :label="$t('Company')"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  filled
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <v-autocomplete
                  v-model="selectedPDF.users"
                  :label="$t('Employee')"
                  :items="liquidation_user_list"
                  item-text="name"
                  item-value="id"
                  filled
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelectAllEmployees">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedPDF.users.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Todos Empleados</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <p class="mb-0">{{ $t("Start") }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="6" md="5" offset-md="1">
                <v-select
                  v-model="selectedPDF.start_month"
                  item-text="name"
                  item-value="id"
                  :items="month_list"
                  :hide-details="true"
                  filled
                  label=""
                  @change="onChangePeriodPDFStart"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="selectedPDF.start_year"
                  :items="year_list"
                  :hide-details="true"
                  filled
                  label=""
                  @change="onChangePeriodPDFStart"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="12" md="10" offset-md="1">
                <p class="mb-0">{{ $t("End") }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" offset-sm="0" sm="6" md="5" offset-md="1">
                <v-select
                  v-model="selectedPDF.end_month"
                  item-text="name"
                  item-value="id"
                  :items="month_list"
                  :hide-details="true"
                  filled
                  label=""
                  @change="onChangePeriodPDFEnd"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-select
                  v-model="selectedPDF.end_year"
                  :items="year_list"
                  :hide-details="true"
                  filled
                  label=""
                  @change="onChangePeriodPDFEnd"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogExportPDF">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDownloadPDF"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <h4>{{ this.$t("Liquidations") }}</h4>
          </v-col>
          <v-col cols="12" sm="12" md="7" class="status_wrapper">
            <statistics :data="statistics_data_liquidation" />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-right">
                <h5 v-if="user.role === 'gpv'">
                  username: {{ isAuthenticated && user.username }}
                </h5>
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="downloadExcel"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 ml-2"
                  @click="onExportPDF"
                  v-if="isAdmin || isSubAdmin"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export PDF
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="onExportRRHH"
                  v-if="isAdmin || isSubAdmin"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export RRHH
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="mb-2 ml-2"
                  @click="onExportSage"
                  v-if="isAdmin || isSubAdmin"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export SAGE
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <input
              ref="uploader_signed_doc"
              class="d-none"
              type="file"
              accept=".pdf,image/jpeg,image/png"
              @change="onFileUploadSignedDocChanged"
            />
            <input
              ref="uploader_responsable_signed_doc"
              class="d-none"
              type="file"
              accept=".pdf,image/jpeg,image/png"
              @change="onFileUploadResonsableSignedDocChanged"
            />
            <ve-table
              id="liquidation-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="isteam === 'my' ? columns : spv_columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import download from "js-file-download";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import statistics from "./statistics";

export default {
  name: "liquidation",
  props: ["isteam"],
  components: {
    CoolLightBox,
    statistics
  },
  data() {
    return {
      lightboxindex: null,
      lightboxresponsabledocindex: null,
      status_type: [
        "Pdte Aprob Gastos",
        "Pdte Firma Empleado",
        "Pdte Firma Responsable",
        "Incidencia",
        "Aprobada"
      ],
      user_roles: [],
      user_status: [],
      expenseTypes: [],
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        year: {},
        month: {},
        companyName: {},
        username: {},
        parent_username: {},
        approvername: {},
        user_status: {},
        status: {},
        km_total: {},
        km_pending_approval: {},
        expense_total: {},
        expense_pending_approval: {},
        parentId: {},
        total_approved_amount: {},
        usertype: {},
        solredAmount: {}
      },
      filterable_col_list: [
        "year",
        "month",
        "companyName",
        "username",
        "usertype",
        "parent_username",
        "approvername",
        "user_status",
        "gpv_comment",
        "spv_comment",
        "status",
        "km_total",
        "km_pending_approval",
        "expense_total",
        "expense_pending_approval",
        "parentId",
        "total_approved_amount",
        "solredAmount"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.status === "Aprobada") {
            return "table-body-cell-approved";
          } else if (row.status === "Incidencia") {
            return "table-body-cell-incidence";
          }
        }
      },
      showEmpty: false,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      kmRules: [
        v => !!v || "Required",
        v => (v && v >= 0) || "Este campo debe estar por encima de 0"
      ],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },
      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      search_route: null,
      isrouteSearching: false,
      filtered_routes: [],
      select_date_menu: false,
      nowDate: new Date().toISOString().slice(0, 10),
      reactive: false,
      current_approvalStatus_class: "error",
      isSaving: false,

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      isDownloadTemplate: false,

      filterable_parent_list: [],
      companies: [],
      liquidation_user_list: [],

      /********** upload options on table **********/
      seleted_upload_document_row: null,

      /********** dialog export all pdf files **********/
      dialogExportPDF: false,
      selectedPDF: {
        companyCode: null,
        start_month: 1,
        start_year: 2022,
        end_month: 1,
        end_year: 2022,
        users: []
      },

      /********** dialog sage **********/
      dialogExportSage: false,
      month_list: [
        { id: 1, name: "ENE" },
        { id: 2, name: "FEB" },
        { id: 3, name: "MAR" },
        { id: 4, name: "ABR" },
        { id: 5, name: "MAY" },
        { id: 6, name: "JUN" },
        { id: 7, name: "JUL" },
        { id: 8, name: "AGO" },
        { id: 9, name: "SEPT" },
        { id: 10, name: "OCT" },
        { id: 11, name: "NOV" },
        { id: 12, name: "DIC" }
      ],
      year_list: [],
      selectedSage: {
        month: 1,
        year: 2022,
        companyCode: null,
        isOnlyActiveUsers: false
      },
      dialogExportRRHH: false,
      selectedRRHH: {
        month: 1,
        year: 2022,
        companyCode: null,
        isOnlyActiveUsers: false
      }
    };
  },
  watch: {
    $route(to, from) {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = Object.assign({});
      }
      this.getDataFromApi();
    },
    dialogExportSage(val) {
      val || this.closeDialogExportSage();
    },
    dialogExportPDF(val) {
      val || this.closeDialogExportPDF();
    },
    dialogExportRRHH(val) {
      val || this.closeDialogExportRRHH();
    }
  },
  computed: {
    icon() {
      if (this.likesAllEmployee) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllEmployee() {
      return (
        this.selectedPDF.users.length === this.liquidation_user_list.length
      );
    },
    liquidationStatus() {
      return [
        { name: "Pdte Aprob Gastos", disabled: true },
        { name: "Pdte Firma Empleado", disabled: true },
        { name: "Pdte Firma Responsable", disabled: true },
        { name: "Incidencia" },
        { name: "Aprobada" }
      ];
    },
    ...mapGetters("auth", [
      "isAuthenticated",
      "isGPV",
      "isAdmin",
      "isSubAdmin",
      "user"
    ]),
    ...mapGetters("expenses", ["statistics_data_liquidation"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem") +
            " - " +
            this.selected.year +
            "/" +
            this.selected.month +
            " " +
            this.selected.user.name;
    },
    col_year() {
      return {
        key: "year",
        title: this.$t("Year"),
        field: "year",
        align: "left",
        width: 100,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["from"]}
                  value={this.filterCriteria["year"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["to"]}
                  value={this.filterCriteria["year"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "year")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_month() {
      return {
        key: "month",
        title: this.$t("Month"),
        field: "month",
        align: "left",
        width: 100,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["from"]}
                  value={this.filterCriteria["month"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["to"]}
                  value={this.filterCriteria["month"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "month")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_company() {
      return {
        key: "companyName",
        title: "Nombre de empresa",
        field: "companyName",
        align: "left",
        width: 200,
        sortBy: "ASC",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.companyName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["companyName"]}
                  apiUrl={`expenses/liquidations/getfilterlist/${this.isteam}`}
                  columnName={`companyName`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["companyName"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "companyName")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_username() {
      return {
        key: "username",
        title: this.$t("salesforce.UserName"),
        field: "username",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["username"]}
                  apiUrl={`expenses/liquidations/getfilterlist/${this.isteam}`}
                  columnName={`username`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["username"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "username")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_userstatus() {
      return {
        key: "user_status",
        title: `${this.$t("salesforce.status")} usuario`,
        field: "user_status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && this.gennera_user_status[row.user.status]}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["user_status"]}
                  dataList={this.user_status}
                  filterCriteria={this.filterCriteria["user_status"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user_status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_usertype() {
      return {
        key: "usertype",
        title: this.$t("salesforce.user_type"),
        field: "usertype",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user && row.user.role}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["usertype"]}
                  dataList={this.user_roles}
                  filterCriteria={this.filterCriteria["usertype"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "usertype")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_parentId() {
      return {
        key: "parentId",
        title: this.$t("expensesParentUserName"),
        field: "parentId",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.user &&
                row.user.Parent &&
                row.user.Parent.name + " " + row.user.Parent.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["parentId"]}
                  apiUrl={`expenses/liquidations/getfilterlist/${this.isteam}`}
                  columnName={`parentId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["parentId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "parentId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_km_total() {
      return {
        key: "km_total",
        title: "KM " + this.$t("approved"),
        field: "km_total",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {this.currency_format(row.km_total_currency)} ({row.km_total} KM)
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["km_total"]["from"]}
                  value={this.filterCriteria["km_total"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["km_total"]["to"]}
                  value={this.filterCriteria["km_total"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "km_total")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_km_pending_approval() {
      return {
        key: "km_pending_approval",
        title: "KM " + this.$t("pendingapproval"),
        field: "km_pending_approval",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {this.currency_format(row.km_pendingapproval_currency)} (
              {row.km_pending_approval} KM)
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["km_pending_approval"]["from"]}
                  value={this.filterCriteria["km_pending_approval"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["km_pending_approval"]["to"]}
                  value={this.filterCriteria["km_pending_approval"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "km_pending_approval")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_expense_total() {
      return {
        key: "expense_total",
        title: this.$t("Other Expenses") + " " + this.$t("approved"),
        field: "expense_total",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.currency_format(row.expense_total)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["expense_total"]["from"]}
                  value={this.filterCriteria["expense_total"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["expense_total"]["to"]}
                  value={this.filterCriteria["expense_total"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "expense_total")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_expense_pending_approval() {
      return {
        key: "expense_pending_approval",
        title: this.$t("Other Expenses") + " " + this.$t("pendingapproval"),
        field: "expense_pending_approval",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{this.currency_format(row.expense_pending_approval)}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={
                    this.filterCriteria["expense_pending_approval"]["from"]
                  }
                  value={
                    this.filterCriteria["expense_pending_approval"]["from"]
                  }
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={
                    this.filterCriteria["expense_pending_approval"]["to"]
                  }
                  value={this.filterCriteria["expense_pending_approval"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "expense_pending_approval")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_solredAmount() {
      return {
        key: "solredAmount",
        title: "Solred " + this.$t("amount"),
        field: "solredAmount",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.currency_format(row.solredAmount)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["solredAmount"]["from"]}
                  value={this.filterCriteria["solredAmount"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["solredAmount"]["to"]}
                  value={this.filterCriteria["solredAmount"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "solredAmount")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_total_approved_amount() {
      return {
        key: "total_approved_amount",
        title: "Total " + this.$t("amount"),
        field: "total_approved_amount",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.currency_format(row.total_approved_amount)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["total_approved_amount"]["from"]}
                  value={this.filterCriteria["total_approved_amount"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["total_approved_amount"]["to"]}
                  value={this.filterCriteria["total_approved_amount"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "total_approved_amount")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_attachment_document_download() {
      return {
        key: "attachment_document_download",
        title: this.$t("Liquidation Download"),
        field: "attachment_document",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="primary"
                dark
                fab
                on-click={() => this.downloadDocTemplate(row.id)}
              >
                <b-icon icon="download" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_my_attachment_document_upload_preview() {
      return {
        key: "attachment_document_upload_preview",
        title: this.$t("Upload Signed Document"),
        field: "attachment_document",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.lqdocument !== null &&
                row.status !== "Pdte Aprob Gastos" &&
                row.status !== "Pdte Firma Empleado" &&
                row.lqdocumentext &&
                row.lqdocumentext !== "pdf" && (
                  <div class="liq_image_wrapper">
                    <img
                      src={this.getFileImageUri(row.lqdocument)}
                      alt=""
                      on-click={() =>
                        this.onItemShowImageDialog(row, row.lqdocument)
                      }
                    />
                    {row.status !== "Aprobada" && (
                      <v-btn
                        class="btn_remove_image"
                        color="pink"
                        fab
                        x-small
                        dark
                        on-click={() => this.deleteDocument(row.id)}
                      >
                        <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                      </v-btn>
                    )}
                  </div>
                )}
              {row.lqdocument !== null &&
                row.status !== "Pdte Aprob Gastos" &&
                row.status !== "Pdte Firma Empleado" &&
                row.lqdocumentext &&
                row.lqdocumentext === "pdf" && (
                  <div class="liq_image_wrapper">
                    <v-btn
                      color="warning"
                      dark
                      fab
                      on-click={() => this.downloadSignedDoc(row.lqdocument)}
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                    </v-btn>
                    {row.status !== "Aprobada" && (
                      <v-btn
                        color="pink"
                        class="ml-2"
                        fab
                        dark
                        on-click={() => this.deleteDocument(row.id)}
                      >
                        <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                      </v-btn>
                    )}
                  </div>
                )}
              {row.lqdocument === null &&
                (row.status === "Pdte Firma Empleado" ||
                  row.status === "Incidencia") && (
                  <div>
                    <v-btn
                      color="success"
                      class="mb-2"
                      fab
                      dark
                      on-click={() => this.onButtonClickUploadSignedDoc(row)}
                    >
                      <b-icon icon="upload" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                )}
            </div>
          );
        }
      };
    },
    col_team_attachment_document_upload_preview() {
      return {
        key: "attachment_document_upload_preview",
        title: this.$t("Upload Signed Document"),
        field: "attachment_document",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.lqdocument !== null &&
                row.lqdocumentext &&
                row.lqdocumentext !== "pdf" && (
                  <div class="liq_image_wrapper">
                    <img
                      src={this.getFileImageUri(row.lqdocument)}
                      alt=""
                      on-click={() =>
                        this.onItemShowImageDialog(row, row.lqdocument)
                      }
                    />
                  </div>
                )}
              {row.lqdocument !== null &&
                row.lqdocumentext &&
                row.lqdocumentext === "pdf" && (
                  <div class="liq_image_wrapper">
                    <v-btn
                      color="warning"
                      dark
                      fab
                      on-click={() => this.downloadSignedDoc(row.lqdocument)}
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                )}
              {row.lqdocument === null &&
                row.userId === this.user.id &&
                (row.status === "Pdte Firma Empleado" ||
                  row.status === "Incidencia") && (
                  <div>
                    <v-btn
                      color="success"
                      class="mb-2"
                      fab
                      dark
                      on-click={() => this.onButtonClickUploadSignedDoc(row)}
                    >
                      <b-icon icon="upload" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                )}
            </div>
          );
        }
      };
    },
    col_my_responsable_signed_document() {
      return {
        key: "responsable_signed_document",
        title: this.$t("Download Responsable Signed Document"),
        field: "responsable_signed_document",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.responsabledocument !== null &&
                row.responsabledocumentext &&
                row.responsabledocumentext !== "pdf" && (
                  <div class="liq_image_wrapper">
                    <img
                      src={this.getFileImageUri(row.responsabledocument)}
                      alt=""
                      on-click={() =>
                        this.onItemShowImageDialog(row, row.responsabledocument)
                      }
                    />
                  </div>
                )}
              {row.responsabledocument !== null &&
                row.responsabledocumentext &&
                row.responsabledocumentext === "pdf" && (
                  <div class="liq_image_wrapper">
                    <v-btn
                      color="warning"
                      dark
                      fab
                      on-click={() =>
                        this.downloadSignedDoc(row.responsabledocument)
                      }
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                )}
            </div>
          );
        }
      };
    },
    col_team_responsable_signed_document() {
      return {
        key: "responsable_signed_document",
        title: this.$t("Download Responsable Signed Document"),
        field: "responsable_signed_document",
        align: "center",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.responsabledocument !== null &&
                row.responsabledocumentext &&
                row.responsabledocumentext !== "pdf" && (
                  <div class="liq_image_wrapper">
                    <img
                      src={this.getFileImageUri(row.responsabledocument)}
                      alt=""
                      on-click={() =>
                        this.onItemShowImageDialog(row, row.responsabledocument)
                      }
                    />
                    {row.status !== "Aprobada" && row.userId !== this.user.id && (
                      <v-btn
                        class="btn_remove_image"
                        color="pink"
                        fab
                        x-small
                        dark
                        on-click={() =>
                          this.deleteResponsibleSignedDocument(row.id)
                        }
                      >
                        <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                      </v-btn>
                    )}
                  </div>
                )}
              {row.responsabledocument !== null &&
                row.responsabledocumentext &&
                row.responsabledocumentext === "pdf" && (
                  <div class="liq_image_wrapper">
                    <v-btn
                      color="warning"
                      dark
                      fab
                      on-click={() =>
                        this.downloadSignedDoc(row.responsabledocument)
                      }
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                    </v-btn>
                    {row.status !== "Aprobada" && row.userId !== this.user.id && (
                      <v-btn
                        color="pink"
                        class="ml-2"
                        fab
                        dark
                        on-click={() =>
                          this.deleteResponsibleSignedDocument(row.id)
                        }
                      >
                        <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                      </v-btn>
                    )}
                  </div>
                )}
              {row.responsabledocument === null &&
                row.userId !== this.user.id &&
                (row.status === "Pdte Firma Responsable" ||
                  row.status === "Incidencia") && (
                  <div>
                    <v-btn
                      color="success"
                      class="mb-2"
                      fab
                      dark
                      on-click={() =>
                        this.onButtonClickUploadResonsableSignedDoc(row)
                      }
                    >
                      <b-icon icon="upload" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                )}
            </div>
          );
        }
      };
    },
    col_gpv_comment() {
      return {
        key: "gpv_comment",
        title: this.$t("comments") + " " + this.$t("User"),
        field: "gpv_comment",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["gpv_comment"]}
                  on-input={value =>
                    (this.filterCriteria["gpv_comment"] = value)
                  }
                  placeholder="Search Comments"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "gpv_comment")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_spv_comment() {
      return {
        key: "spv_comment",
        title: this.$t("comments") + " " + this.$t("expensesParentUserName"),
        field: "spv_comment",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["spv_comment"]}
                  on-input={value =>
                    (this.filterCriteria["spv_comment"] = value)
                  }
                  placeholder="Search Comments"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "spv_comment")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_approvername() {
      return {
        key: "approvername",
        title: this.$t("Approved By"),
        field: "approvername",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.lqapprover &&
                row.lqapprover.name + " " + row.lqapprover.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["approvername"]}
                  apiUrl={`expenses/liquidations/getfilterlist/${this.isteam}`}
                  columnName={`approvername`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["approvername"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "approvername")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_status() {
      return {
        key: "status",
        title: this.$t("status"),
        field: "status",
        align: "left",
        width: 180,
        sortBy: "",
        fixed: "right",
        // renderBodyCell: ({ row, column, rowIndex }, h) => {
        //   return (
        //     <span>{!row.isOnlySolred ? row.status : ""}</span>
        //   );
        // },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["status"]}
                  dataList={this.status_type}
                  filterCriteria={this.filterCriteria["status"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_edit() {
      return {
        key: "edit",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {!row.isOnlySolred ? (
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              ) : (
                ""
              )}
            </div>
          );
        }
      };
    },
    col_go() {
      return {
        key: "go",
        title: this.$t("go"),
        field: "go",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {!row.isOnlySolred ? (
                <router-link
                  to={
                    "/expenses/liquidation/detail/" +
                    this.isteam +
                    "/" +
                    row.year +
                    "/" +
                    row.month +
                    "/" +
                    row.userId
                  }
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </router-link>
              ) : (
                ""
              )}
            </div>
          );
        }
      };
    },
    columns() {
      return [
        this.col_year,
        this.col_month,
        this.col_km_total,
        this.col_km_pending_approval,
        this.col_expense_total,
        this.col_expense_pending_approval,
        this.col_solredAmount,
        this.col_total_approved_amount,
        this.col_attachment_document_download,
        this.col_my_attachment_document_upload_preview,
        this.col_my_responsable_signed_document,
        this.col_gpv_comment,
        this.col_spv_comment,
        this.col_approvername,
        this.col_status,
        this.col_edit,
        this.col_go
      ];
    },
    spv_columns() {
      return [
        this.col_year,
        this.col_month,
        this.col_company,
        this.col_username,
        this.col_userstatus,
        this.col_usertype,
        this.col_parentId,
        this.col_km_total,
        this.col_km_pending_approval,
        this.col_expense_total,
        this.col_expense_pending_approval,
        this.col_solredAmount,
        this.col_total_approved_amount,
        this.col_attachment_document_download,
        this.col_team_attachment_document_upload_preview,
        this.col_team_responsable_signed_document,
        this.col_gpv_comment,
        this.col_spv_comment,
        this.col_approvername,
        this.col_status,
        this.col_edit,
        this.col_go
      ];
    }
  },
  methods: {
    onChangePeriodPDFStart() {
      const { start_year, start_month, end_year, end_month } = this.selectedPDF;
      let startDate = new Date(start_year, start_month - 1, 1)
        .toISOString()
        .slice(0, 7);
      let endDate = new Date(end_year, end_month - 1, 1)
        .toISOString()
        .slice(0, 7);
      if (startDate > endDate) {
        console.log(startDate, endDate, startDate > endDate);
        this.selectedPDF.end_year = this.selectedPDF.start_year;
        this.selectedPDF.end_month = this.selectedPDF.start_month;
        console.log(this.selectedPDF);
      }
    },
    onChangePeriodPDFEnd() {
      const { start_year, start_month, end_year, end_month } = this.selectedPDF;
      let startDate = new Date(start_year, start_month - 1, 1)
        .toISOString()
        .slice(0, 7);
      let endDate = new Date(end_year, end_month - 1, 1)
        .toISOString()
        .slice(0, 7);
      if (startDate > endDate) {
        console.log(startDate, endDate, startDate > endDate);
        this.selectedPDF.start_year = this.selectedPDF.end_year;
        this.selectedPDF.start_month = this.selectedPDF.end_month;
        console.log(this.selectedPDF);
      }
    },
    toggleSelectAllEmployees() {
      if (this.likesAllEmployee) {
        this.selectedPDF.users = [];
      } else {
        this.selectedPDF.users = this.liquidation_user_list.map(
          item => item.id
        );
      }
      console.log(this.selectedPDF.users);
    },
    closeDialogExportPDF() {
      this.dialogExportPDF = false;
      this.$nextTick(() => {
        console.log("next-tick !");
      });
    },
    onExportPDF() {
      this.dialogExportPDF = true;
    },
    async confirmDownloadPDF() {
      this.closeDialogExportPDF();
      const {
        start_year,
        start_month,
        end_year,
        end_month,
        companyCode,
        users
      } = this.selectedPDF;
      this.show();
      let fetch_url = `expenses/liquidations/downloadpdf`;
      try {
        const response = await ApiService.post(fetch_url, this.selectedPDF, {
          responseType: "blob"
        });
        if (response.type === "application/json") {
          logError("File Not Found !");
        } else {
          download(response, "downloadpdfs.zip");
        }
        this.close();
      } catch (error) {
        logError("File not found !");
        this.close();
      }
    },
    closeDialogExportSage() {
      this.dialogExportSage = false;
      this.$nextTick(() => {
        console.log("next-tick !");
      });
    },
    closeDialogExportRRHH() {
      this.dialogExportRRHH = false;
      this.$nextTick(() => {
        console.log("next-tick !");
      });
    },
    async confirmDownloadSage() {
      this.closeDialogExportSage();
      const { year, month, companyCode, isOnlyActiveUsers } = this.selectedSage;
      this.show();
      let fetch_url = `expenses/liquidations/downloadexcelsage/${year}/${month}/${companyCode}?isOnlyActiveUsers=${isOnlyActiveUsers}`;
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "sage.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async confirmDownloadRRHH() {
      this.closeDialogExportRRHH();
      const { year, month, companyCode, isOnlyActiveUsers } = this.selectedRRHH;
      this.show();
      let fetch_url = `expenses/liquidations/downloadexcelrrhh/${year}/${month}/${companyCode}?isOnlyActiveUsers=${isOnlyActiveUsers}`;
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "RRHH.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onExportSage() {
      this.dialogExportSage = true;
    },
    onExportRRHH() {
      this.dialogExportRRHH = true;
    },
    onButtonClickUploadSignedDoc(row) {
      this.seleted_upload_document_row = row;
      window.addEventListener(
        "focus",
        () => {
          console.log("selected");
        },
        { once: false }
      );
      this.$refs.uploader_signed_doc.click();
    },
    async onFileUploadSignedDocChanged(e) {
      if (e.target.files[0]) {
        this.show();
        try {
          let { data } = await ApiService.uploadFile(e.target.files[0]);
          this.$refs.uploader_signed_doc.value = "";
          if (data.id) {
            let body = {
              id: this.seleted_upload_document_row.id,
              attachment_document: data.id
            };
            await ApiService.put(
              `expenses/liquidations/updatedocument/${body.id}`,
              body
            );
            this.close();
            this.getDataFromApi();
          }
        } catch (error) {
          this.close();
        }
      }
    },
    onButtonClickUploadResonsableSignedDoc(row) {
      this.seleted_upload_document_row = row;
      window.addEventListener(
        "focus",
        () => {
          console.log("selected");
        },
        { once: false }
      );
      this.$refs.uploader_responsable_signed_doc.click();
    },
    async onFileUploadResonsableSignedDocChanged(e) {
      if (e.target.files[0]) {
        this.show();
        try {
          let { data } = await ApiService.uploadFile(e.target.files[0]);
          this.$refs.uploader_responsable_signed_doc.value = "";
          if (data.id) {
            let body = {
              id: this.seleted_upload_document_row.id,
              responsable_sign_document: data.id
            };
            await ApiService.put(
              `expenses/liquidations/updateresponsablesigneddocument/${body.id}`,
              body
            );
            this.close();
            this.getDataFromApi();
          }
        } catch (error) {
          this.close();
        }
      }
    },
    onChangeApprovalStatus(val) {
      switch (val) {
        case "Aprobada":
          this.current_approvalStatus_class = "success";
          break;
        case "Incidencia":
          this.current_approvalStatus_class = "warning";
          break;
        case "Pdte Firma Responsable":
          this.current_approvalStatus_class = "primary";
          break;
        default:
          this.current_approvalStatus_class = "error";
          break;
      }
      // setTimeout(() => {
      //   if (val === "Incidencia" && !this.selected.spv_comment) {
      //     this.$refs.btnSave.$el.click();
      //   }
      // }, "500");
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#liquidation-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "gpv_comment" ||
        cancelFilterKey === "spv_comment"
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      let filter_columns = document.querySelectorAll(
        "#liquidation-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "expenses/liquidations";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.expenseTypes = resp.expenseTypes;
        this.$store.dispatch(
          "expenses/setStatisticsDataLiquidation",
          resp.statistics_data_liquidation
        );
        this.filterable_parent_list = resp.parent_list;
        this.liquidation_user_list = resp.user_list;
        this.companies = resp.companies;
        this.user_roles = resp.user_roles;
        this.user_status = resp.user_status;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    onItemShowImageDialog(item, image) {
      this.selected = this.createSelectedModel(item);
      // this.selected_image = image;
      this.editedIndex = this.selected.id;

      this.selected_image = this.getFileImageUri(image ? image : null);
      console.log(this.selected_image);
      this.showImageDialog = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    onPictureDocument(img) {
      this.selected.attachment_document.img = this.$refs[
        "pictureInputDocument"
      ].file;
    },
    onPictureResponsableDocument(img) {
      this.selected.responsable_sign_document.img = this.$refs[
        "pictureInputResponsableDocument"
      ].file;
    },
    getImageUri(image) {
      return ApiService.getImageUri(image);
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    getFileImageUri(image) {
      return ApiService.getFileUri(image);
    },
    createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        year: _.get(item, "year"),
        month: _.get(item, "month"),
        userId: _.get(item, "userId"),
        user: _.get(item, "user"),
        attachment_document: {
          file: null,
          lqdocument: _.get(item, "lqdocument")
        },
        responsable_sign_document: {
          file: null,
          responsabledocument: _.get(item, "responsabledocument")
        },
        status: _.get(item, "status"),
        initialStatus: _.get(item, "status"),
        gpv_comment: _.get(item, "gpv_comment"),
        spv_comment: _.get(item, "spv_comment"),
        isChecking: false,
        isEditable: true
      };
      if (
        model.initialStatus === "Aprobada" &&
        !this.isAdmin &&
        !this.isSubAdmin
      ) {
        model.isEditable = false;
      }
      this.onChangeApprovalStatus(model.status);
      model.lightboxarr = [];
      if (model.attachment_document.lqdocument) {
        model.lightboxarr.push(
          this.getImageUri(model.attachment_document.lqdocument)
        );
      }
      model.lightboxresponsabledocarr = [];
      if (model.responsable_sign_document.responsabledocument) {
        model.lightboxresponsabledocarr.push(
          this.getImageUri(model.responsable_sign_document.responsabledocument)
        );
      }
      if (model.id === undefined || !model.attachment_document.lqdocument) {
        if (this.$refs["pictureInputDocument"] !== undefined) {
          this.$refs["pictureInputDocument"].removeImage();
        }
      }
      if (
        model.id === undefined ||
        !model.responsable_sign_document.responsabledocument
      ) {
        if (this.$refs["pictureInputResponsableDocument"] !== undefined) {
          this.$refs["pictureInputResponsableDocument"].removeImage();
        }
      }
      if (model.userId !== this.user.id) {
        model.isChecking = true;
      }
      console.log(model, this.user.id);
      return model;
    },
    async downloadSignedDoc(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    async downloadDocTemplate(id) {
      this.show();
      let fetch_url = ApiService.getdownloadsigndoctemplateURL(id);
      this.isDownloadTemplate = true;
      console.log(fetch_url);
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "templatesigndocument.pdf");
        this.isDownloadTemplate = false;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onChangeSignedDocument(file) {
      this.selected.attachment_document.file = file;
    },
    async onChangeResponsableSignedDocument(file) {
      this.selected.responsable_sign_document.file = file;
    },
    async onSaveClick(item) {
      if (
        this.selected.isChecking &&
        this.selected.status === "Incidencia" &&
        !this.selected.spv_comment
      ) {
        logError("Los comentarios del responsable son necesarios.");
        this.$refs.textarea_spv_comment.focus();
      }
      if (this.$refs.form.validate()) {
        let is_valid = true;

        if (is_valid) {
          let body = _.omit(item, [
            "attachment_document",
            "responsable_sign_document"
          ]);
          // let attachment_document = item.attachment_document.img;
          let attachment_document = item.attachment_document.file;
          if (attachment_document) {
            let { data } = await ApiService.uploadFile(attachment_document);
            body.attachment_document = data.id;
          }
          // let responsable_sign_document = item.responsable_sign_document.img;
          let responsable_sign_document = item.responsable_sign_document.file;
          console.log(responsable_sign_document);
          if (
            body.isChecking &&
            body.status === "Aprobada" &&
            !responsable_sign_document &&
            !item.responsable_sign_document.responsabledocument
          ) {
            logError(this.$t("Upload Responsable Signed Document"));
            return;
          }
          if (responsable_sign_document) {
            let { data } = await ApiService.uploadFile(
              responsable_sign_document
            );
            body.responsable_sign_document = data.id;
          }

          console.log(body);
          if (!this.selected.isChecking) {
            if (body.status === "Incidencia") {
              body.status === "Pdte Firma Responsable";
            }
          }
          if (
            this.selected.isChecking &&
            !this.isAdmin &&
            !this.isSubAdmin &&
            body.status === "Aprobada"
          ) {
            if (
              window.confirm(
                "Si apruebas la liquidación no podrá modificarse más."
              )
            ) {
              this.saveData(body);
            }
          } else {
            this.saveData(body);
          }
        }
      }
    },
    async saveData(body) {
      if (body.id) {
        await ApiService.put(`expenses/liquidations/${body.id}`, body);
      }
      this.$store.dispatch("auth/getGlobalState");
      logInfo("Éxito !");
      this.showEdit = false;
      this.getDataFromApi();
    },
    async downloadImage(selected, downloadFile) {
      this.show();
      try {
        const response = await ApiService.get(this.selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async deleteDocument(id) {
      if (window.confirm(this.$t("confirm_delete_uploaded_signed_document"))) {
        this.show();
        try {
          if (id) {
            await ApiService.delete(
              `expenses/liquidations/deleteDocument/${id}`
            );
            this.close();
            this.getDataFromApi();
          }
        } catch (error) {
          this.close();
        }
      }
    },
    async deleteResponsibleSignedDocument(id) {
      if (window.confirm(this.$t("confirm_delete_uploaded_signed_document"))) {
        this.show();
        try {
          if (id) {
            await ApiService.delete(
              `expenses/liquidations/deleteResponsibleSignedDocument/${id}`
            );
            this.close();
            this.getDataFromApi();
          }
        } catch (error) {
          this.close();
        }
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      try {
        let fetch_url = "expenses/liquidations/downloadexcel";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "liquidations.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },

    init() {
      this.getDataFromApi();
    }
  },
  mounted() {
    let cur_date = new Date();
    let cur_year = cur_date.getFullYear();
    for (let i = cur_year; i >= cur_year - 10; i--) {
      this.year_list.push(i);
    }
    cur_date.setMonth(cur_date.getMonth() - 1);
    this.selectedSage.month = cur_date.getMonth() + 1;
    this.selectedSage.year = cur_date.getFullYear();
    this.selectedRRHH.month = cur_date.getMonth() + 1;
    this.selectedRRHH.year = cur_date.getFullYear();
    this.selectedPDF.start_month = cur_date.getMonth() + 1;
    this.selectedPDF.start_year = cur_date.getFullYear();
    this.selectedPDF.end_month = cur_date.getMonth() + 1;
    this.selectedPDF.end_year = cur_date.getFullYear();
    this.$store.dispatch("auth/getGlobalState");
    this.init();
  }
};
</script>

<style>
#kilometers-table tbody img {
  width: 100% !important;
}
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_wrapper .btn_status {
  cursor: initial;
}
.liq_image_wrapper {
  position: relative;
}
.liq_image_wrapper img {
  border: 1px solid rgb(170, 170, 170);
  cursor: pointer;
  width: 100%;
}
.liq_image_wrapper .btn_remove_image {
  position: absolute;
  bottom: 0;
  right: 0;
}
.picture-inner,
.picture-preview {
  z-index: 1000 !important;
}
</style>
