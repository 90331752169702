<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="900px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ selected.portal_photo_name }}</span>
            <b-icon
              class="mx-1"
              :icon="selected?.isHiddenForBrand == true ? `eye` : `eye-slash`"
              font-scale="2"
              size="lg"
            ></b-icon>
            <b-icon
              class="mx-1"
              :icon="
                selected?.isWarning == true || selected?.isWarning == false
                  ? `exclamation-triangle-fill`
                  : `triangle`
              "
              font-scale="2"
              :variant="
                selected?.isWarning == true
                  ? `danger`
                  : selected?.isWarning == false
                  ? `success`
                  : ``
              "
            ></b-icon>
            <b-icon
              class="mx-1"
              font-scale="2"
              :icon="selected?.isFavorite == true ? `heart-fill` : `heart`"
              :variant="selected?.isFavorite == true ? `danger` : ``"
            ></b-icon>
          </v-card-title>

          <v-card-text class="text--primary">
            <v-container>
              <ImageSlider :attachments="selected.attachments" />
              <v-row class="mt-4">
                <v-col cols="12" md="4">
                  <h6 class="my-2">
                    {{ $t("Date") }} : {{ selected.worksessionDate }}
                  </h6>
                </v-col>
                <v-col cols="12" md="4">
                  <h6 class="my-2">
                    {{ $t("route") }} : {{ selected.route_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="4">
                  <h6 class="my-2">GPV : {{ selected.gpv_username }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("pos") + " " + this.$t("brands.name") }} :
                    {{ selected.pos_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="2">
                  <h6 class="my-2">
                    {{ `ID ${this.$t("pos")}` }} :
                    {{ selected.posId }}
                  </h6>
                </v-col>
                <v-col cols="12" md="2">
                  <h6 class="my-2">
                    {{ `ID ${this.$t("pos")} ${this.$t("brands.brand")}` }} :
                    {{ selected.idPosBrand }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ this.$t("address") }} :
                    {{ selected.address_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("chain") }} :
                    {{ selected.chain_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("channel") }} :
                    {{ selected.channel_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("brands.brands") }} :
                    {{ selected.brand_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("survey") }} :
                    {{ selected.survey_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ this.$t("Question") }} :
                    {{ selected.survey_question_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `GPV` }} :
                    {{ selected?.user_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <v-rating
                    dense
                    length="5"
                    size="30"
                    :value="selected?.scoreGPV"
                    :readonly="true"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `Marca` }} :
                    {{
                      selected?.ppScoreBrandBy &&
                        selected.ppScoreBrandBy.username
                    }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <v-rating
                    dense
                    length="5"
                    size="30"
                    v-model="selected.scoreBrand"
                    :readonly="!selected.isBrandEditing"
                    :required="selected.isBrandEditing"
                    :rules="selected.isBrandEditing ? requiredRules : []"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `Admin` }} :
                    {{
                      selected?.ppScoreAdminBy &&
                        selected.ppScoreAdminBy.username
                    }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <v-rating
                    dense
                    length="5"
                    size="30"
                    v-model="selected.scoreAdmin"
                    :readonly="!selected.isAdminEditing"
                    :required="selected.isAdminEditing"
                    :rules="selected.isAdminEditing ? requiredRules : []"
                  ></v-rating>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4" sm="12">
                  <h6 class="my-2">
                    {{ `GPV ${this.$t("comments")}` }}
                  </h6>
                  <v-textarea
                    :readonly="true"
                    class="gpv_comment_text readonly"
                    v-model="selected.commentsGPV"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <h6 class="my-2">
                    {{ `Marca ${this.$t("comments")}` }}
                  </h6>
                  <v-textarea
                    :readonly="!selected.isBrandEditing"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (!selected.isBrandEditing ? 'readonly' : '')
                    "
                    :required="selected.isBrandEditing"
                    :rules="
                      selected.isBrandEditing ? requiredCommentsRules : []
                    "
                    v-model="selected.commentsBrand"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <h6 class="my-2">
                    {{ `Admin ${this.$t("comments")}` }}
                  </h6>
                  <v-textarea
                    :readonly="!selected.isAdminEditing"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (!selected.isAdminEditing ? 'readonly' : '')
                    "
                    :required="selected.isAdminEditing"
                    :rules="
                      selected.isAdminEditing ? requiredCommentsRules : []
                    "
                    v-model="selected.commentsAdmin"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
                  <v-radio-group v-model="selected.isWarning" row>
                    <v-radio label="Alert Not Read" :value="1"></v-radio>
                    <b-icon
                      class="mr-3 flag_icons"
                      icon="exclamation-triangle-fill"
                      font-scale="2"
                      :variant="`danger`"
                      @click="onClickFlagIcons(`isWarning`, 1)"
                    ></b-icon>
                    <v-radio label="Alert Read" :value="0"></v-radio>
                    <b-icon
                      class="mr-3 flag_icons"
                      icon="exclamation-triangle-fill"
                      font-scale="2"
                      :variant="`success`"
                      @click="onClickFlagIcons(`isWarning`, 0)"
                    ></b-icon>
                    <v-radio label="Not Alert" :value="null"></v-radio>
                    <b-icon
                      class="mr-3 flag_icons"
                      icon="triangle"
                      font-scale="2"
                      @click="onClickFlagIcons(`isWarning`, null)"
                    ></b-icon>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" sm="12" class="d-flex justify-center">
                  <v-radio-group v-model="selected.isHiddenForBrand" row>
                    <v-radio label="Seen" :value="1"></v-radio>
                    <b-icon
                      class="mr-3 flag_icons"
                      font-scale="2"
                      :icon="`eye`"
                      @click="onClickFlagIcons(`isHiddenForBrand`, 1)"
                    ></b-icon>
                    <v-radio label="Not Seen" :value="0"></v-radio>
                    <b-icon
                      class="mr-3 flag_icons"
                      font-scale="2"
                      :icon="`eye-slash`"
                      @click="onClickFlagIcons(`isHiddenForBrand`, 0)"
                    ></b-icon>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="d-flex justify-center align-items-center"
                >
                  <v-checkbox
                    class="mt-0 mx-0 mr-4"
                    :label="`Favorite`"
                    v-model="selected.isFavorite"
                    hide-details
                  ></v-checkbox>
                  <b-icon
                    class="mr-3 flag_icons"
                    @click="
                      onClickFlagIcons(`isFavorite`, !selected.isFavorite)
                    "
                    font-scale="2"
                    :icon="
                      selected?.isFavorite == true ? `heart-fill` : `heart`
                    "
                    :variant="selected?.isFavorite == true ? `danger` : ``"
                  ></b-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
              v-if="selected.isBrandEditing || selected.isAdminEditing"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Portal Fotos</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form_filter" v-model="valid_filter" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeFilterMainCredentials('surveys')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSurveys"
                    v-bind:label="$t('survey')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_surveys"
                    :loading="isSearching['surveys']"
                    :items="filtered_surveys"
                    hide-no-data
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="!this.isBrandOrGPV">
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSPVs"
                    v-bind:label="`${this.$t('salesforce.Parent')}`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_spvs"
                    :loading="isSearching['spvs']"
                    :items="filtered_spvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('spvs')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idRoutes"
                    v-bind:label="$t('route')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_routes"
                    :loading="isSearching['routes']"
                    :items="filtered_routes"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idGPVs"
                    v-bind:label="`GPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_gpvs"
                    :loading="isSearching['gpvs']"
                    :items="filtered_gpvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.companyCodes"
                    :label="$t('Company')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_companies"
                    :loading="isSearching['companies']"
                    :items="filtered_companies"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idOperators"
                    label="Operador"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_operators"
                    :loading="isSearching['operators']"
                    :items="filtered_operators"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <b-button
              v-if="!showEmpty && !isBrand"
              @click="downloadExcel"
              variant="warning"
              class="mr-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Export Excel
            </b-button>
            <b-button
              v-if="!showEmpty && (!isBrand || user.anadimos_exports === 'SI')"
              @click="downloadExports('ZIP')"
              variant="primary"
              class="mr-2"
              :disabled="!isExportEnalbled"
              style="color: white;"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Export Zip
            </b-button>
            <b-button
              v-if="!showEmpty && (!isBrand || user.anadimos_exports === 'SI')"
              @click="downloadExports('POWERPOINT')"
              variant="success"
              class="mr-2"
              :disabled="!isExportEnalbled"
              style="color: white;"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Export PowerPoint
            </b-button>
          </v-col>
        </v-row>
        <DialogPortalPhotos
          :ref="`dialogPortalPhotos`"
          :portalItem="selected"
          :assignTypes="assignTypes"
        />
        <template v-if="exports && exports.length">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label class="m-0 mr-1">
                <b-badge variant="success">
                  Descargas disponibles
                </b-badge>
              </label>
              <span style="font-weight: bold; font-size: 12px">
                <i>
                  (Estarán disponibles durante un día tras la creación)
                </i>
              </span>
              <div
                class="d-flex flex-wrap"
                style="background: #efefef;border: 1px solid #999; padding: 2px; font-family: Consolas "
              >
                <div
                  class="d-flex  mr-1"
                  :key="`export_el_${k}`"
                  v-for="(el, k) in exports"
                >
                  <div
                    class="p-1 d-flex flex-column"
                    style="background: white;border: 1px solid;"
                  >
                    <div class="d-flex">
                      <b-icon
                        v-if="el.type === 'ZIP'"
                        class="mt-1 mr-1"
                        icon="file-earmark-zip"
                        title="zip"
                      ></b-icon>
                      <b-icon
                        v-if="el.type === 'POWERPOINT'"
                        class="mt-1 mr-1"
                        icon="file-earmark-word"
                        title="powerpoint"
                      ></b-icon>

                      <span>{{ formatDateTime(el.createdAt) }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <span
                        v-if="el.status === 'PENDING'"
                        style="font-weight: bold; color: #ff0000"
                      >
                        generando...
                      </span>
                      <span
                        v-if="el.status === 'COMPLETED'"
                        style="font-weight: bold; color: green"
                      >
                        completado
                      </span>

                      <template v-if="el.status === 'COMPLETED'">
                        <div class="d-flex">
                          <button
                            :disabled="activeExportDownload"
                            class="mr-2"
                            @click="downloadExport(el)"
                          >
                            <div
                              v-if="
                                activeExportDownload &&
                                  activeExportDownload.id === el.id
                              "
                              class="loader-xs"
                              style=""
                            ></div>
                            <b-icon
                              v-else
                              icon="cloud-download"
                              title="descargar"
                            ></b-icon>
                          </button>

                          <button
                            :disabled="activeExportDownload"
                            style="background: #ffb9b9"
                            @click="removeExport(el)"
                          >
                            <b-icon icon="trash" title="eliminar"></b-icon>
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div
                    v-if="downloadProgress && el.id === downloadProgress.id"
                    class="flex-column p-1"
                    style="border: 1px solid; border-left: none; background: gainsboro; font-size: 13px"
                  >
                    <div style="">
                      <span class="mr-1 my-0"><b>total</b>: </span>
                      {{ downloadProgress.total }}MB
                    </div>
                    <div class="d-flex">
                      <v-progress-linear
                        v-model="downloadProgress.progress"
                        height="25"
                      >
                        <strong>
                          {{ Math.ceil(downloadProgress.progress) }}%
                        </strong>
                      </v-progress-linear>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- <b-alert class="mt-4" show variant="info" v-if="isLoadingData">
          <b-icon
            icon="arrow-counterclockwise"
            animation="spin-reverse"
          ></b-icon>
          cargando datos...
        </b-alert> -->
        <!-- <b-alert
          v-if="!isLoadingData && showEmpty"
          variant="warning"
          class="m-0 mt-4 p-2 my-alert"
          show
        >
          <i> No hay datos....</i>
        </b-alert> -->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefPortalPhotos"
              id="datatable_portal_photos"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";
import ImageSlider from "./ImageSlider.vue";
import DialogPortalPhotos from "./DialogPortalPhotos.vue";

export default {
  name: "SalesForcePortalPhotos",
  components: { ImageSlider, DialogPortalPhotos },
  data: function() {
    return {
      isLoadingData: false,
      filter_main_credentials_fetch_url:
        "salesforce/portalphotos/getfiltermaincredentials",
      filterMainCredential: {
        companyCodes: [],
        brandId: null,
        date: {
          from: null,
          to: null
        },
        idSurveys: null,
        idSPVs: [],
        idRoutes: [],
        idGPVs: [],
        idOperators: []
      },
      select_startDate_menu: false,
      select_endDate_menu: false,
      isSearching: {},
      search_brands: null,
      filtered_brands: [],
      search_surveys: null,
      filtered_surveys: [],
      search_spvs: null,
      filtered_spvs: [],
      search_gpvs: null,
      filtered_gpvs: [],
      search_routes: null,
      filtered_routes: [],
      search_companies: null,
      filtered_companies: [],
      search_operators: null,
      filtered_operators: [],
      valid_filter: true,

      liveWire: null, // interval object used for periodic state fetch (to avoid using wwbsocket)
      exports: [],
      activeExportDownload: null,
      downloadProgress: null,
      exportEnabled: true,
      isSearch: 0,

      assignTypes: {},
      alertList: [
        { value: "ALERT_NOT_READ", label: "Alert Not Read" },
        { value: "ALERT_READ", label: "Alert Read" },
        { value: "NOT_ALERT", label: "Not Alert" }
      ],
      hiddenList: [
        { value: "SEEN", label: "Seen" },
        { value: "NOT_SEEN", label: "Not Seen" }
      ],
      favoriteList: [
        { value: "FAVORITE", label: "Favorites" },
        { value: "NO_FAVORITE", label: "Non Favorites" }
      ],
      filterlist_fetch_url: "salesforce/portalphotos/getfilterlist",
      filterable_col_list: [],
      filterCriteria: {
        isWarning: {},
        isHiddenForBrand: {},
        favorite: {},
        worksessionDate: {},
        route_name: {},
        posId: {},
        idPosBrand: {},
        pos_name: {},
        portal_photo_name: {},
        address_label: {},
        chain_name: {},
        channel_name: {},
        brand_name: {},
        survey_name: {},
        survey_question_name: {},
        user_name: {},
        responsable_name: {},
        scoreGPVBy: {},
        scoreBrandBy: {},
        scoreAdminBy: {},
        commentsGPV: "",
        commentsBrand: "",
        commentsAdmin: "",
        scoreGPV: {},
        scoreBrand: {},
        scoreAdmin: {},
        surveyQuestionId: {},
        operatorId: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 10,
      pageOptions: [10, 50, 100],
      totaldata: 0,
      data: [],
      statistics_data: {},

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      requiredCommentsRules: [v => !!v || "Escribe los comentarios"],
      isSaving: false,
      selectedRow: null
    };
  },
  watch: {
    async search_companies(val) {
      this.searchValues("companies", val);
    },
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_surveys(val) {
      this.searchValues("surveys", val);
    },
    async search_routes(val) {
      this.searchValues("routes", val);
    },
    async search_spvs(val) {
      this.searchValues("spvs", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    },
    async search_operators(val) {
      this.searchValues("operators", val);
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isSPV", "isGPV", "user"]),
    showEmpty() {
      let ret = true;
      if (this.data.length > 0) {
        ret = false;
      }
      return ret;
    },
    ...mapGetters("auth", [
      "isAuthenticated",
      "isGPV",
      "isSPV",
      "isBrand",
      "isAdmin",
      "isSubAdmin",
      "user"
    ]),
    score_list() {
      let ret = [];
      for (let i = 1; i <= 5; i++) {
        ret.push(i.toString());
      }
      return ret;
    },
    isExportEnalbled() {
      let ret = this.exportEnabled;
      if (!this.exports || (this.exports && this.exports.length === 0))
        ret = true;
      return ret;
    },
    isGPVRole() {
      return this.isGPV;
    },
    isBrandRole() {
      let ret = false;
      if (!this.isGPV && !this.isAdmin && !this.isSubAdmin && !this.isSPV) {
        ret = true;
      }
      return ret;
    },
    isAdminRole() {
      return this.isAdmin || this.isSubAdmin || this.isSPV;
    },
    col_worksessionDate() {
      return {
        key: "worksessionDate",
        title: this.$t("Date"),
        field: "worksessionDate",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["worksessionDate"]["from"]}
                  value={this.filterCriteria["worksessionDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["worksessionDate"]["to"]}
                  value={this.filterCriteria["worksessionDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "worksessionDate")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_operator() {
      return {
        key: "operatorId",
        title: "Operador",
        field: "operatorId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.operator && row.operator.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["operatorId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`operatorId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["operatorId"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "operatorId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_route() {
      return {
        key: "route_name",
        title: this.$t("route"),
        field: "route_name",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posId"]["from"]}
                  value={this.filterCriteria["posId"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posId"]["to"]}
                  value={this.filterCriteria["posId"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "posId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_idPosBrand() {
      return {
        key: "idPosBrand",
        title: `ID ${this.$t("pos")} ${this.$t("brands.brand")}`,
        field: "idPosBrand",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["idPosBrand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`idPosBrand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["idPosBrand"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "idPosBrand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_pos_name() {
      return {
        key: "pos_name",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos_name",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["address_label"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`address_label`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["address_label"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_chain_name() {
      return {
        key: "chain_name",
        title: this.$t("chain"),
        field: "chain_name",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_channel_name() {
      return {
        key: "channel_name",
        title: this.$t("channel"),
        field: "channel_name",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["channel_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`channel_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["channel_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brand_name() {
      return {
        key: "brand_name",
        title: this.$t("brands.brands"),
        field: "brand_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_survey_name() {
      return {
        key: "survey_name",
        title: this.$t("survey"),
        field: "survey_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["survey_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`survey_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["survey_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "survey_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_surveyQuestionId() {
      return {
        key: "surveyQuestionId",
        title: `ID ${this.$t("Question")}`,
        field: "surveyQuestionId",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["surveyQuestionId"]["from"]}
                  value={this.filterCriteria["surveyQuestionId"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["surveyQuestionId"]["to"]}
                  value={this.filterCriteria["surveyQuestionId"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "surveyQuestionId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_survey_question_name() {
      return {
        key: "survey_question_name",
        title: `${this.$t("Question")}`,
        field: "survey_question_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.survey_question_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["survey_question_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`survey_question_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["survey_question_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "survey_question_name")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_user_name() {
      return {
        key: "user_name",
        title: `GPV`,
        field: "user_name",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user_name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_responsable_name() {
      return {
        key: "responsable_name",
        title: `${this.$t("salesforce.Parent")}`,
        field: "responsable_name",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["responsable_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`responsable_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["responsable_name"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsable_name")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreGPVBy() {
      return {
        key: "scoreGPVBy",
        title: `GPV`,
        field: "scoreGPVBy",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.scoreGPVByName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["scoreGPVBy"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`scoreGPVBy`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["scoreGPVBy"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreGPVBy")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreBrandBy() {
      return {
        key: "scoreBrandBy",
        title: `Marca`,
        field: "scoreBrandBy",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.scoreBrandByName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["scoreBrandBy"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`scoreBrandBy`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["scoreBrandBy"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreBrandBy")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreAdminBy() {
      return {
        key: "scoreAdminBy",
        title: `Admin`,
        field: "scoreAdminBy",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.scoreAdminByName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["scoreAdminBy"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`scoreAdminBy`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["scoreAdminBy"]}
                  criteria={this.filterCriteria}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreAdminBy")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_commentsGPV() {
      return {
        key: "commentsGPV",
        title: `GPV ${this.$t("comments")}`,
        field: "commentsGPV",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["commentsGPV"]}
                  on-input={value =>
                    (this.filterCriteria["commentsGPV"] = value)
                  }
                  placeholder="Search Comentarios"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "commentsGPV")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_commentsBrand() {
      return {
        key: "commentsBrand",
        title: `Marca ${this.$t("comments")}`,
        field: "commentsBrand",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["commentsBrand"]}
                  on-input={value =>
                    (this.filterCriteria["commentsBrand"] = value)
                  }
                  placeholder="Search Comentarios"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "commentsBrand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_commentsAdmin() {
      return {
        key: "commentsAdmin",
        title: `Comentarios Responsable`,
        field: "commentsAdmin",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["commentsAdmin"]}
                  on-input={value =>
                    (this.filterCriteria["commentsAdmin"] = value)
                  }
                  placeholder="Search Comentarios"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "commentsAdmin")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreGPV() {
      return {
        key: "scoreGPV",
        title: `Score GPV`,
        field: "scoreGPV",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return row?.scoreGPV ? (
            <v-rating
              dense
              length="5"
              readonly
              size="18"
              value={row?.scoreGPV}
            ></v-rating>
          ) : (
            ""
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["scoreGPV"]}
                  dataList={this.score_list}
                  filterCriteria={this.filterCriteria["scoreGPV"]}
                />
                <br />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreGPV")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreBrand() {
      return {
        key: "scoreBrand",
        title: `Score Marca`,
        field: "scoreBrand",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return row?.scoreBrand ? (
            <v-rating
              dense
              length="5"
              readonly
              size="18"
              value={row?.scoreBrand}
            ></v-rating>
          ) : (
            ""
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["scoreBrand"]}
                  dataList={this.score_list}
                  filterCriteria={this.filterCriteria["scoreBrand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreBrand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_scoreAdmin() {
      return {
        key: "scoreAdmin",
        title: `Puntuación Responsable`,
        field: "scoreAdmin",
        align: "left",
        width: 250,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return row?.scoreAdmin ? (
            <v-rating
              dense
              length="5"
              readonly
              size="18"
              value={row?.scoreAdmin}
            ></v-rating>
          ) : (
            ""
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["scoreAdmin"]}
                  dataList={this.score_list}
                  filterCriteria={this.filterCriteria["scoreAdmin"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "scoreAdmin")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_isWarning() {
      return {
        key: "isWarning",
        title: `Alert`,
        field: "isWarning",
        align: "center",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <b-icon
                class="mx-1"
                icon={
                  row?.isWarning == true || row?.isWarning == false
                    ? `exclamation-triangle-fill`
                    : `triangle`
                }
                font-scale="2"
                variant={
                  row?.isWarning == true
                    ? `danger`
                    : row?.isWarning == false
                    ? `success`
                    : ``
                }
              ></b-icon>
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["isWarning"]}
                  dataList={this.alertList}
                  filterCriteria={this.filterCriteria["isWarning"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "isWarning")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_isHiddenForBrand() {
      return {
        key: "isHiddenForBrand",
        title: `Hidden`,
        field: "isHiddenForBrand",
        align: "center",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <b-icon
                class="mx-1"
                font-scale="2"
                icon={row?.isHiddenForBrand == true ? `eye` : `eye-slash`}
              ></b-icon>
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["isHiddenForBrand"]}
                  dataList={this.hiddenList}
                  filterCriteria={this.filterCriteria["isHiddenForBrand"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "isHiddenForBrand")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_favorite() {
      return {
        key: "favorite",
        title: `Favorite`,
        field: "favorite",
        align: "center",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row?.portalFavoriteUsers.length > 0 ? (
                <b-icon
                  class="mx-1"
                  font-scale="2"
                  icon="heart-fill"
                  variant="danger"
                ></b-icon>
              ) : (
                ""
              )}
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["favorite"]}
                  dataList={this.favoriteList}
                  filterCriteria={this.filterCriteria["favorite"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "favorite")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_photo() {
      return {
        key: "photo",
        title: this.$t("Photo"),
        field: "photo",
        align: "left",
        width: 150,
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row?.mainPhoto && row.mainPhoto?.worksessionSurveyResponsePhoto) {
            let thumbnail_url = this.getImageUri(
              row.mainPhoto?.worksessionSurveyResponsePhoto
            );
            return (
              <img
                style="border: 1px solid #aaa; cursor: pointer; max-width: 100%;"
                src={thumbnail_url}
                alt=""
                on-click={() => this.onClickPhoto(row)}
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_photo_name() {
      return {
        key: "portal_photo_name",
        title: `${this.$t("Photo")} ${this.$t("brands.name")}`,
        field: "portal_photo_name",
        align: "left",
        fixed: "left",
        sortBy: "",
        width: 150,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["portal_photo_name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`portal_photo_name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["portal_photo_name"]}
                  criteria={this.filterCriteria}
                  isSearch={this.isSearch}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "portal_photo_name")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    isBrandOrGPV() {
      return this.isBrand || this.isGPV;
    },
    columns() {
      let isVisibleDeleteBtn = true;
      if (this.isGPV || this.isBrand) {
        isVisibleDeleteBtn = false;
      }
      let actionColumn = {
        key: "actions",
        title: this.$t("edititem"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
              {row?.worksession_survey_photos_portal && isVisibleDeleteBtn ? (
                <v-icon
                  medium
                  color="error"
                  on-click={() =>
                    this.onItemRemove(row?.worksession_survey_photos_portal.id)
                  }
                >
                  mdi-delete
                </v-icon>
              ) : (
                ""
              )}
            </div>
          );
        }
      };
      let cols = [
        this.col_photo,
        this.col_photo_name,
        this.col_worksessionDate,
        // this.col_route,
        this.col_operator,
        this.col_posId,
        this.col_idPosBrand,
        this.col_pos_name,
        this.col_address_label,
        this.col_chain_name,
        this.col_channel_name,
        this.col_brand_name,
        this.col_survey_name,
        this.col_surveyQuestionId,
        this.col_survey_question_name,
        // this.col_user_name,
        // this.col_responsable_name,
        this.col_scoreGPV,
        this.col_commentsGPV,
        this.col_scoreBrandBy,
        this.col_scoreBrand,
        this.col_commentsBrand,
        this.col_favorite
      ];
      let otherCols = [
        this.col_scoreAdminBy,
        this.col_scoreAdmin,
        this.col_commentsAdmin,
        this.col_isWarning,
        this.col_isHiddenForBrand
      ];
      let ret = [];
      if (this.isBrandOrGPV) {
        ret = [
          ...cols.slice(0, 18),
          this.col_scoreAdmin,
          this.col_commentsAdmin,
          ...cols.slice(18)
        ];
      } else {
        ret = [
          ...cols.slice(0, 3),
          this.col_route,
          ...cols.slice(3, 13),
          this.col_user_name,
          this.col_responsable_name,
          ...cols.slice(13, 18),
          ...otherCols,
          ...cols.slice(18)
        ];
      }
      if (!this.isGPV) ret = [...ret, actionColumn];
      return ret;
    }
  },
  methods: {
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "surveys") {
        this.filtered_surveys = resp.data;
      } else if (fieldname === "spvs") {
        this.filtered_spvs = resp.data;
      } else if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "routes") {
        this.filtered_routes = resp.data;
      } else if (fieldname === "companies") {
        this.filtered_companies = resp.data;
      } else if (fieldname === "operators") {
        this.filtered_operators = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },
    onChangeDates() {
      if (
        this.filterMainCredential.startDate > this.filterMainCredential.endDate
      ) {
        this.filterMainCredential.endDate = this.filterMainCredential.startDate;
      }
    },
    async onClickFilter() {
      if (this.$refs.form_filter.validate()) {
        await this.getDataFromApi();

        this.filterCriteria.brand_name = {
          [this.filterMainCredential.brandId]: this.filterMainCredential.brandId
        };
        this.filterCriteria.survey_name = {
          [this.filterMainCredential.idSurveys]: this.filterMainCredential
            .idSurveys
        };

        this.isSearch += 1;
        if (this.filterMainCredential?.idRoutes?.length) {
          Object.values(this.filterMainCredential.idRoutes).forEach(
            el =>
              (this.filterCriteria.route_name = {
                [el]: el
              })
          );
        } else {
          this.filterCriteria.route_name = {};
        }
        if (this.filterMainCredential?.idSPVs?.length) {
          Object.values(this.filterMainCredential.idSPVs).forEach(
            el =>
              (this.filterCriteria.responsable_name = {
                [el]: el
              })
          );
        } else {
          this.filterCriteria.responsable_name = {};
        }
        if (this.filterMainCredential?.idGPVs?.length) {
          Object.values(this.filterMainCredential.idGPVs).forEach(
            el =>
              (this.filterCriteria.user_name = {
                [el]: el
              })
          );
        } else {
          this.filterCriteria.user_name = {};
        }
        if (this.isSearch === 1) {
          this.filterCriteria.idPosBrand = {};
          this.filterCriteria.pos_name = {};
          this.filterCriteria.address_label = {};
          this.filterCriteria.chain_name = {};
          this.filterCriteria.channel_name = {};
          this.filterCriteria.survey_question_name = {};
          this.filterCriteria.scoreBrandBy = {};
          this.filterCriteria.scoreAdminBy = {};
          this.filterCriteria.operatorId = {};
        }
      }
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn, searchKey) {
      // if (searchKey && searchKey === "brand_name") {
      //   let changingSearchKeys = [
      //     // "portal_photo_name",
      //     // "route_name",
      //     "idPosBrand",
      //     "pos_name",
      //     "address_label",
      //     "chain_name",
      //     "channel_name",
      //     // "survey_name",
      //     // "survey_question_name",
      //     // "user_name",
      //     // "responsable_name"
      //     // "scoreGPVBy",
      //     // "scoreBrandBy",
      //     // "scoreAdminBy",
      //   ];
      //   for (const changeKey of changingSearchKeys) {
      //     this.filterCriteria[changeKey] = {
      //       ...this.filterCriteria[changeKey],
      //       "-1": 10
      //     };
      //     this.filterCriteria[changeKey] = {};
      //   }
      // }
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        ["commentsGPV", "commentsBrand", "commentsAdmin"].includes(
          cancelFilterKey
        )
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_portal_photos"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async getDataFromApi() {
      this.isLoadingData = true;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#datatable_portal_photos .filterable-column"
      });
      let fetch_url = "salesforce/portalphotos";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.data = resp.data;
        this.totaldata = resp.total;
        this.assignTypes = resp.assignTypes;
        this.close();
        this.isLoadingData = false;
      } catch (error) {
        this.close();
        this.isLoadingData = false;
      }
    },
    onClickPhoto(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.$refs[`dialogPortalPhotos`].showModal(this.selected);
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      let model = {
        ...item,
        portalPhotoId: null,
        isFavorite: false,
        isBrandEditing: this.isBrandRole,
        isAdminEditing: this.isAdminRole,
        isGPVEditing: this.isGPVRole
      };
      if (model?.worksession_survey_photos_portal) {
        model.portalPhotoId = model?.worksession_survey_photos_portal.id;
      } else {
        model.isHiddenForBrand = 0;
      }
      if (model?.portalFavoriteUsers && model?.portalFavoriteUsers.length > 0) {
        model.isFavorite = true;
      }
      console.log("createSelectedModel - ", model);
      return model;
    },
    onClickFlagIcons(flag, value) {
      switch (flag) {
        case "isWarning":
          this.selected.isWarning = value;
          break;
        case "isHiddenForBrand":
          this.selected.isHiddenForBrand = value;
          break;
        case "isFavorite":
          this.selected.isFavorite = value;
          break;

        default:
          break;
      }
    },
    async onItemRemove(photosPortalId) {
      if (window.confirm(`${this.$t("confirm_delete")}`)) {
        try {
          let status = await ApiService.delete(
            `salesforce/portalphotos/${photosPortalId}`
          );
          if (status) {
            const { success } = status;
            if (success) {
              logInfo(this.$t("alert_success"));
              this.getDataFromApi();
            } else {
              logError("Failed !");
            }
          }
        } catch (error) {
          logError("Failed !");
        }
      }
    },
    async onSaveClick(item) {
      let comments = null;
      let scores = null;
      if (item?.isBrandEditing) {
        comments = item.commentsBrand;
        scores = item.scoreBrand;
      } else if (item?.isAdminEditing) {
        comments = item.commentsAdmin;
        scores = item.scoreAdmin;
      }
      if (!comments && !scores) {
        logError("Puntua y Escribe los comentarios");
        return;
      } else if (!comments) {
        logError("Escribe los comentarios");
        return;
      } else if (!scores) {
        logError("Puntua la foto");
        return;
      }

      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = {
          ...item
        };
        try {
          await ApiService.post(`salesforce/portalphotos/upsert`, body);
          logInfo("Éxito !");
          this.showEdit = false;
          this.getDataFromApi();
          this.isSaving = false;
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async downloadExcel() {
      if (!this.$refs.form_filter.validate()) return;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#pedidoimport-table .filterable-column"
      });
      console.log(query_params_string);
      let fetch_url = "salesforce/portalphotos/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    getQuery() {
      const { filterCriteria } = this;
      let filterModel = [];
      for (const [key, value] of Object.entries(filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      return filterModel;
    },
    formatDateTime(d) {
      return moment(d).format("YYYY-MM-DD HH:mm:ss");
    },
    async downloadExports(type) {
      const LIMIT = 250;
      if (this.totaldata > LIMIT) {
        if (
          !confirm(
            `Limite de fotos alcanzado ${this.totaldata}/${LIMIT}: Se limitaran a ${LIMIT}`
          )
        ) {
          return logError(`Limite de fotos: ${LIMIT}`);
        }
      }
      let query = this.getQuery();
      const q = {
        filterMainCredential: this.filterMainCredential,
        query,
        type
      };
      await ApiService.post("salesforce/portalphotos/export", q);
      this.exportEnabled = false;
      logInfo("Export solicitado");
      await this.pullLiveWire();
    },
    async downloadExport(el) {
      this.activeExportDownload = el;
      this.downloadProgress = null;
      const self = this;
      function onProgress(stats) {
        let { total, progress } = stats;
        const xProgress = Math.floor(progress);
        if (!self.downloadProgress) {
          self.downloadProgress = {
            id: el.id,
            total,
            progress: xProgress
          };
        }
        if (self.downloadProgress.progress !== xProgress) {
          self.downloadProgress.progress = xProgress;
        }
      }
      try {
        await ApiService.download(
          `salesforce/portalphotos/export/static/${el.file}`,
          el.file,
          onProgress
        );
      } catch (ex) {
        console.error(ex);
      }
      this.activeExportDownload = null;
    },
    async removeExport(el) {
      await ApiService.delete(`salesforce/portalphotos/export/${el.id}`);
      logInfo("export borrado");
      await this.pullLiveWire();
    },
    //avoid using websocket as client firewall might block ports
    async pullLiveWire() {
      const { exports } = await ApiService.get(
        "salesforce/portalphotos/livewire"
      );
      this.exports = exports;
    },
    async init() {
      console.log("---------------------------", this.user, this.isBrand);
      this.filterable_col_list = Object.keys(this.filterCriteria);
      await this.searchValues("brands", "");
      await this.searchValues("surveys", "");
      await this.searchValues("spvs", "");
      await this.searchValues("gpvs", "");
      await this.searchValues("routes", "");
      await this.searchValues("companies", "");
      await this.searchValues("operators", "");
    }
  },
  async mounted() {
    this.init();
  },
  async created() {
    await this.pullLiveWire();
    this.liveWire = setInterval(
      async function() {
        await this.pullLiveWire();
      }.bind(this),
      10000
    );
  },
  beforeDestroy: function() {
    clearInterval(this.liveWire);
  }
};
</script>

<style>
.flag_icons {
  cursor: pointer;
}
</style>
