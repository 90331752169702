<template>
  <div
    :class="
      `d-flex flex-column survey_wrapper ${
        this.isPCView && this.isThereOthersBottom
          ? 'survey_wrapper_has_others_bottom'
          : ''
      }`
    "
  >
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <div
      :class="
        `d-flex flex-column ${
          this.deviceInformation &&
          ['PC'].includes(this.deviceInformation?.type)
            ? 'survey_filter_wrapper'
            : ''
        }`
      "
    >
      <div class="p-0">
        <v-row class="mx-0 my-0">
          <v-col cols="12" xs="12" sm="2" md="2" lg="1" class="py-2">
            <router-link
              v-if="this.selectedBrandId"
              :to="
                `/session/${this.sessionId}/pospage/${this.worksessionPosId}/route/${this.selectedBrandId}`
              "
            >
              <b-button class="mr-1 my-2" variant="outline-secondary" size="sm">
                <b-icon icon="arrow-left"></b-icon> volver
              </b-button>
            </router-link>
          </v-col>
          <v-col cols="12" xs="12" sm="10" md="6" lg="5" class="py-2">
            <div
              class="d-flex justify-content-start align-center flex-wrap"
              v-if="posSession"
            >
              <template v-for="(brandItem, bKey) in worksessionPosBrands">
                <b-button
                  variant="outline-secondary"
                  class="mr-2 my-2"
                  v-if="selectedBrandId !== brandItem.id"
                  :key="`brandnavbtn_${bKey}`"
                  @click="onClickSelectedBrand(brandItem.id)"
                  size="sm"
                >
                  <v-badge color="pink" inline dot>
                    {{ brandItem.name }}
                  </v-badge>
                </b-button>
                <b-button
                  :key="`brandnavbtn_${bKey}`"
                  variant="outline-secondary"
                  class="mr-2 my-2"
                  @click="onClickSelectedBrand(brandItem.id)"
                  size="sm"
                  v-else
                >
                  <v-badge color="green" inline dot>
                    {{ brandItem.name }}
                  </v-badge>
                </b-button>
              </template>
              <div>
                Punt:
                <span class="mx-1 bold">
                  {{ surveys_response_actions?.surveyBrandScore }}
                </span>
                ({{ posBrandPSObjective }} Obj -
                {{ displayPosBrandPSLastVisit }} Ant)
              </div>
              <b-button
                :class="`mx-1 ${this.isActiveFilter ? 'active_filter' : ''}`"
                variant="outline-secondary"
                size="sm"
                @click="onClickFilterDialog"
              >
                <b-icon icon="funnel-fill"></b-icon>
              </b-button>
              <span class="mx-1 bold">{{
                `${actionCount} ${this.$t("brands.Actions")}`
              }}</span>
              <b-button
                class="mx-1"
                variant="outline-secondary"
                size="sm"
                @click="showDialogOnePage()"
              >
                One Page
              </b-button>
              <b-button
                class="mx-1"
                variant="outline-secondary"
                size="sm"
                @click="showDialogFotosAnterios()"
              >
                Fotos
              </b-button>
              <b-button
                class="mx-1"
                variant="outline-secondary"
                size="sm"
                @click="showDialogPromos()"
              >
                Promos
              </b-button>
              <template v-if="posSession">
                <sessionTagLabels
                  :postaglabels="posTagLabels"
                ></sessionTagLabels>
                <DialogOnePage
                  ref="dialogOnePage"
                  :routeId="posSession.routeId"
                  :posId="posSession.posId"
                  :worksessionPosBrands="currentWorksessionPosBrand"
                />
                <DialogFotosAnterios
                  ref="dialogFotos"
                  :routeId="posSession.routeId"
                  :posId="posSession.posId"
                  :worksessionPosBrands="currentWorksessionPosBrand"
                />
                <DialogPromos
                  ref="dialogPromos"
                  :routeId="posSession.routeId"
                  :posId="posSession.posId"
                  :worksessionPosBrands="currentWorksessionPosBrand"
                />
              </template>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="2"
            class="py-2"
            v-if="
              this.deviceInformation &&
                ['PC', 'TABLET'].includes(this.deviceInformation?.type)
            "
          >
            <v-radio-group v-model="showType" row @change="onChangeShowType">
              <v-radio label="Vista STD" value="STANDARD_VIEW"></v-radio>
              <v-radio label="Vista Tabla" value="TABLE_VIEW"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="py-2">
            <v-checkbox
              class="mt-0 mx-2 d-inline-block"
              :label="`Obligatoria`"
              hide-details
              v-model="filterCriteria['isMandatory']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2 d-inline-block"
              :label="`Mejora PS`"
              hide-details
              v-model="filterCriteria['zero_points']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2 d-inline-block"
              :label="`Sin Contestar`"
              hide-details
              v-model="filterCriteria['no_answers']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
            <v-checkbox
              class="mt-0 mx-2 d-inline-block"
              :label="`Acciones`"
              hide-details
              v-model="filterCriteria['new_actions']"
              @change="onChangeFilterSurveys"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog
      v-if="selected_image && selected_promatItem"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between align-start">
          <div class="d-flex justify-content-center w-full">
            <v-icon
              x-large
              class="modal_close_button"
              @click="closeImageDialog"
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
          <div class="d-flex justify-content-between align-start w-full">
            <span class="text-h5">{{ selected_promatItem?.name }}</span>
            <div>
              <div>
                <span class="text-h6">{{
                  selected_promatItem?.grams
                    ? `${selected_promatItem?.grams} ${$t("Grams")}`
                    : ""
                }}</span>
              </div>
              <div>
                <span class="text-h6">{{
                  selected_promatItem?.mHeight
                    ? `${selected_promatItem?.mHeight} ${$t("Height")}`
                    : ""
                }}</span>
              </div>
              <div>
                <span class="text-h6">{{
                  selected_promatItem?.mWidth
                    ? `${selected_promatItem?.mWidth} ${$t("Width")}`
                    : ""
                }}</span>
              </div>
              <div>
                <span class="text-h6">{{
                  selected_promatItem?.mLength
                    ? `${selected_promatItem?.mLength} ${$t("Length")}`
                    : ""
                }}</span>
              </div>
              <div>
                <span class="text-h6">{{
                  selected_promatItem?.pro_ean
                    ? `EAN: ${selected_promatItem?.pro_ean}`
                    : ""
                }}</span>
              </div>
            </div>
          </div>
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
          <v-container class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 79%;" :src="selected_image" alt="" />
                <img
                  style="max-width: 20%;"
                  class="ml-1"
                  v-if="selected_promatItem?.eanStaticPhoto"
                  :src="getImageUri(selected_promatItem?.eanStaticPhoto)"
                  alt=""
                />
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.description">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("brands.description") }}
                </p>
                <p class="sectionContents">
                  {{ selected_promatItem?.description }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.salesArguments">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">{{ $t("Sales Arguments") }}</p>
                <div
                  v-html="selected_promatItem?.salesArguments"
                  class="sectionContents text--primary mt-2"
                ></div>
              </v-col>
            </v-row>
            <v-row v-if="selected_promatItem?.executionInstruction">
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 sectionTitle">
                  {{ $t("Execution Instruction") }}
                </p>
                <div
                  v-html="selected_promatItem?.executionInstruction"
                  class="sectionContents text--primary mt-2"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedCompQueImage"
      v-model="showCompQueImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-center align-start">
          <v-icon x-large @click="closeCompQueImageDialog">
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
          <v-container class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  style="max-width: 100%;"
                  :src="selectedCompQueImage"
                  alt=""
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedWorksessionSurveyResponseApproval"
      v-model="showCorrectionAlertDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between align-start">
          {{ `${this.$t("Corrected Answer")}` }} -
          {{
            this.approvalStatus[
              selectedWorksessionSurveyResponseApproval.approvalStatus
            ]
          }}
        </v-card-title>
        <v-card-text style="padding-bottom: 0 !important;">
          <v-container class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <h6>{{ `${this.$t("comments")}` }}</h6>
                <p>
                  {{
                    selectedWorksessionSurveyResponseApproval.approvalComments
                  }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedProductAlertList && selectedProductAlertList.length > 0"
      v-model="showProductAlertDialog"
      scrollable
      width="500px"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("Alert") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="text--primary">
            <v-row
              v-for="(alertItem, alertKey) in selectedProductAlertList"
              :key="`alert_list_${alertKey}`"
            >
              <v-col cols="12" sm="12" md="12">
                <div
                  class="message_contents"
                  v-html="alertItem.messageContents"
                ></div>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeProductAlertDialog">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedAddActionItem"
      v-model="showAddActionDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ titleOfAddActionDialog }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-autocomplete
                  v-model="selectedAddActionItem.surveyQuestionId"
                  v-bind:label="`${$t('Question')}`"
                  :items="selectedAddActionItem.selectableQuestionList"
                  item-text="label"
                  item-value="value"
                  filled
                  :hide-details="true"
                  @change="onChangeActionQuestionId"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="
                selectedAddActionItem.surveyQuestionId &&
                  selectedAddActionItem.surveyQuestionId !== 'OTHERS'
              "
            >
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-autocomplete
                  v-model="selectedAddActionItem.surveyActionId"
                  v-bind:label="`${$t('brands.Actions')}`"
                  :items="selectedAddActionItem.selectableActions"
                  item-text="label"
                  item-value="id"
                  filled
                  :hide-details="true"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-textarea
                  v-model="selectedAddActionItem.comments"
                  v-bind:label="$t('comments')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddActionsDialog">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onClickSaveAddActions(selectedAddActionItem)"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogPromoLocal
      ref="dialogPromoLocal"
      :placeTypeList="placeTypeList"
      @onChangeAddActionItem="
        selectedAddActionItem => onClickSaveAddActions(selectedAddActionItem)
      "
    />

    <v-dialog v-model="showFilterDialog" scrollable width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h5">Filter</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                class="d-flex justify-content-start align-center flex-wrap"
                v-if="filterableSurveyList.length > 1"
              >
                <v-autocomplete
                  v-model="filterCriteria['surveyId']"
                  v-bind:label="$t('survey')"
                  item-text="name"
                  item-value="id"
                  :items="filterableSurveyList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeSurvey"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['surveyComponentId']"
                  v-bind:label="$t('component')"
                  item-text="name"
                  item-value="id"
                  :items="filterableSurveyComponentList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeSurveyComponent"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['surveyProOrMatId']"
                  v-bind:label="`${$t('Product')} ${$t('Material')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableProOrMatList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeSurveyComponentProOrMat"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['surveyQuestionId']"
                  v-bind:label="$t('Question')"
                  item-text="name"
                  item-value="id"
                  :items="filterableQuestions"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeSurveyQuestion"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row
              v-if="
                filterableProSubBrandList.length > 0 ||
                  filterableProMainFamilyList.length > 0 ||
                  filterableProSubFamilyList.length > 0
              "
            >
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['proSubBrandId']"
                  v-bind:label="`${$t('Product')} ${$t('brands.sub_brand')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableProSubBrandList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeProSubBrand"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['proMainFamilyId']"
                  v-bind:label="`${$t('Product')} ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableProMainFamilyList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeProMainFamily"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['proSubFamilyId']"
                  v-bind:label="`${$t('Product')} Sub ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableProSubFamilyList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeProSubFamily"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="
                filterableMatMainFamilyList.length > 0 ||
                  filterableMatSubFamilyList.length > 0
              "
            >
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['matSubBrandId']"
                  v-bind:label="`${$t('Material')} ${$t('brands.sub_brand')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableMatSubBrandList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeMatSubBrand"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['matMainFamilyId']"
                  v-bind:label="`${$t('Material')} ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableMatMainFamilyList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeMatMainFamily"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                class="d-flex justify-content-start align-center flex-wrap"
              >
                <v-autocomplete
                  v-model="filterCriteria['matSubFamilyId']"
                  v-bind:label="`${$t('Material')} Sub ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filterableMatSubFamilyList"
                  filled
                  outlined
                  hide-no-data
                  hide-details
                  class="mr-4"
                  clearable
                  multiple
                  @change="onChangeMatSubFamily"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmFilterDialog">
            Ok
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancelFilterDialog">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="clearFilterDialog">
            {{ $t("ClearFilters") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="surveyList && Object.values(surveyList).length > 0">
      <template
        v-for="(surveyListItem, surveyListKey) in Object.values(surveyList)"
      >
        <div
          v-if="!surveyListItem.survey_components.length"
          :key="`survey_list_${surveyListKey}`"
          class="mt-3"
        >
          <b-alert variant="warning" show="">
            {{ surveyListItem.name }} - No hay ninguna encuesta
          </b-alert>
        </div>
        <template v-else>
          <v-card
            elevation="5"
            class="mb-3"
            outlined
            :key="`survey_list_${surveyListKey}`"
            v-show="surveyListItem.isVisible"
          >
            <v-card-title>
              {{ surveyListItem.name }}
              <b-badge
                variant="success"
                class="mx-2"
                v-if="surveyListItem.estimatedTime"
              >
                {{ getEstimatedTime(surveyListItem.estimatedTime) }}
              </b-badge>
            </v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <template
                v-for="componentId in surveyListItem.sortedKeysComponent"
              >
                <div
                  class="mb-3"
                  :key="
                    `survey_list_item_component_${surveyListKey}_${componentId}_${comKey}`
                  "
                  v-for="(component, comKey) in [
                    getComponentItem(surveyListItem?.components, componentId)
                  ]"
                >
                  <div
                    v-if="showType === 'STANDARD_VIEW'"
                    v-show="component.isVisible && component.isCondVisible"
                  >
                    <template v-if="isShowIncludingOthersBottom(component)">
                      <SurveyComponentTitle
                        :component="component"
                        :onClickCompQueImage="onClickCompQueImage"
                      />
                      <template
                        v-for="proOrMatId in component.sortedProOrMatKeys"
                      >
                        <v-row
                          v-for="(proOrMat, proOrMatKey) in [
                            getProOrMatItem(component.proOrMatList, proOrMatId)
                          ]"
                          :key="`component_promat_${proOrMatId}_${proOrMatKey}`"
                        >
                          <v-col v-if="proOrMat" cols="12">
                            <v-row
                              v-show="
                                proOrMat.isVisible || proOrMat.isVisibleAction
                              "
                            >
                              <v-col cols="12" xs="12" sm="6">
                                <h5 class="text-right" v-if="proOrMat">
                                  {{ proOrMat.name }}
                                </h5>
                              </v-col>
                              <v-col cols="12" xs="12" sm="6" v-if="proOrMat">
                                <img
                                  v-if="
                                    getProOrMatImageComponent(
                                      component.assignType,
                                      proOrMat,
                                      true
                                    )
                                  "
                                  style="border: 1px solid #aaa; max-width: 80%; cursor: pointer;"
                                  :src="
                                    getProOrMatImageComponent(
                                      component.assignType,
                                      proOrMat,
                                      true
                                    )
                                  "
                                  @click="
                                    onItemShowImageDialog(
                                      component.assignType,
                                      proOrMat,
                                      false
                                    )
                                  "
                                  alt=""
                                />
                                <b-button
                                  class="ml-2 btn_color_white"
                                  size="sm"
                                  v-if="proOrMat.alertList.length > 0"
                                  :variant="
                                    proOrMat.isReadProductAlert
                                      ? `success`
                                      : `danger`
                                  "
                                  @click="
                                    onClickProductAlert(
                                      proOrMat.alertList,
                                      surveyListItem,
                                      component,
                                      proOrMat
                                    )
                                  "
                                >
                                  <b-icon icon="alarm-fill"></b-icon>
                                </b-button>
                              </v-col>
                            </v-row>
                            <v-row
                              v-show="
                                proOrMat.isVisible || proOrMat.isVisibleAction
                              "
                            >
                              <template
                                v-for="questionId in proOrMat.sortedKeysQuestion"
                              >
                                <template
                                  v-for="question in [
                                    getQuestionItem(
                                      proOrMat.questions,
                                      questionId
                                    )
                                  ]"
                                >
                                  <v-col
                                    v-if="question"
                                    cols="12"
                                    xs="12"
                                    :sm="
                                      ![QUESTION_TYPES.LONGTEXT].includes(
                                        question.questionType
                                      )
                                        ? 4
                                        : 12
                                    "
                                    :md="
                                      ![QUESTION_TYPES.LONGTEXT].includes(
                                        question.questionType
                                      )
                                        ? 3
                                        : 12
                                    "
                                    :lg="
                                      ![QUESTION_TYPES.LONGTEXT].includes(
                                        question.questionType
                                      )
                                        ? 2
                                        : 12
                                    "
                                    :key="
                                      `component_proOrMat_${surveyListKey}_${comKey}_${proOrMatKey}_question_${question.id}`
                                    "
                                    v-show="
                                      question.isVisible &&
                                        question.isCondVisible
                                    "
                                  >
                                    <SurveyQuestion
                                      :isPSNotZeroLine="
                                        getPSSumOfProOrMatItem(proOrMat)
                                      "
                                      :getImportedAndKPIObjectiveValue="
                                        getImportedAndKPIObjectiveValue
                                      "
                                      :worksessionPosBrandId="
                                        worksessionPosBrandId
                                      "
                                      :currentVisitType="currentVisitType"
                                      :isTableView="false"
                                      :detectedSurveyChainIdForPuncDetails="
                                        detectedSurveyChainIdForPuncDetails
                                      "
                                      :worksession_survey_response_approval="
                                        question.worksession_survey_response_approval
                                      "
                                      :optionScores="question.optionScores"
                                      :initialValue="question.value"
                                      :currentScore="question.score"
                                      :maximumScore="question.maximumScore"
                                      :surveyListItem="surveyListItem"
                                      :component="component"
                                      :proOrMat="proOrMat"
                                      :question="question"
                                      :assignTypes="assignTypes"
                                      @onClickSaveComments="
                                        saveData =>
                                          onClickSaveComments(
                                            saveData,
                                            surveyListItem.id,
                                            component.id,
                                            proOrMat?.id ? proOrMat.id : null,
                                            question.id,
                                            component.assignType,
                                            question
                                          )
                                      "
                                      @updatePhotosPortalAttributes="
                                        (update_data, typeStr) =>
                                          updatePhotosPortalAttributes(
                                            surveyListItem.id,
                                            component.id,
                                            proOrMat?.id ? proOrMat.id : null,
                                            question.id,
                                            typeStr,
                                            update_data
                                          )
                                      "
                                      @deleteAttachmentItem="
                                        (delId, typeStr) =>
                                          deleteAttachmentItem(
                                            surveyListItem.id,
                                            component.id,
                                            proOrMat?.id ? proOrMat.id : null,
                                            question.id,
                                            typeStr,
                                            delId
                                          )
                                      "
                                      @onClickCompQueImage="
                                        image => onClickCompQueImage(image)
                                      "
                                      @onClickCorrectionAlert="
                                        worksession_survey_response_approval =>
                                          onClickCorrectionAlert(
                                            worksession_survey_response_approval
                                          )
                                      "
                                      @input="
                                        (result, typeStr) =>
                                          persistQuestionValue(
                                            result,
                                            typeStr,
                                            surveyListItem.id,
                                            component.id,
                                            proOrMat?.id ? proOrMat.id : null,
                                            question.id,
                                            component.assignType,
                                            question,
                                            `survey_question_ref_${proOrMatKey}_${question.id}`,
                                            question.isCondVisible
                                          )
                                      "
                                      :ref="
                                        `survey_question_ref_${proOrMatKey}_${question.id}`
                                      "
                                    />
                                  </v-col>
                                </template>
                              </template>
                            </v-row>
                            <v-row v-if="component.isAction">
                              <v-col
                                cols="12"
                                xs="12"
                                sm="3"
                                class="text-left d-flex align-center"
                              >
                                <b-button
                                  class="mr-1"
                                  variant="success"
                                  size="sm"
                                  style="color: #fff !important;"
                                  v-show="
                                    proOrMat.isVisible ||
                                      proOrMat.isVisibleAction
                                  "
                                  @click="
                                    onClickUpsertActions(
                                      surveyListItem,
                                      component,
                                      proOrMat,
                                      null,
                                      null
                                    )
                                  "
                                >
                                  <b-icon icon="plus"></b-icon>
                                  {{ $t("Add Action") }}
                                </b-button>
                              </v-col>
                              <v-col
                                cols="12"
                                xs="12"
                                sm="9"
                                class="text-left"
                                v-show="
                                  proOrMat.isVisible || proOrMat.isVisibleAction
                                "
                              >
                                <v-chip
                                  :class="
                                    `m-2 added_action_chip ${
                                      actionItem.actionType === 'PROMOLOCAL'
                                        ? 'chip_promo_local'
                                        : ''
                                    }`
                                  "
                                  v-for="(actionItem,
                                  actionKey) in proOrMat?.actions"
                                  v-show="actionItem.isVisible"
                                  v-bind:key="`add_action_item_${actionKey}`"
                                  close
                                  @click:close="
                                    onClickRemoveAction(
                                      surveyListItem,
                                      component,
                                      proOrMat,
                                      actionKey
                                    )
                                  "
                                >
                                  <div
                                    class="px-2 py-2"
                                    style="cursor: pointer;"
                                    @click="
                                      onClickUpsertActions(
                                        surveyListItem,
                                        component,
                                        proOrMat,
                                        actionItem,
                                        actionKey
                                      )
                                    "
                                  >
                                    {{ actionItem?.actionLabel }}
                                    <v-divider class="my-1"></v-divider>
                                    <p
                                      style="max-width: 200px; text-wrap: wrap"
                                    >
                                      {{ actionItem.comments }}
                                    </p>
                                  </div>
                                </v-chip>
                              </v-col>
                            </v-row>
                            <v-row
                              v-show="
                                proOrMat.isVisible || proOrMat.isVisibleAction
                              "
                              v-if="component.assignType === 'PRODUCT'"
                            >
                              <v-col cols="12" md="12">
                                <promosAnswerTable
                                  v-if="
                                    currentPromosForSelectedBrand.length > 0
                                  "
                                  :currentPromosForSelectedBrand="
                                    getCurrentPromosForSelectedBrandProOrMat(
                                      currentPromosForSelectedBrand,
                                      component,
                                      proOrMat
                                    )
                                  "
                                  :promosTypeList="promosTypeList"
                                  :placeTypeList="placeTypeList"
                                  :folletoTypeList="folletoTypeList"
                                  :isPresentTypeList="isPresentTypeList"
                                  :component="component"
                                  :proOrMat="proOrMat"
                                  @changeAnswer="
                                    (changeKey, record) =>
                                      persistPromosAnswer(changeKey, record)
                                  "
                                />
                              </v-col>
                            </v-row>
                            <v-divider
                              v-if="
                                Object.keys(component.proOrMatList).pop() !==
                                  proOrMatKey &&
                                  (proOrMat.isVisible ||
                                    proOrMat.isVisibleAction)
                              "
                              class="survey_pro_divider"
                            ></v-divider>
                          </v-col>
                        </v-row>
                      </template>
                    </template>
                  </div>
                  <div
                    v-else-if="
                      showType === 'TABLE_VIEW' &&
                        isShowIncludingOthersBottom(component)
                    "
                    v-show="component.isVisible && component.isCondVisible"
                  >
                    <SurveyComponentTitle
                      :component="component"
                      :onClickCompQueImage="onClickCompQueImage"
                    />
                    <div class="survey-table-container">
                      <table
                        class="table b-table table-striped table-bordered table-sm b-table-stacked-sm survey_answers_tb"
                      >
                        <tbody>
                          <template
                            v-for="proOrMatId in component.sortedProOrMatKeys"
                          >
                            <template
                              v-for="(proOrMat, proOrMatKey) in [
                                getProOrMatItem(
                                  component.proOrMatList,
                                  proOrMatId
                                )
                              ]"
                            >
                              <template v-if="proOrMat">
                                <tr
                                  :key="
                                    `component_promat_${proOrMatId}_${proOrMatKey}`
                                  "
                                  v-show="
                                    proOrMat.isVisible ||
                                      proOrMat.isVisibleAction
                                  "
                                >
                                  <td
                                    style="min-width: 200px;"
                                    v-show="
                                      isShowProductColumnInTableView(component)
                                    "
                                  >
                                    <div class="d-flex flex-column">
                                      <h5
                                        v-if="proOrMat"
                                        style="cursor: pointer;"
                                        @click="
                                          onItemShowImageDialog(
                                            component.assignType,
                                            proOrMat,
                                            false
                                          )
                                        "
                                      >
                                        {{ proOrMat.name }}
                                      </h5>
                                      <b-button
                                        class="mt-2 btn_color_white"
                                        size="sm"
                                        v-if="proOrMat.alertList.length > 0"
                                        :variant="
                                          proOrMat.isReadProductAlert
                                            ? `success`
                                            : `danger`
                                        "
                                        @click="
                                          onClickProductAlert(
                                            proOrMat.alertList,
                                            surveyListItem,
                                            component,
                                            proOrMat
                                          )
                                        "
                                      >
                                        <b-icon icon="alarm-fill"></b-icon>
                                      </b-button>
                                      <b-button
                                        v-if="
                                          component.isAction &&
                                            Object.values(proOrMat?.actions)
                                              .length === 0
                                        "
                                        class="mt-2"
                                        variant="success"
                                        size="sm"
                                        style="color: #fff !important;"
                                        v-show="
                                          proOrMat.isVisible ||
                                            proOrMat.isVisibleAction
                                        "
                                        @click="
                                          onClickUpsertActions(
                                            surveyListItem,
                                            component,
                                            proOrMat,
                                            null,
                                            null
                                          )
                                        "
                                      >
                                        <b-icon icon="plus"></b-icon>
                                        {{ $t("Add Action") }}
                                      </b-button>
                                    </div>
                                  </td>
                                  <template
                                    v-for="col_question_item in getQuestionList(
                                      component
                                    )"
                                  >
                                    <template
                                      v-for="question in [
                                        getQuestionItem(
                                          proOrMat.questions,
                                          col_question_item.id
                                        )
                                      ]"
                                    >
                                      <td
                                        :key="
                                          `component_proOrMat_${surveyListKey}_${comKey}_${proOrMatKey}_question_${question.id}`
                                        "
                                      >
                                        <SurveyQuestion
                                          v-show="
                                            question.isVisible &&
                                              question.isCondVisible
                                          "
                                          :isPSNotZeroLine="
                                            getPSSumOfProOrMatItem(proOrMat)
                                          "
                                          :getImportedAndKPIObjectiveValue="
                                            getImportedAndKPIObjectiveValue
                                          "
                                          :worksessionPosBrandId="
                                            worksessionPosBrandId
                                          "
                                          :currentVisitType="currentVisitType"
                                          :isTableView="true"
                                          :detectedSurveyChainIdForPuncDetails="
                                            detectedSurveyChainIdForPuncDetails
                                          "
                                          :worksession_survey_response_approval="
                                            question.worksession_survey_response_approval
                                          "
                                          :optionScores="question.optionScores"
                                          :initialValue="question.value"
                                          :currentScore="question.score"
                                          :maximumScore="question.maximumScore"
                                          :surveyListItem="surveyListItem"
                                          :component="component"
                                          :proOrMat="proOrMat"
                                          :question="question"
                                          :assignTypes="assignTypes"
                                          @onClickSaveComments="
                                            saveData =>
                                              onClickSaveComments(
                                                saveData,
                                                surveyListItem.id,
                                                component.id,
                                                proOrMat?.id
                                                  ? proOrMat.id
                                                  : null,
                                                question.id,
                                                component.assignType,
                                                question
                                              )
                                          "
                                          @updatePhotosPortalAttributes="
                                            (update_data, typeStr) =>
                                              updatePhotosPortalAttributes(
                                                surveyListItem.id,
                                                component.id,
                                                proOrMat?.id
                                                  ? proOrMat.id
                                                  : null,
                                                question.id,
                                                typeStr,
                                                update_data
                                              )
                                          "
                                          @deleteAttachmentItem="
                                            (delId, typeStr) =>
                                              deleteAttachmentItem(
                                                surveyListItem.id,
                                                component.id,
                                                proOrMat?.id
                                                  ? proOrMat.id
                                                  : null,
                                                question.id,
                                                typeStr,
                                                delId
                                              )
                                          "
                                          @onClickCompQueImage="
                                            image => onClickCompQueImage(image)
                                          "
                                          @onClickCorrectionAlert="
                                            worksession_survey_response_approval =>
                                              onClickCorrectionAlert(
                                                worksession_survey_response_approval
                                              )
                                          "
                                          @input="
                                            (result, typeStr) =>
                                              persistQuestionValue(
                                                result,
                                                typeStr,
                                                surveyListItem.id,
                                                component.id,
                                                proOrMat?.id
                                                  ? proOrMat.id
                                                  : null,
                                                question.id,
                                                component.assignType,
                                                question,
                                                `survey_question_ref_${proOrMatKey}_${question.id}`,
                                                question.isCondVisible
                                              )
                                          "
                                          :ref="
                                            `survey_question_ref_${proOrMatKey}_${question.id}`
                                          "
                                        />
                                      </td>
                                    </template>
                                  </template>
                                </tr>
                                <tr
                                  :key="
                                    `component_promat_action_${proOrMatId}_${proOrMatKey}`
                                  "
                                  v-if="
                                    component.isAction &&
                                      Object.values(proOrMat?.actions).length >
                                        0
                                  "
                                  v-show="
                                    proOrMat.isVisible ||
                                      proOrMat.isVisibleAction
                                  "
                                >
                                  <td>
                                    <b-button
                                      class="mr-1"
                                      variant="success"
                                      size="sm"
                                      style="color: #fff !important;"
                                      v-show="
                                        proOrMat.isVisible ||
                                          proOrMat.isVisibleAction
                                      "
                                      @click="
                                        onClickUpsertActions(
                                          surveyListItem,
                                          component,
                                          proOrMat,
                                          null,
                                          null
                                        )
                                      "
                                    >
                                      <b-icon icon="plus"></b-icon>
                                      {{ $t("Add Action") }}
                                    </b-button>
                                  </td>
                                  <td colspan="100%">
                                    <v-chip
                                      :class="
                                        `m-2 added_action_chip ${
                                          actionItem.actionType === 'PROMOLOCAL'
                                            ? 'chip_promo_local'
                                            : ''
                                        }`
                                      "
                                      v-for="(actionItem,
                                      actionKey) in proOrMat?.actions"
                                      v-show="actionItem.isVisible"
                                      v-bind:key="
                                        `add_action_item_${actionKey}`
                                      "
                                      close
                                      @click:close="
                                        onClickRemoveAction(
                                          surveyListItem,
                                          component,
                                          proOrMat,
                                          actionKey
                                        )
                                      "
                                    >
                                      <div
                                        class="px-2 py-2"
                                        style="cursor: pointer;"
                                        @click="
                                          onClickUpsertActions(
                                            surveyListItem,
                                            component,
                                            proOrMat,
                                            actionItem,
                                            actionKey
                                          )
                                        "
                                      >
                                        {{ actionItem?.actionLabel }}
                                        <v-divider class="my-1"></v-divider>
                                        <p
                                          style="max-width: 200px; text-wrap: wrap"
                                        >
                                          {{ actionItem.comments }}
                                        </p>
                                      </div>
                                    </v-chip>
                                  </td>
                                </tr>
                                <tr
                                  :key="
                                    `component_promat_promosanswer_${proOrMatId}_${proOrMatKey}`
                                  "
                                  v-show="
                                    proOrMat.isVisible ||
                                      proOrMat.isVisibleAction
                                  "
                                  v-if="component.assignType === 'PRODUCT'"
                                >
                                  <td colspan="100%">
                                    <promosAnswerTable
                                      v-if="
                                        currentPromosForSelectedBrand.length > 0
                                      "
                                      :currentPromosForSelectedBrand="
                                        getCurrentPromosForSelectedBrandProOrMat(
                                          currentPromosForSelectedBrand,
                                          component,
                                          proOrMat
                                        )
                                      "
                                      :promosTypeList="promosTypeList"
                                      :placeTypeList="placeTypeList"
                                      :folletoTypeList="folletoTypeList"
                                      :isPresentTypeList="isPresentTypeList"
                                      :component="component"
                                      :proOrMat="proOrMat"
                                      @changeAnswer="
                                        (changeKey, record) =>
                                          persistPromosAnswer(changeKey, record)
                                      "
                                    />
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </template>
      </template>
      <v-row class="text-center" v-if="!isThereOthersBottom || !isPCView">
        <v-col cols="12" sm="12" md="12">
          <v-btn
            color="success"
            dark
            class="mx-1 my-2"
            @click="onSaveClick"
            :loading="isSaving"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="others_bottom_wrapper"
        v-if="isPCView && isThereOthersBottom"
      >
        <v-col cols="12" sm="10" md="10" class="others_components_wrapper">
          <template
            v-for="(surveyListItem, surveyListKey) in Object.values(surveyList)"
          >
            <template v-for="componentId in surveyListItem.sortedKeysComponent">
              <div
                class="mb-3"
                :key="
                  `survey_list_item_component_${surveyListKey}_${componentId}_${comKey}`
                "
                v-for="(component, comKey) in [
                  getComponentItem(surveyListItem?.components, componentId)
                ]"
              >
                <div v-show="component.isCondVisible">
                  <template
                    v-if="
                      component.assignType == 'OTHERS' &&
                        component.isOthersBottom
                    "
                  >
                    <!-- <SurveyComponentTitle
                      :component="component"
                      :onClickCompQueImage="onClickCompQueImage"
                    /> -->
                    <template
                      v-for="proOrMatId in component.sortedProOrMatKeys"
                    >
                      <v-row
                        v-for="(proOrMat, proOrMatKey) in [
                          getProOrMatItem(component.proOrMatList, proOrMatId)
                        ]"
                        :key="`component_promat_${proOrMatId}_${proOrMatKey}`"
                      >
                        <v-col v-if="proOrMat" cols="12">
                          <v-row v-if="proOrMat && proOrMat?.name">
                            <v-col cols="12" xs="12" sm="6">
                              <h5 class="text-right">
                                {{ proOrMat.name }}
                              </h5>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6">
                              <img
                                v-if="
                                  getProOrMatImageComponent(
                                    component.assignType,
                                    proOrMat,
                                    true
                                  )
                                "
                                style="border: 1px solid #aaa; max-width: 80%; cursor: pointer;"
                                :src="
                                  getProOrMatImageComponent(
                                    component.assignType,
                                    proOrMat,
                                    true
                                  )
                                "
                                @click="
                                  onItemShowImageDialog(
                                    component.assignType,
                                    proOrMat,
                                    false
                                  )
                                "
                                alt=""
                              />
                              <b-button
                                class="ml-2 btn_color_white"
                                size="sm"
                                v-if="proOrMat.alertList.length > 0"
                                :variant="
                                  proOrMat.isReadProductAlert
                                    ? `success`
                                    : `danger`
                                "
                                @click="
                                  onClickProductAlert(
                                    proOrMat.alertList,
                                    surveyListItem,
                                    component,
                                    proOrMat
                                  )
                                "
                              >
                                <b-icon icon="alarm-fill"></b-icon>
                              </b-button>
                            </v-col>
                          </v-row>
                          <v-row>
                            <template
                              v-for="questionId in proOrMat.sortedKeysQuestion"
                            >
                              <template
                                v-for="question in [
                                  getQuestionItem(
                                    proOrMat.questions,
                                    questionId
                                  )
                                ]"
                              >
                                <v-col
                                  v-if="question"
                                  cols="12"
                                  xs="12"
                                  :sm="
                                    ![QUESTION_TYPES.LONGTEXT].includes(
                                      question.questionType
                                    )
                                      ? 4
                                      : 12
                                  "
                                  :md="
                                    ![QUESTION_TYPES.LONGTEXT].includes(
                                      question.questionType
                                    )
                                      ? 3
                                      : 12
                                  "
                                  :lg="
                                    ![QUESTION_TYPES.LONGTEXT].includes(
                                      question.questionType
                                    )
                                      ? 2
                                      : 12
                                  "
                                  :key="
                                    `component_proOrMat_${surveyListKey}_${comKey}_${proOrMatKey}_question_${question.id}`
                                  "
                                  v-show="question.isCondVisible"
                                >
                                  <SurveyQuestion
                                    :isPSNotZeroLine="
                                      getPSSumOfProOrMatItem(proOrMat)
                                    "
                                    :getImportedAndKPIObjectiveValue="
                                      getImportedAndKPIObjectiveValue
                                    "
                                    :worksessionPosBrandId="
                                      worksessionPosBrandId
                                    "
                                    :currentVisitType="currentVisitType"
                                    :isTableView="false"
                                    :detectedSurveyChainIdForPuncDetails="
                                      detectedSurveyChainIdForPuncDetails
                                    "
                                    :worksession_survey_response_approval="
                                      question.worksession_survey_response_approval
                                    "
                                    :optionScores="question.optionScores"
                                    :initialValue="question.value"
                                    :currentScore="question.score"
                                    :maximumScore="question.maximumScore"
                                    :surveyListItem="surveyListItem"
                                    :component="component"
                                    :proOrMat="proOrMat"
                                    :question="question"
                                    :assignTypes="assignTypes"
                                    @onClickSaveComments="
                                      saveData =>
                                        onClickSaveComments(
                                          saveData,
                                          surveyListItem.id,
                                          component.id,
                                          proOrMat?.id ? proOrMat.id : null,
                                          question.id,
                                          component.assignType,
                                          question
                                        )
                                    "
                                    @updatePhotosPortalAttributes="
                                      (update_data, typeStr) =>
                                        updatePhotosPortalAttributes(
                                          surveyListItem.id,
                                          component.id,
                                          proOrMat?.id ? proOrMat.id : null,
                                          question.id,
                                          typeStr,
                                          update_data
                                        )
                                    "
                                    @deleteAttachmentItem="
                                      (delId, typeStr) =>
                                        deleteAttachmentItem(
                                          surveyListItem.id,
                                          component.id,
                                          proOrMat?.id ? proOrMat.id : null,
                                          question.id,
                                          typeStr,
                                          delId
                                        )
                                    "
                                    @onClickCompQueImage="
                                      image => onClickCompQueImage(image)
                                    "
                                    @onClickCorrectionAlert="
                                      worksession_survey_response_approval =>
                                        onClickCorrectionAlert(
                                          worksession_survey_response_approval
                                        )
                                    "
                                    @input="
                                      (result, typeStr) =>
                                        persistQuestionValue(
                                          result,
                                          typeStr,
                                          surveyListItem.id,
                                          component.id,
                                          proOrMat?.id ? proOrMat.id : null,
                                          question.id,
                                          component.assignType,
                                          question,
                                          `survey_question_ref_${proOrMatKey}_${question.id}`,
                                          question.isCondVisible
                                        )
                                    "
                                    :ref="
                                      `survey_question_ref_${proOrMatKey}_${question.id}`
                                    "
                                  />
                                </v-col>
                              </template>
                            </template>
                          </v-row>
                          <v-row v-if="component.isAction">
                            <v-col
                              cols="12"
                              xs="12"
                              sm="3"
                              class="text-left d-flex align-center"
                            >
                              <b-button
                                class="mr-1"
                                variant="success"
                                size="sm"
                                style="color: #fff !important;"
                                @click="
                                  onClickUpsertActions(
                                    surveyListItem,
                                    component,
                                    proOrMat,
                                    null,
                                    null
                                  )
                                "
                              >
                                <b-icon icon="plus"></b-icon>
                                {{ $t("Add Action") }}
                              </b-button>
                            </v-col>
                            <v-col cols="12" xs="12" sm="9" class="text-left">
                              <v-chip
                                :class="
                                  `m-2 added_action_chip ${
                                    actionItem.actionType === 'PROMOLOCAL'
                                      ? 'chip_promo_local'
                                      : ''
                                  }`
                                "
                                v-for="(actionItem,
                                actionKey) in proOrMat?.actions"
                                v-bind:key="`add_action_item_${actionKey}`"
                                close
                                @click:close="
                                  onClickRemoveAction(
                                    surveyListItem,
                                    component,
                                    proOrMat,
                                    actionKey
                                  )
                                "
                              >
                                <div
                                  class="px-2 py-2"
                                  style="cursor: pointer;"
                                  @click="
                                    onClickUpsertActions(
                                      surveyListItem,
                                      component,
                                      proOrMat,
                                      actionItem,
                                      actionKey
                                    )
                                  "
                                >
                                  {{ actionItem?.actionLabel }}
                                  <v-divider class="my-1"></v-divider>
                                  <p style="max-width: 200px; text-wrap: wrap">
                                    {{ actionItem.comments }}
                                  </p>
                                </div>
                              </v-chip>
                            </v-col>
                          </v-row>
                          <v-row v-if="component.assignType === 'PRODUCT'">
                            <v-col cols="12" md="12">
                              <promosAnswerTable
                                v-if="currentPromosForSelectedBrand.length > 0"
                                :currentPromosForSelectedBrand="
                                  getCurrentPromosForSelectedBrandProOrMat(
                                    currentPromosForSelectedBrand,
                                    component,
                                    proOrMat
                                  )
                                "
                                :promosTypeList="promosTypeList"
                                :placeTypeList="placeTypeList"
                                :folletoTypeList="folletoTypeList"
                                :isPresentTypeList="isPresentTypeList"
                                :component="component"
                                :proOrMat="proOrMat"
                                @changeAnswer="
                                  (changeKey, record) =>
                                    persistPromosAnswer(changeKey, record)
                                "
                              />
                            </v-col>
                          </v-row>

                          <v-divider
                            v-if="
                              Object.keys(component.proOrMatList).pop() !==
                                proOrMatKey
                            "
                            class="survey_pro_divider"
                          ></v-divider>
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
          class="d-flex justify-content-center align-center"
        >
          <v-btn
            color="success"
            dark
            class="mx-1 my-2"
            @click="onSaveClick"
            :loading="isSaving"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <b-alert v-if="!surveys_response_actions" show variant="info" class="mt-4">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import OfflineService from "@/services/offlineService";
import StorageService from "@/services/storageService";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { logInfo, logError } from "@/utils";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import sessionTagLabels from "@/components/sessionTagLabels";
import SurveyQuestion from "./question";
import SurveyComponentTitle from "./surveyComponentTitle";
import SurveyListView from "./surveyListView";
import SurveyTableView from "./surveyTableView";
import {
  QUESTION_TYPES,
  OP_STRING,
  OP_BOOL,
  OP_NUMBER,
  CALC_OPERATE_SYMBOLS
} from "@/views/surveys/models.js";
import DialogOnePage from "../DialogOnePage.vue";
import DialogFotosAnterios from "../DialogFotosAnterios.vue";
import DialogPromos from "../DialogPromos.vue";
import promosAnswerTable from "./promosAnswerTable.vue";
import DialogPromoLocal from "./DialogPromoLocal.vue";

export default {
  name: "posbrandsurvey",
  props: ["brandId"],
  components: {
    sessionTagLabels,
    SurveyQuestion,
    CoolLightBox,
    DialogOnePage,
    DialogFotosAnterios,
    DialogPromos,
    promosAnswerTable,
    DialogPromoLocal,
    SurveyComponentTitle,
    SurveyListView,
    SurveyTableView
  },
  data: function() {
    return {
      showType: "STANDARD_VIEW",
      QUESTION_TYPES: QUESTION_TYPES,
      COMPARE_OPRATORS: {},
      sessionId: null,
      worksessionPosId: null,
      posSession: null,
      worksessionPosBrands: [],
      worksessionPosBrandId: null,
      selectedBrandId: null,
      selectedBrand: null,
      posSessionChainCriteria: null,
      detectedSurveyChainIdForPuncDetails: null,
      posBrandPSLastVisit: {},
      posBrandPSObjective: 0,
      componentConditionals: {},
      questionConditionals: {},
      mergedFamlies: null,

      filterCriteria: {
        surveyId: [],
        surveyComponentId: [],
        surveyProOrMatId: [],
        surveyQuestionId: [],
        proMainFamilyId: [],
        proSubFamilyId: [],
        matMainFamilyId: [],
        matSubFamilyId: [],
        proSubBrandId: [],
        matSubBrandId: []
      },
      bufferFilterCriteria: null,
      initialFilterCriteria: null,

      // survey_list: [],
      surveys_response_actions: null,
      saveValues: null,
      surveys_promos_answers: [],
      selectedBrandItem: null,

      isSaving: false,
      selected_image: null,
      selected_promatItem: null,
      showImageDialog: false,

      showAddActionDialog: false,
      selectedAddActionItem: null,
      surveyActionsForBrand: [],

      showFilterDialog: false,

      selectedCompQueImage: null,
      showCompQueImageDialog: false,
      selectedProductAlertList: null,
      showProductAlertDialog: false,
      approvalStatus: {},
      assignTypes: {},
      selectedWorksessionSurveyResponseApproval: null,
      showCorrectionAlertDialog: false,

      pdfFilePrefix:
        process.env.VUE_APP_ENV !== "development"
          ? "https://docs.google.com/gview?embedded=true&url="
          : // ? "https://docs.google.com/viewerng/viewer?url="
            "",
      placeTypeList: null,
      promosTypeList: null,
      folletoTypeList: null,
      isPresentTypeList: null,
      actionTypeList: null,
      actionTypes: {},
      currentPromosForSelectedBrand: [],

      totalEstimatedTimeSeconds: null,
      totalEstimatedTimeDuration: null,
      current_worked_time_seconds: 0,
      currentWorkedTime: 0,
      logTimeIntervalId: null,

      isLoadingSpinner: false
    };
  },
  watch: {
    async $route(to, from) {
      if (to !== from) {
        await this.changedSelectedBrand(to.params.brandId);
      }
    }
  },
  computed: {
    ...mapGetters("myroute", [
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession",
      "deviceInformation"
    ]),
    posTagLabels() {
      let ret = [];
      if (
        this.posSession &&
        this.posSession?.po &&
        this.posSession.po.postaglabels.length > 0 &&
        this.brandId
      ) {
        ret = this.posSession.po.postaglabels.filter(
          el => el.brandId == this.brandId
        );
      }
      return ret;
    },
    isMobileView() {
      let ret = false;
      if (
        this.deviceInformation &&
        ["MOBILE"].includes(this.deviceInformation?.type)
      ) {
        ret = true;
      }
      return ret;
    },
    isTabletView() {
      let ret = false;
      if (
        this.deviceInformation &&
        ["TABLET"].includes(this.deviceInformation?.type)
      ) {
        ret = true;
      }
      return ret;
    },
    isPCView() {
      let ret = false;
      if (
        this.deviceInformation &&
        ["PC"].includes(this.deviceInformation?.type)
      ) {
        ret = true;
      }
      return ret;
    },
    isThereOthersBottom() {
      let ret = false;
      if (this.surveyList && Object.values(this.surveyList).length > 0) {
        for (const [surveyId, surveyItem] of Object.entries(this.surveyList)) {
          if (
            surveyItem?.components &&
            Object.values(surveyItem.components).length > 0
          ) {
            for (const [componentId, componentItem] of Object.entries(
              surveyItem.components
            )) {
              if (
                componentItem?.assignType === "OTHERS" &&
                componentItem?.isOthersBottom
              ) {
                ret = true;
                break;
              }
            }
          }
        }
      }
      return ret;
    },
    currentVisitType() {
      let ret = "PRESENT";
      if (this.posSession) {
        ret = this.posSession?.visitType;
        if (this.posSession?.changedVisitType)
          ret = this.posSession?.changedVisitType;
      }
      return ret;
    },
    isExceedWorkTime() {
      return this.current_worked_time_seconds > this.totalEstimatedTimeSeconds;
    },
    surveyList() {
      let survey_list = {};
      if (
        this.surveys_response_actions &&
        Object.keys(this.surveys_response_actions?.surveys).length > 0
      ) {
        survey_list = this.surveys_response_actions.surveys;
      }
      return survey_list;
    },
    filterableSurveyList() {
      let survey_list = [];
      if (this.surveyList && Object.keys(this.surveyList).length > 0) {
        for (const [surveyId, surveyItem] of Object.entries(this.surveyList)) {
          let pushItem = {
            id: surveyId,
            name: surveyItem.name
          };
          survey_list.push(pushItem);
        }
      }
      return survey_list;
    },
    filterableSurveyComponentList() {
      let component_list = [];
      for (const surveyItem of Object.values(this.surveyList)) {
        if (
          this.filterCriteria.surveyId.length === 0 ||
          this.filterCriteria.surveyId
            .map(el => parseInt(el))
            .includes(parseInt(surveyItem?.id))
        ) {
          component_list = [
            ...component_list,
            ...Object.values(surveyItem.components)
          ];
        }
      }
      if (component_list.length > 0) {
        component_list = _.uniqBy(component_list, "id");
      }
      return component_list;
    },
    assignTypeForSelectedComponent() {
      let assignType = "";
      if (this.filterCriteria["surveyComponentId"]) {
        let foundSurveyComponent = this.filterableSurveyComponentList.find(
          el => el.id === this.filterCriteria["surveyComponentId"]
        );
        if (foundSurveyComponent) {
          assignType = foundSurveyComponent.assignType;
        }
      }
      return assignType;
    },
    filterableProOrMatList() {
      let dataList = [];
      for (const component of Object.values(
        this.filterableSurveyComponentList
      )) {
        if (
          this.filterCriteria.surveyComponentId.length === 0 ||
          this.filterCriteria.surveyComponentId
            .map(el => parseInt(el))
            .includes(parseInt(component.id))
        ) {
          for (const proOrMatItem of Object.values(component.proOrMatList)) {
            if (proOrMatItem?.id) dataList.push(proOrMatItem);
          }
        }
      }
      if (dataList.length > 0) {
        dataList = _.uniqBy(dataList, "id");
      }
      return dataList;
    },
    filterableQuestions() {
      let dataList = [];
      for (const component of Object.values(
        this.filterableSurveyComponentList
      )) {
        if (
          this.filterCriteria.surveyComponentId.length === 0 ||
          this.filterCriteria.surveyComponentId
            .map(el => parseInt(el))
            .includes(parseInt(component.id))
        ) {
          for (const proOrMatItem of Object.values(component.proOrMatList)) {
            if (Object.values(proOrMatItem?.questions).length > 0) {
              dataList = [
                ...dataList,
                ...Object.values(proOrMatItem?.questions)
              ];
            }
          }
        }
      }
      if (dataList.length > 0) {
        dataList = _.uniqBy(dataList, "id");
      }
      return dataList;
    },
    displayPosBrandPSLastVisit() {
      let calcedValue = 0;
      for (const [surveyId, value] of Object.entries(
        this.posBrandPSLastVisit
      )) {
        calcedValue += value;
      }
      return calcedValue;
    },
    titleOfAddActionDialog() {
      if (
        this.selectedAddActionItem &&
        this.selectedAddActionItem?.actionKey >= 0
      ) {
        return this.$t("edititem");
      } else {
        return this.$t("Add Action");
      }
    },
    filterableProSubBrandList() {
      let list = [];
      if (this.mergedFamlies && this.mergedFamlies["proSubBrands"].length > 0) {
        list = this.mergedFamlies["proSubBrands"].map(el => el);
      }
      return list;
    },
    filterableMatSubBrandList() {
      let list = [];
      if (this.mergedFamlies && this.mergedFamlies["matSubBrands"].length > 0) {
        list = this.mergedFamlies["matSubBrands"].map(el => el);
      }
      return list;
    },
    filterableProMainFamilyList() {
      let list = [];
      if (
        this.mergedFamlies &&
        this.mergedFamlies["proMainFamilies"].length > 0
      ) {
        list = this.mergedFamlies["proMainFamilies"].map(el => el);
      }
      return list;
    },
    filterableMatMainFamilyList() {
      let list = [];
      if (
        this.mergedFamlies &&
        this.mergedFamlies["matMainFamilies"].length > 0
      ) {
        list = this.mergedFamlies["matMainFamilies"].map(el => el);
      }
      return list;
    },
    filterableProSubFamilyList() {
      let list = [];
      if (
        this.mergedFamlies &&
        this.mergedFamlies["proSubFamilies"].length > 0
      ) {
        list = this.mergedFamlies["proSubFamilies"].map(el => el);
      }
      if (this.filterCriteria?.proMainFamilyId.length > 0) {
        let selectedMainFamilyId = this.filterCriteria.proMainFamilyId.map(el =>
          parseInt(el)
        );
        list = list.filter(el =>
          selectedMainFamilyId.includes(el.savedParentId)
        );
      }
      return list;
    },
    filterableMatSubFamilyList() {
      let list = [];
      if (
        this.mergedFamlies &&
        this.mergedFamlies["matSubFamilies"].length > 0
      ) {
        list = this.mergedFamlies["matSubFamilies"].map(el => el);
      }
      if (this.filterCriteria?.matMainFamilyId.length > 0) {
        let selectedMainFamilyId = this.filterCriteria.matMainFamilyId.map(el =>
          parseInt(el)
        );
        list = list.filter(el =>
          selectedMainFamilyId.includes(el.savedParentId)
        );
      }
      return list;
    },
    isActiveFilter() {
      let isActive = false;
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            isActive = true;
            break;
          }
        } else if (value) {
          isActive = true;
          break;
        }
      }
      return isActive;
    },
    actionCount() {
      let action_counts = 0;
      if (
        this.surveys_response_actions &&
        Object.keys(this.surveys_response_actions?.surveys).length > 0
      ) {
        for (const [surveyId, surveyItem] of Object.entries(
          this.surveys_response_actions.surveys
        )) {
          for (const [surveyComponentId, componentItem] of Object.entries(
            surveyItem.components
          )) {
            if (!componentItem?.isAction) continue;
            for (const [proOrMatId, proOrMatItem] of Object.entries(
              componentItem.proOrMatList
            )) {
              action_counts += proOrMatItem.actions.length;
            }
          }
        }
      }
      return action_counts;
    },
    isShowOnepageModal() {
      let pdfCount = 0;
      this.onepagePdfList.map(
        el =>
          (pdfCount += el?.onepagePdfContents
            ? el.onepagePdfContents.length
            : 0)
      );
      return pdfCount > 0;
    },
    isShowFotosModal() {
      let pdfCount = 0;
      this.fotosPdfList.map(
        el => (pdfCount += el.fotosPdfContents ? el.fotosPdfContents.length : 0)
      );
      return pdfCount > 0;
    },
    onepagePdfList() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["onepagePdfContents"] = [];
        push_item["isIncluded"] = true;
        if (el.id === this.selectedBrandId) {
          if (push_item?.brand_onepages) {
            push_item["onepagePdfContents"] = push_item.brand_onepages.map(
              oneEl => {
                let onepageItem = {
                  ...oneEl
                };
                if (oneEl?.onepagePdfFile?.file) {
                  onepageItem["pdfPath"] =
                    this.pdfFilePrefix +
                    ApiService.getFileUri(oneEl.onepagePdfFile);
                }
                return onepageItem;
              }
            );
          }
          return push_item;
        }
      });
      data = data.filter(el => el !== undefined && el !== null);
      return data;
    },
    fotosPdfList() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["fotosPdfContents"] = [];
        push_item["isIncluded"] = true;
        if (push_item?.brand_fotos_anteriors) {
          push_item["fotosPdfContents"] = push_item.brand_fotos_anteriors
            .filter(oneEl => oneEl.status === "active")
            .map(oneEl => {
              let fotosItem = {
                ...oneEl
              };
              if (oneEl?.fotosAnteriosPdfFile?.file) {
                fotosItem["pdfPath"] =
                  this.pdfFilePrefix +
                  ApiService.getFileUri(oneEl.fotosAnteriosPdfFile);
              }
              return fotosItem;
            });
        }
        return push_item;
      });
      return data;
    },
    isShowPromosModal() {
      let pdfCount = 0;
      this.promosPdfList.map(
        el =>
          (pdfCount += el?.promosPdfContents ? el.promosPdfContents.length : 0)
      );
      return pdfCount > 0;
    },
    promosPdfList() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["promosPdfContents"] = [];
        push_item["isIncluded"] = true;
        if (el.id === this.selectedBrandId) {
          if (push_item?.brand_promos) {
            push_item["promosPdfContents"] = push_item.brand_promos.map(
              promosEl => {
                let promosItem = {
                  ...promosEl
                };
                if (promosEl?.promosPdfFile?.file) {
                  promosItem["pdfPath"] =
                    this.pdfFilePrefix +
                    ApiService.getFileUri(promosEl.promosPdfFile);
                }
                return promosItem;
              }
            );
          }
          return push_item;
        }
      });
      data = data.filter(el => el !== undefined && el !== null);
      return data;
    },
    currentWorksessionPosBrand() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        if (el.id === this.selectedBrandId) {
          return push_item;
        }
      });
      data = data.filter(el => el !== undefined && el !== null);
      return data;
    }
  },
  methods: {
    onChangeShowType() {
      StorageService.saveToken(
        StorageService.KEY_SHOW_TYPE_IN_SURVEY,
        this.showType
      );
    },
    isShowIncludingOthersBottom(component) {
      let ret = false;
      if (component.assignType !== "OTHERS" || !this.isPCView) {
        ret = true;
      } else if (this.isPCView) {
        if (
          component.assignType !== "OTHERS" ||
          (component.assignType == "OTHERS" && !component.isOthersBottom)
        ) {
          ret = true;
        }
      }
      return ret;
    },
    isShowProductColumnInTableView(component) {
      let isShow = true;
      if (component && component?.assignType === "OTHERS") {
        if (
          component?.survey_component_options.length === 0 &&
          !component?.isAction
        ) {
          isShow = false;
        }
      } else {
        if (
          component.pro_mat_list.filter(el => el !== null).length === 0 &&
          !component?.isAction
        ) {
          isShow = false;
        }
      }
      return isShow;
    },
    getEstimatedTime(estimatedTime) {
      let formatedTime = "";
      if (estimatedTime) {
        const [hour, minute, sec] = estimatedTime.split(":");
        formatedTime = [hour, minute].join(":");
      }
      return formatedTime;
    },
    getComponentItem(components, surveyComponentId) {
      if (
        Object.keys(components)
          .map(el => parseInt(el))
          .includes(surveyComponentId)
      ) {
        return components[surveyComponentId];
      }
      return null;
    },
    getProOrMatItem(proOrMatList, proOrMatId) {
      if (
        Object.keys(proOrMatList)
          .map(el => (!isNaN(parseInt(el)) ? parseInt(el) : null))
          .includes(proOrMatId ? parseInt(proOrMatId) : null)
      ) {
        return proOrMatList[proOrMatId];
      }
      return null;
    },
    getPSSumOfProOrMatItem(proOrMat) {
      let psSum = 0;
      if (proOrMat && proOrMat?.questions) {
        for (const [questionId, questionItem] of Object.entries(
          proOrMat?.questions
        )) {
          if (questionItem?.maximumScore && !isNaN(questionItem.maximumScore)) {
            psSum += questionItem.maximumScore;
          }
        }
      }
      return psSum > 0;
    },
    getQuestionItem(questions, questionId) {
      if (
        Object.keys(questions)
          .map(el => parseInt(el))
          .includes(questionId)
      ) {
        return questions[questionId];
      }
      return null;
    },
    getQuestionList(component) {
      let visibleQuestions = [];
      if (component && component?.proOrMatList) {
        let questions = [];
        for (const [proOrMatId, proOrMatItem] of Object.entries(
          component["proOrMatList"]
        )) {
          for (const [questionId, questionItem] of Object.entries(
            proOrMatItem["questions"]
          )) {
            if (questionItem.isVisible && questionItem.isCondVisible) {
              let found = questions.find(el => el.id === questionItem.id);
              if (!found) questions.push(questionItem);
            }
          }
        }
        if (component?.questionOrder) {
          for (const uuid of component.questionOrder) {
            let foundItem = questions.find(el => el.uuid === uuid);
            if (foundItem) {
              visibleQuestions.push(foundItem);
            }
          }
        }
      }
      return visibleQuestions;
    },
    persistPromosAnswer(changeKey, record) {
      const {
        worksessionPosBrandId,
        surveyComponentId,
        productId,
        materialId,
        brandPromosId
      } = record;
      const changedValue = record[changeKey];
      let filteredAt = this.surveys_promos_answers.findIndex(
        el =>
          el.worksessionPosBrandId === worksessionPosBrandId &&
          el.surveyComponentId === surveyComponentId &&
          el.productId === productId &&
          el.materialId === materialId &&
          el.brandPromosId === brandPromosId
      );
      if (filteredAt < 0) {
        this.surveys_promos_answers.push(record);
      } else {
        this.surveys_promos_answers[filteredAt] = record;
      }
    },
    getCurrentPromosForSelectedBrandProOrMat(
      currentPromosForSelectedBrand,
      component,
      proOrMat
    ) {
      let currentPromosForBrandProOrMat = [];
      let proOrMatId = proOrMat?.id ? proOrMat.id : null;
      let { assignType } = component;
      currentPromosForBrandProOrMat = currentPromosForSelectedBrand.map(el => {
        if (el.isSurveyResponse !== "NO") {
          let pushItem = {
            ...el,
            worksessionPosBrandId: this.worksessionPosBrandId,
            surveyComponentId: component.id,
            productId: null,
            materialId: null,
            brandPromosId: el.id,
            isAnswer: false
          };
          if (proOrMat?.type === "product")
            pushItem = { ...pushItem, productsLabel: proOrMat?.name };
          if (
            assignType === "PRODUCT" &&
            el.productIds.map(el => parseInt(el)).includes(proOrMatId)
          ) {
            pushItem["productId"] = proOrMatId;
            return pushItem;
          } else if (
            assignType === "MATERIAL" &&
            el.materialId === proOrMatId
          ) {
            pushItem["materialId"] = proOrMatId;
            return pushItem;
          }
        }
      });
      currentPromosForBrandProOrMat = currentPromosForBrandProOrMat.filter(
        el => el !== undefined && el !== null
      );
      let addedAnswerValues = [];
      for (let i = 0; i < currentPromosForBrandProOrMat.length; i++) {
        addedAnswerValues.push(currentPromosForBrandProOrMat[i]);
        const {
          worksessionPosBrandId,
          surveyComponentId,
          productId,
          materialId,
          brandPromosId
        } = currentPromosForBrandProOrMat[i];
        if (
          currentPromosForBrandProOrMat[i].statusKey === "ACTIVE" &&
          currentPromosForBrandProOrMat[i].isSurveyResponse === "YES"
        ) {
          let isAnswerRow = {
            isAnswer: true,
            worksessionPosBrandId,
            surveyComponentId,
            productId,
            materialId,
            brandPromosId,
            promosTypeValue: null,
            priceValue: null,
            placeValue: null,
            spaceValue: null,
            materialValue: null,
            folletoValue: null
          };
          /****** CHECK SAVED ANSWERS ******/
          let filteredAt = this.surveys_promos_answers.findIndex(
            el =>
              el.worksessionPosBrandId === worksessionPosBrandId &&
              el.surveyComponentId === surveyComponentId &&
              el.productId === productId &&
              el.materialId === materialId &&
              el.brandPromosId === brandPromosId
          );
          if (filteredAt >= 0) {
            [
              "promosTypeValue",
              "priceValue",
              "placeValue",
              "spaceValue",
              "materialValue",
              "folletoValue",
              "isPresent"
            ].map(readKey => {
              isAnswerRow[readKey] = this.surveys_promos_answers[filteredAt][
                readKey
              ];
            });
          }
          addedAnswerValues.push(isAnswerRow);
        }
      }
      if (addedAnswerValues.length > 0) {
        addedAnswerValues = addedAnswerValues.map(el => {
          let pushItem = { ...el };
          pushItem.orderDateLabel = "";
          let orderDates = [];
          if (pushItem.startOrderDate) orderDates.push(pushItem.startOrderDate);
          else orderDates.push("-");
          if (pushItem.endOrderDate) orderDates.push(pushItem.endOrderDate);
          else orderDates.push("-");
          pushItem.orderDateLabel = orderDates.join(" / ");
          return pushItem;
        });
      }
      return addedAnswerValues;
    },
    setVisibleByConditionalsAndCalculating() {
      /****** CHECK COMPONENT'S VISIBLE BY CONDITIONALS ******/
      let condComponentIds = Object.keys(this.componentConditionals);
      let condQuestionIds = Object.keys(this.questionConditionals);
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions["surveys"]
      )) {
        for (const [componentId, componentItem] of Object.entries(
          surveyItem["components"]
        )) {
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem["proOrMatList"]
          )) {
            for (const [questionId, questionItem] of Object.entries(
              proOrMatItem["questions"]
            )) {
              if (
                questionItem?.conditionals &&
                questionItem?.conditionals.length > 0
              ) {
                let isVisibleQueForTheConditionals = this.isFullFilledForConditionals(
                  questionItem?.conditionals,
                  componentItem,
                  proOrMatItem,
                  questionItem
                );
                this.setValueSurveyCompProOrMatQueItem(
                  surveyId,
                  componentId,
                  proOrMatId,
                  questionId,
                  "SETQUESTION",
                  "isCondVisible",
                  isVisibleQueForTheConditionals
                );
                if (
                  !isVisibleQueForTheConditionals &&
                  !["IMPORT_DATA", "CALCULATION_DATA"].includes(
                    questionItem.questionImportType
                  )
                ) {
                  // init question's values
                  this.setValueSurveyCompProOrMatQueItem(
                    surveyId,
                    componentId,
                    proOrMatId,
                    questionId,
                    "SETQUESTION",
                    "value",
                    null
                  );
                  this.setValueSurveyCompProOrMatQueItem(
                    surveyId,
                    componentId,
                    proOrMatId,
                    questionId,
                    "SETQUESTION",
                    "score",
                    0
                  );
                }
              }
              if (
                questionItem.questionImportType === "CALCULATION_DATA" &&
                questionItem?.calculations &&
                questionItem?.calculations.length > 0
              ) {
                let calcedValue = this.calculation_get_and_set_values(
                  questionItem?.calculations,
                  componentItem,
                  proOrMatItem,
                  questionItem
                );
                this.setValueSurveyCompProOrMatQueItem(
                  surveyId,
                  componentId,
                  proOrMatId,
                  questionId,
                  "SETQUESTION",
                  "value",
                  calcedValue
                );
              }
            }
          }
          if (componentItem?.conditionals.length > 0) {
            let isVisibleCompForTheConditionals = this.isFullFilledForConditionals(
              componentItem?.conditionals,
              componentItem,
              null,
              null
            );
            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              componentId,
              null,
              null,
              "SETCOMPONENT",
              "isCondVisible",
              isVisibleCompForTheConditionals
            );
            if (!isVisibleCompForTheConditionals) {
              // initial all questions' values for this component
              this.initializeCompQueValues(surveyId, componentId);
            }
          }
        }
      }

      /****** CHECK QUESTION'S VISIBLE BY CONDITIONALS ******/
    },
    isFullFilledForConditionals(
      conditionals,
      currentComponent,
      currentProOrMatItem,
      currentQuestion
    ) {
      let condResults = [];
      let condIntegrateOpts = [];
      for (const cond of conditionals) {
        const { foundComponent, foundQuestions, values } = this.getCompQueValue(
          cond.component,
          cond.question,
          currentComponent,
          currentProOrMatItem,
          currentQuestion
        );
        let currentValues = null;
        let foundQuestionItem = null;
        if (foundComponent && foundQuestions.length > 0 && values.length > 0) {
          foundQuestionItem = foundQuestions[0];
          currentValues = values.map(el => {
            if (el !== null && el !== undefined) {
              return el.toString();
            } else {
              return null;
            }
          });
          currentValues = _.uniq(currentValues);
        }
        if (foundComponent && foundQuestionItem && currentValues) {
          if (
            [
              QUESTION_TYPES.MONEY,
              QUESTION_TYPES.DECIMAL,
              QUESTION_TYPES.NUMBER
            ].includes(foundQuestionItem.questionType)
          ) {
            currentValues = currentValues
              .map(el => parseFloat(el))
              .map(el => {
                if (isNaN(el)) return null;
                else return el;
              });
          }

          let filteredFullfilledValues = currentValues.filter(val =>
            this.compareConditional(
              val,
              cond.val,
              cond.op,
              foundQuestionItem.questionType
            )
          );
          condResults.push(filteredFullfilledValues.length > 0);
          condIntegrateOpts.push(cond.integrationOfOperation);
        }
      }
      let isCondOk = false;
      if (
        condResults.length > 0 &&
        condIntegrateOpts.length === condResults.length
      ) {
        isCondOk = condResults[0];
        for (let i = 1; i < condResults.length; i++) {
          if (condIntegrateOpts[i] === "AND") {
            isCondOk = isCondOk && condResults[i];
          } else {
            isCondOk = isCondOk || condResults[i];
          }
        }
      }
      return isCondOk;
    },
    calcQuestionItem(
      calc,
      foundComponent,
      foundQuestions,
      values,
      valuesWithProOrMatKey
    ) {
      let ret = 0;
      if (foundQuestions.length === 0) return ret;
      let questionItem = foundQuestions[0];
      let questionType = questionItem.survey_question_type?.type;
      let questionOptions = questionItem.options;
      let calcValues = calc.values;
      let calcProOrMatOptions = calc.options;
      console.log("calcProOrMatOptions - ", calcProOrMatOptions);

      if (
        [
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.ACTION
        ].includes(questionType) &&
        calc.operation === "COUNT"
      ) {
        let savedValues = [];
        for (const [proOrMatId, value] of Object.entries(
          valuesWithProOrMatKey
        )) {
          console.log("XcalcProOrMatOptions - ", proOrMatId, value);
          if (
            (!calcProOrMatOptions || calcProOrMatOptions.length === 0) &&
            `${proOrMatId}` == "null"
          ) {
            savedValues.push(value);
          } else if (calcProOrMatOptions && calcProOrMatOptions.length > 0) {
            if (calcProOrMatOptions.includes(-1)) {
              console.log(
                "XcalcProOrMatOptions.includes(-1) - ",
                proOrMatId,
                value
              );
              savedValues.push(value);
            } else if (
              calcProOrMatOptions.map(el => `${el}`).includes(`${proOrMatId}`)
            ) {
              savedValues.push(value);
            }
          }
        }

        let savedValueLabels = [];
        if (questionOptions?.values) {
          for (const valueItem of savedValues) {
            if (Array.isArray(questionOptions.values)) {
              if (
                !isNaN(valueItem) &&
                questionOptions.values.length > parseFloat(valueItem)
              ) {
                savedValueLabels.push(questionOptions.values[valueItem]);
              }
            } else {
              if (Object.keys(questionOptions.values).includes(valueItem)) {
                savedValueLabels.push(questionOptions.values[valueItem]);
              }
            }
          }
          if (calcValues && calcValues.length > 0) {
            savedValueLabels.map(el => {
              if (calcValues.includes(el)) {
                ret++;
              }
            });
          }
        }
        ret = this.getCalcedValueForNumberQuestion(calc, [ret]);
      } else if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER
        ].includes(questionType) &&
        calc.operation !== "COUNT"
      ) {
        let savedValues = [];
        for (const [proOrMatId, value] of Object.entries(
          valuesWithProOrMatKey
        )) {
          console.log("YcalcProOrMatOptions - ", proOrMatId, value);
          if (
            (!calcProOrMatOptions || calcProOrMatOptions.length === 0) &&
            `${proOrMatId}` == "null"
          ) {
            savedValues.push(value);
          } else if (calcProOrMatOptions && calcProOrMatOptions.length > 0) {
            if (calcProOrMatOptions.includes(-1)) {
              console.log(
                "YcalcProOrMatOptions.includes(-1) - ",
                proOrMatId,
                value
              );
              savedValues.push(value);
            } else if (
              calcProOrMatOptions.map(el => `${el}`).includes(`${proOrMatId}`)
            ) {
              savedValues.push(value);
            }
          }
        }
        ret = this.getCalcedValueForNumberQuestion(calc, savedValues);
      }
      return ret;
    },
    getCalcedValueForNumberQuestion(calc, savedValues) {
      let format =
        isNaN(calc.format) || calc.format == 0 ? 1 : parseFloat(calc.format);
      let ret = 0;
      if (calc && savedValues) {
        savedValues = savedValues.map(el =>
          !el || isNaN(el) ? 0 : parseFloat(el)
        );
        let calcedTotalSum = _.sum(savedValues);
        const { operation } = calc;
        if (operation === "SUM") {
          ret = calcedTotalSum;
        } else if (operation === "MULTIPLY") {
          ret = calcedTotalSum * format;
        } else if (operation === "SUBTRACT") {
          ret = -1 * calcedTotalSum;
        } else if (operation === "DIVIDE") {
          ret = calcedTotalSum / format;
        } else {
          ret = calcedTotalSum;
        }
      }
      if (calc?.multipler) {
        if (calc.multipler === "MULTIPLY") {
          ret = ret * format;
        } else if (calc.multipler === "DIVIDE") {
          ret = ret / format;
        }
      }
      return ret;
    },
    calculation_get_and_set_values(
      calculations,
      currentComponent,
      currentProOrMatItem,
      currentQuestion
    ) {
      let calcResults = [];
      let calcIntegrateOpts = [];
      for (const calc of calculations) {
        const {
          foundComponent,
          foundQuestions,
          values,
          valuesWithProOrMatKey
        } = this.getCompQueValue(
          calc.component,
          calc.question,
          currentComponent,
          currentProOrMatItem,
          currentQuestion,
          true
        );
        let calcTotalValue = this.calcQuestionItem(
          calc,
          foundComponent,
          foundQuestions,
          values,
          valuesWithProOrMatKey
        );
        calcResults.push(calcTotalValue);
        calcIntegrateOpts.push(calc.integrationOfOperation);
      }

      let calcedQuestionItemTotalValue = this.getTotalCalculatedValue(
        calcResults,
        calcIntegrateOpts
      );

      let value = calcedQuestionItemTotalValue;
      if (currentQuestion.calcParenthesys !== null)
        value = this.calculateForParenthesys(
          currentQuestion.calcParenthesys,
          calcResults
        );

      console.log(
        "*****************************************************************",
        calcResults,
        calcIntegrateOpts,
        currentQuestion.calcParenthesys,
        value
      );

      return value;
    },
    calculateForParenthesys(expression, calcResults) {
      const tokens = this.tokenize(expression);
      return this.evaluate(tokens, calcResults);
    },
    tokenize(expression) {
      const operators = ["+", "-", "*", "/", "(", ")"];
      const tokens = [];
      let number = "";

      for (let char of expression) {
        if (char === " ") {
          continue; // Skip whitespace
        } else if (operators.includes(char)) {
          if (number !== "") {
            tokens.push(number);
            number = "";
          }
          tokens.push(char);
        } else {
          number += char;
        }
      }

      if (number !== "") {
        tokens.push(number);
      }

      return tokens;
    },
    evaluate(tokens, calcResults) {
      const operators = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
        "*": (a, b) => a * b,
        "/": (a, b) => a / b
      };

      const precedence = {
        "+": 1,
        "-": 1,
        "*": 2,
        "/": 2
      };
      const values = [];
      const operatorsStack = [];

      for (let token of tokens) {
        if (token in operators) {
          while (
            operatorsStack.length > 0 &&
            precedence[operatorsStack[operatorsStack.length - 1]] >=
              precedence[token]
          ) {
            const operator = operatorsStack.pop();
            const operand2 = values.pop();
            const operand1 = values.pop();
            values.push(operators[operator](operand1, operand2));
          }
          operatorsStack.push(token);
        } else if (token === "(") {
          operatorsStack.push(token);
        } else if (token === ")") {
          while (
            operatorsStack.length > 0 &&
            operatorsStack[operatorsStack.length - 1] !== "("
          ) {
            const operator = operatorsStack.pop();
            const operand2 = values.pop();
            const operand1 = values.pop();
            values.push(operators[operator](operand1, operand2));
          }
          operatorsStack.pop(); // Pop the '('
        } else {
          values.push(calcResults[parseInt(token) - 1]);
        }
      }

      while (operatorsStack.length > 0) {
        const operator = operatorsStack.pop();
        const operand2 = values.pop();
        const operand1 = values.pop();
        values.push(operators[operator](operand1, operand2));
      }

      return values.pop();
    },
    getTotalCalculatedValue(calcResults, calcIntegrateOpts) {
      let calcedQuestionItemTotalValue = 0;
      if (
        calcResults.length > 0 &&
        calcIntegrateOpts.length === calcResults.length
      ) {
        let operators = calcIntegrateOpts
          .map(el => {
            if (Object.keys(CALC_OPERATE_SYMBOLS).includes(el)) {
              return CALC_OPERATE_SYMBOLS[el];
            }
            return undefined;
          })
          .filter(el => el);
        let expression = calcResults[0];
        for (let i = 1; i < operators.length; i++) {
          expression += operators[i] + calcResults[i];
        }
        calcedQuestionItemTotalValue = eval(expression);
        if (isNaN(calcedQuestionItemTotalValue))
          calcedQuestionItemTotalValue = 0;
      }
      if (
        calcedQuestionItemTotalValue !== 0 &&
        !isNaN(calcedQuestionItemTotalValue)
      ) {
        calcedQuestionItemTotalValue = parseFloat(
          calcedQuestionItemTotalValue.toFixed(3)
        );
      }
      return calcedQuestionItemTotalValue;
    },
    getCompQueValue(
      compUUID,
      queUUID,
      currentComponent,
      currentProOrMatItem,
      currentQuestion,
      isCheckAllProOrMat
    ) {
      let isCheckOnlyInCurrentProOrMat = false;
      if (!isCheckAllProOrMat && compUUID === currentComponent.uuid) {
        isCheckOnlyInCurrentProOrMat = true;
      }

      let retVal = [];
      let values = null;
      let valuesWithProOrMatKey = {};
      let foundComponent = null;
      let foundQuestions = [];
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions["surveys"]
      )) {
        let findComponent = Object.values(surveyItem.components).find(
          el => el.uuid === compUUID
        );
        if (findComponent) {
          foundComponent = findComponent;
          for (let [proOrMatId, proOrMat] of Object.entries(
            foundComponent.proOrMatList
          )) {
            if (isCheckOnlyInCurrentProOrMat) {
              let currentProOrMatId = null;
              if (currentProOrMatItem?.id) {
                currentProOrMatId = currentProOrMatItem?.id;
              }
              if (proOrMatId !== null && proOrMatId !== "null")
                proOrMatId = parseInt(proOrMatId);
              else if (proOrMatId === "null") proOrMatId = null;
              if (proOrMatId != currentProOrMatId) continue;
            }
            let findQuestionItem = Object.values(proOrMat.questions).find(
              el => el.uuid === queUUID
            );
            if (findQuestionItem) {
              foundQuestions.push(findQuestionItem);
              if (findQuestionItem?.isCondVisible) {
                valuesWithProOrMatKey[proOrMatId] = findQuestionItem.value;
              }
            }
          }
          break;
        } else {
          continue;
        }
      }
      if (foundComponent && foundQuestions.length > 0) {
        values = foundQuestions.map(el => el.value);
        for (const value of values) {
          if (Array.isArray(value)) {
            for (const valItem of value) {
              retVal.push(valItem);
            }
          } else {
            retVal.push(value);
          }
        }
      }
      return {
        foundComponent,
        foundQuestions,
        values: retVal,
        valuesWithProOrMatKey
      };
    },
    compareConditional(value1, value2, op, questionType) {
      let result = false;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER,
          QUESTION_TYPES.DATE
        ].includes(questionType)
      ) {
        let val1 = value1;
        let val2 = value2;
        if (
          [
            QUESTION_TYPES.MONEY,
            QUESTION_TYPES.DECIMAL,
            QUESTION_TYPES.NUMBER
          ].includes(questionType)
        ) {
          val1 = parseFloat(value1);
          val2 = parseFloat(value2);
          if (isNaN(val1) || isNaN(val2)) {
            return result;
          }
        }
        switch (op) {
          case "lt":
            result = val1 < val2;
            break;
          case "le":
            result = val1 <= val2;
            break;
          case "eq":
            result = val1 === val2;
            break;
          case "gt":
            result = val1 > val2;
            break;
          case "ge":
            result = val1 >= val2;
            break;
          case "ne":
            result = val1 !== val2;
            break;
          default:
            result = val1 === val2;
            break;
        }
      } else {
        switch (op) {
          case "eq":
            result = value1 === value2;
            break;
          case "ne":
            result = value1 !== value2;
            break;
          default:
            result = value1 === value2;
            break;
        }
      }
      return result;
    },
    initializeCompQueValues(initSurveyId, initComponentId) {
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions["surveys"]
      )) {
        if (surveyId !== initSurveyId) continue;
        for (const [componentId, componentItem] of Object.entries(
          surveyItem["components"]
        )) {
          if (componentId !== initComponentId) continue;
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem["proOrMatList"]
          )) {
            for (const [questionId, questionItem] of Object.entries(
              proOrMatItem["questions"]
            )) {
              if (
                !["IMPORT_DATA", "CALCULATION_DATA"].includes(
                  questionItem.questionImportType
                )
              ) {
                this.setValueSurveyCompProOrMatQueItem(
                  surveyId,
                  componentId,
                  proOrMatId,
                  questionId,
                  "SETQUESTION",
                  "value",
                  null
                );
              }
            }
          }
        }
      }
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    getImageUri(image) {
      return ApiService.getImageUri(image);
    },
    async changedSelectedBrand(currentSelectedBrandId) {
      this.surveys_response_actions = null;
      this.mergedFamlies = null;
      this.clearFilterParams();
      await this.init(currentSelectedBrandId);
    },
    async onClickSelectedBrand(currentSelectedBrandId) {
      this.$router.push({
        name: "router_worksessionpos_surveyv2",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          brandId: currentSelectedBrandId
        }
      });
    },
    onChangeProSubBrand() {
      this.onChangeFilterSurveys();
    },
    onChangeMatSubBrand() {
      this.onChangeFilterSurveys();
    },
    onChangeProMainFamily() {
      this.filterCriteria.proSubFamilyId = [];
      this.onChangeFilterSurveys();
    },
    onChangeMatMainFamily() {
      this.filterCriteria.matSubFamilyId = [];
      this.onChangeFilterSurveys();
    },
    onChangeProSubFamily() {
      if (this.filterCriteria?.proSubFamilyId) {
        let foundSubFamilyItem = this.filterableProSubFamilyList.find(
          el => el.id === this.filterCriteria.proSubFamilyId
        );
        if (foundSubFamilyItem) {
          let filteredParentId = foundSubFamilyItem.savedParentId;
          this.filterCriteria.proMainFamilyId = filteredParentId;
        }
      }
      this.onChangeFilterSurveys();
    },
    onChangeMatSubFamily() {
      if (this.filterCriteria?.matSubFamilyId) {
        let foundSubFamilyItem = this.filterableMatSubFamilyList.find(
          el => el.id === this.filterCriteria.matSubFamilyId
        );
        if (foundSubFamilyItem) {
          let filteredParentId = foundSubFamilyItem.savedParentId;
          this.filterCriteria.matMainFamilyId = filteredParentId;
        }
      }
      this.onChangeFilterSurveys();
    },
    async onChangeSurvey() {
      this.filterCriteria["surveyComponentId"] = [];
      this.filterCriteria["surveyProOrMatId"] = [];
      this.filterCriteria["surveyQuestionId"] = [];
      this.onChangeFilterSurveys();
    },
    async onChangeSurveyComponent() {
      this.filterCriteria["surveyProOrMatId"] = [];
      this.filterCriteria["surveyQuestionId"] = [];
      this.onChangeFilterSurveys();
    },
    async onChangeSurveyComponentProOrMat() {
      this.filterCriteria["surveyQuestionId"] = [];
      this.onChangeFilterSurveys();
    },
    async onChangeSurveyQuestion() {
      this.onChangeFilterSurveys();
    },
    async onChangeFilterSurveys() {
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions.surveys
      )) {
        surveyItem.isVisible = false;
        for (const [surveyComponentId, componentItem] of Object.entries(
          surveyItem.components
        )) {
          componentItem.isVisible = false;
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem.proOrMatList
          )) {
            proOrMatItem.isVisible = false;
            proOrMatItem.isVisibleAction = false;

            if (proOrMatItem?.actions && proOrMatItem?.actions.length > 0) {
              let updated_actions = proOrMatItem?.actions.map(actionItem => {
                let updated_item = { ...actionItem };
                let isVisibleAction = true;
                if (this.filterCriteria?.no_answers) {
                  if (actionItem.comments && actionItem.comments !== "") {
                    isVisibleAction &= false;
                  }
                }
                if (
                  this.filterCriteria?.zero_points ||
                  this.filterCriteria?.isMandatory
                ) {
                  isVisibleAction &= false;
                }
                updated_item.isVisible = isVisibleAction;
                proOrMatItem.isVisibleAction =
                  proOrMatItem.isVisibleAction || isVisibleAction;
                return updated_item;
              });
              this.setValueSurveyCompProOrMatQueItem(
                surveyId,
                surveyComponentId,
                proOrMatId,
                null,
                "SETPROORMAT",
                "actions",
                updated_actions
              );
            }

            if (Object.keys(proOrMatItem.questions).length === 0) {
              proOrMatItem.isVisible = true;
              proOrMatItem.isVisibleAction = true;
            }

            for (const [surveyQuestionId, question] of Object.entries(
              proOrMatItem.questions
            )) {
              let isVisibleArray = [true];
              question.isVisible = true;
              let isMandatoryQuestion = question.isMandatory;
              if ([QUESTION_TYPES.PHOTO].includes(question.questionType)) {
                const {
                  isRequiredPortalComment,
                  isRequiredPortalScore
                } = question;
                if (!isMandatoryQuestion) {
                  if (isRequiredPortalComment || isRequiredPortalScore) {
                    isMandatoryQuestion = true;
                  }
                }
              }
              if (this.filterCriteria?.isMandatory && !isMandatoryQuestion) {
                isVisibleArray.push(false);
              }

              let surveyQuestionIsCompleted = this.isValueFullfilled(
                question.questionType,
                question
              );
              let isPhotoPortalCompleted = true;
              let isQuestionPhoto = false;
              if ([QUESTION_TYPES.PHOTO].includes(question.questionType)) {
                isPhotoPortalCompleted = this.isPortalPhotosFullfilled(
                  question.questionType,
                  question
                );
                isQuestionPhoto = true;
              }

              if (this.filterCriteria?.zero_points) {
                let current_point = question.score;
                if (!question.optionScores) {
                  isVisibleArray.push(false);
                }
                if (question.optionScores && !question.optionScores?.PSValues) {
                  isVisibleArray.push(false);
                }
                if (
                  question.maximumScore !== null &&
                  question.score >= question.maximumScore
                ) {
                  if (surveyQuestionIsCompleted) {
                    isVisibleArray.push(false);
                  }
                }
                if (question.optionScores && question.optionScores?.PSValues) {
                  let ps_values = Object.values(
                    question.optionScores?.PSValues
                  );
                  if (!ps_values.includes(question.maximumScore)) {
                    isVisibleArray.push(false);
                  }
                }
              }

              if (
                this.filterCriteria?.no_answers ||
                this.filterCriteria?.answers
              ) {
                let isFilterAnswer = false;
                if (this.filterCriteria?.answers) {
                  isFilterAnswer = true;
                }
                if (isFilterAnswer) {
                  if (isQuestionPhoto) {
                    if (!surveyQuestionIsCompleted || !isPhotoPortalCompleted) {
                      isVisibleArray.push(false);
                    }
                  } else {
                    if (!surveyQuestionIsCompleted) isVisibleArray.push(false);
                  }
                } else {
                  if (isQuestionPhoto) {
                    if (surveyQuestionIsCompleted && isPhotoPortalCompleted) {
                      isVisibleArray.push(false);
                    }
                  } else {
                    if (surveyQuestionIsCompleted) isVisibleArray.push(false);
                  }
                }
              }

              if (
                this.filterCriteria?.surveyQuestionId.length > 0 &&
                !this.filterCriteria?.surveyQuestionId
                  .map(el => parseInt(el))
                  .includes(parseInt(surveyQuestionId))
              ) {
                isVisibleArray.push(false);
              }

              if (this.filterCriteria?.new_actions) {
                let is_visible_new_action_option = [false];
                if ([QUESTION_TYPES.ACTION].includes(question?.questionType)) {
                  let actionsOptionDetails = question?.options;
                  let actionItems = [];
                  if (actionsOptionDetails && actionsOptionDetails?.values) {
                    for (const [optionId, optionItem] of Object.entries(
                      actionsOptionDetails?.values
                    )) {
                      if (optionItem?.isAction) {
                        actionItems.push(optionId);
                      }
                    }
                  }
                  let currentSurveyQuestionItem = question;
                  if (Array.isArray(question.value)) {
                    let selectedActionItems = question?.value.filter(el =>
                      actionItems.includes(el)
                    );
                    if (selectedActionItems.length > 0)
                      is_visible_new_action_option.push(true);
                  } else {
                    if (actionItems.includes(question?.value)) {
                      is_visible_new_action_option.push(true);
                    }
                  }
                }
                if (
                  question?.worksession_survey_response_approval &&
                  question?.worksession_survey_response_approval
                    .approvalStatus === "NO"
                ) {
                  is_visible_new_action_option.push(true);
                }
                is_visible_new_action_option = is_visible_new_action_option.some(
                  el => el === true
                );
                if (!is_visible_new_action_option) {
                  isVisibleArray.push(false);
                }
              }

              question.isVisible = isVisibleArray.every(el => el === true);
              this.setValueSurveyCompProOrMatQueItem(
                surveyId,
                surveyComponentId,
                proOrMatId,
                surveyQuestionId,
                "SETQUESTION",
                "isVisible",
                question.isVisible
              );
              proOrMatItem.isVisible =
                proOrMatItem.isVisible || question.isVisible;
            }

            if (
              this.filterCriteria?.surveyProOrMatId.length > 0 &&
              !this.filterCriteria?.surveyProOrMatId
                .map(el => parseInt(el))
                .includes(parseInt(proOrMatId))
            ) {
              proOrMatItem.isVisible = false;
              proOrMatItem.isVisibleAction = false;
            }
            if (proOrMatItem.isVisible) {
              if (
                this.filterCriteria?.proSubBrandId.length > 0 ||
                this.filterCriteria?.matSubBrandId.length > 0
              ) {
                let filterSubBrandId = [
                  ...this.filterCriteria?.proSubBrandId,
                  ...this.filterCriteria?.matSubBrandId
                ];
                let mergedProOrMatSubBrandIds = [];
                let proOrMatSubBrandIds = null;
                if (proOrMatItem?.productSubBrand) {
                  if (Array.isArray(proOrMatItem?.productSubBrand)) {
                    proOrMatSubBrandIds = proOrMatItem?.productSubBrand.map(
                      el => el?.id
                    );
                  } else {
                    proOrMatSubBrandIds = [proOrMatItem?.productSubBrand.id];
                  }
                }
                if (proOrMatSubBrandIds && proOrMatSubBrandIds.length > 0) {
                  mergedProOrMatSubBrandIds = [
                    ...mergedProOrMatSubBrandIds,
                    ...proOrMatSubBrandIds
                  ];
                }
                if (proOrMatItem?.subbrand) {
                  if (Array.isArray(proOrMatItem?.subbrand)) {
                    proOrMatSubBrandIds = proOrMatItem?.subbrand.map(
                      el => el?.id
                    );
                  } else {
                    proOrMatSubBrandIds = [proOrMatItem?.subbrand.id];
                  }
                }
                if (proOrMatSubBrandIds && proOrMatSubBrandIds.length > 0) {
                  mergedProOrMatSubBrandIds = [
                    ...mergedProOrMatSubBrandIds,
                    ...proOrMatSubBrandIds
                  ];
                }
                let filteredData = null;
                if (mergedProOrMatSubBrandIds.length > 0) {
                  filteredData = mergedProOrMatSubBrandIds.filter(filterEl =>
                    filterSubBrandId.includes(filterEl)
                  );
                }
                if (
                  mergedProOrMatSubBrandIds.length === 0 ||
                  !filteredData ||
                  filteredData?.length === 0
                ) {
                  proOrMatItem.isVisible = false;
                  proOrMatItem.isVisibleAction = false;
                }
              }
              if (this.filterCriteria?.proMainFamilyId.length > 0) {
                let proOrMatMainFamilyId = null;
                if (proOrMatItem?.family) {
                  proOrMatMainFamilyId = proOrMatItem.family.id;
                } else if (proOrMatItem?.productMainFamily) {
                  proOrMatMainFamilyId = proOrMatItem.productMainFamily.id;
                }
                if (
                  !proOrMatMainFamilyId ||
                  !this.filterCriteria.proMainFamilyId
                    .map(el => parseInt(el))
                    .includes(proOrMatMainFamilyId)
                ) {
                  proOrMatItem.isVisible = false;
                  proOrMatItem.isVisibleAction = false;
                }
              }
              if (this.filterCriteria?.proSubFamilyId.length > 0) {
                let proOrMatSubFamilyId = null;
                if (proOrMatItem?.productSubFamily) {
                  proOrMatSubFamilyId = proOrMatItem.productSubFamily.id;
                }
                if (
                  !proOrMatSubFamilyId ||
                  !this.filterCriteria.proSubFamilyId
                    .map(el => parseInt(el))
                    .includes(proOrMatSubFamilyId)
                ) {
                  proOrMatItem.isVisible = false;
                  proOrMatItem.isVisibleAction = false;
                }
              }
              if (this.filterCriteria?.matMainFamilyId.length > 0) {
                let proOrMatMainFamilyId = null;
                if (proOrMatItem?.materialMainFamily) {
                  proOrMatMainFamilyId = proOrMatItem.materialMainFamily.id;
                }
                if (
                  !proOrMatMainFamilyId ||
                  !this.filterCriteria.matMainFamilyId
                    .map(el => parseInt(el))
                    .includes(proOrMatMainFamilyId)
                ) {
                  proOrMatItem.isVisible = false;
                  proOrMatItem.isVisibleAction = false;
                }
              }
              if (this.filterCriteria?.matSubFamilyId.length > 0) {
                let proOrMatSubFamilyId = null;
                if (proOrMatItem?.materialSubFamily) {
                  proOrMatSubFamilyId = proOrMatItem.materialSubFamily.id;
                }
                if (
                  !proOrMatSubFamilyId ||
                  !this.filterCriteria.matSubFamilyId
                    .map(el => parseInt(el))
                    .includes(proOrMatSubFamilyId)
                ) {
                  proOrMatItem.isVisible = false;
                  proOrMatItem.isVisibleAction = false;
                }
              }
            }

            if (this.filterCriteria?.new_actions) {
              if (
                proOrMatItem?.alertList &&
                proOrMatItem?.alertList.length > 0 &&
                (proOrMatItem?.isReadProductAlert ||
                  !proOrMatItem?.isReadProductAlert)
              ) {
                proOrMatItem.isVisible = true;
                proOrMatItem.isVisibleAction = true;
              }
            }

            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              surveyComponentId,
              proOrMatId,
              null,
              "SETPROORMAT",
              "isVisibleAction",
              proOrMatItem.isVisibleAction
            );
            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              surveyComponentId,
              proOrMatId,
              null,
              "SETPROORMAT",
              "isVisible",
              proOrMatItem.isVisible
            );
          }

          componentItem.isVisible = Object.values(componentItem.proOrMatList)
            .map(el => el.isVisible || el.isVisibleAction)
            .some(el => el === true);

          if (this.filterCriteria?.surveyComponentId.length > 0) {
            if (
              !this.filterCriteria?.surveyComponentId
                .map(el => parseInt(el))
                .includes(parseInt(surveyComponentId))
            ) {
              componentItem.isVisible = false;
            } else {
              componentItem.isVisible = true;
            }
          }

          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            surveyComponentId,
            null,
            null,
            "SETCOMPONENT",
            "isVisible",
            componentItem.isVisible
          );
        }

        surveyItem.isVisible = Object.values(surveyItem.components)
          .map(el => el.isVisible)
          .some(el => el === true);

        if (this.filterCriteria?.surveyId.length > 0) {
          if (
            !this.filterCriteria?.surveyId
              .map(el => parseInt(el))
              .includes(parseInt(surveyId))
          ) {
            surveyItem.isVisible = false;
          } else {
            surveyItem.isVisible = true;
          }
        }
        this.setValueSurveyCompProOrMatQueItem(
          surveyId,
          null,
          null,
          null,
          "SETSURVEY",
          "isVisible",
          surveyItem.isVisible
        );
      }
      this.setVisibleByConditionalsAndCalculating();
    },

    async getProOrMatDetail(assignType, proOrMatItem) {
      let form_data = {
        assignType: assignType,
        proOrMatId: proOrMatItem?.id
      };
      const { data } = await ApiService.post(
        `worksession/survey/getProOrMatDetail`,
        form_data
      );
      return data;
    },
    getProOrMatImageComponent(assignType, proOrMatItem, isThumbnail) {
      let thumbnail_url = null;
      if (proOrMatItem) {
        if (assignType === "PRODUCT" && proOrMatItem?.static) {
          thumbnail_url = isThumbnail
            ? this.getThumbnailUri(proOrMatItem.static)
            : this.getImageUri(proOrMatItem.static);
        } else if (assignType === "MATERIAL" && proOrMatItem?.materialPhoto) {
          thumbnail_url = isThumbnail
            ? this.getThumbnailUri(proOrMatItem.materialPhoto)
            : this.getImageUri(proOrMatItem.materialPhoto);
        } else if (
          assignType === "OTHERS" &&
          proOrMatItem?.surveyComponentOptionPhoto
        ) {
          thumbnail_url = isThumbnail
            ? this.getThumbnailUri(proOrMatItem?.surveyComponentOptionPhoto)
            : this.getImageUri(proOrMatItem?.surveyComponentOptionPhoto);
        }
      }
      return thumbnail_url;
    },
    getFamilyInformation(proOrMat) {
      let displayStr = "";
      let familyList = [];
      if (proOrMat?.family) {
        familyList.push(proOrMat.family.name);
        if (proOrMat?.productSubFamily) {
          familyList.push(proOrMat.productSubFamily.name);
        }
      }
      if (proOrMat?.materialMainFamily) {
        familyList.push(proOrMat.materialMainFamily.name);
        if (proOrMat?.materialSubFamily) {
          familyList.push(proOrMat.materialSubFamily.name);
        }
      }
      if (familyList.length > 0) {
        displayStr = ` - ${familyList.join(", ")}`;
      }
      return displayStr;
    },
    onClickFilterDialog() {
      this.showFilterDialog = true;
      this.bufferFilterCriteria = JSON.parse(
        JSON.stringify(this.filterCriteria)
      );
    },
    confirmFilterDialog() {
      this.showFilterDialog = false;
    },
    cancelFilterDialog() {
      this.filterCriteria = JSON.parse(
        JSON.stringify(this.bufferFilterCriteria)
      );
      this.onChangeFilterSurveys();
      this.showFilterDialog = false;
    },
    clearFilterParams() {
      for (const [filterKey, val] of Object.entries(this.filterCriteria)) {
        if (Object.keys(this.initialFilterCriteria).includes(filterKey)) {
          this.filterCriteria[filterKey] = this.initialFilterCriteria[
            filterKey
          ];
        } else {
          this.filterCriteria[filterKey] = false;
        }
      }
    },
    clearFilterDialog() {
      this.clearFilterParams();
      this.onChangeFilterSurveys();
      this.showFilterDialog = false;
    },
    showDialogOnePage() {
      if (this.isShowOnepageModal > 0) {
        this.$refs.dialogOnePage.showModal();
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    showDialogFotosAnterios() {
      if (this.isShowFotosModal > 0) {
        this.$refs.dialogFotos.showModal();
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    showDialogPromos() {
      if (this.isShowPromosModal > 0) {
        this.$refs.dialogPromos.showModal();
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    async onItemShowImageDialog(assignType, proOrMatItem) {
      this.selected_promatItem = proOrMatItem;
      this.isLoadingSpinner = true;
      try {
        const response = await this.getProOrMatDetail(assignType, proOrMatItem);
        if (response) {
          this.selected_promatItem = {
            ...this.selected_promatItem,
            ...response
          };
        }
        let thumbnail_url = this.getProOrMatImageComponent(
          assignType,
          proOrMatItem,
          false
        );
        this.selected_image = thumbnail_url;
        this.showImageDialog = true;
      } catch (error) {}
      this.isLoadingSpinner = false;
    },
    async onClickProductAlert(
      productAlertList,
      surveyListItem,
      component,
      proOrMat
    ) {
      // mark as read for the product alert list
      const { posId } = this.posSession;
      let body = {
        productAlertList: productAlertList.map(el => el.id),
        posId
      };
      this.isLoadingSpinner = true;
      try {
        const { alertList } = await ApiService.post(
          `worksession/markasreadproductalerts`,
          body
        );
        if (alertList) {
          this.selectedProductAlertList = productAlertList.map(el => {
            let pushItem = { ...el };
            let foundMessageContent = alertList.find(fEl => fEl.id == el.id);
            if (foundMessageContent) {
              pushItem = { ...pushItem, ...foundMessageContent };
            }
            return pushItem;
          });
        }
        let surveyId = surveyListItem.id;
        let currentProOrMatId = proOrMat?.id;
        for (const [surveyComponentId, componentItem] of Object.entries(
          this.surveys_response_actions.surveys[surveyId].components
        )) {
          if (
            Object.keys(componentItem.proOrMatList)
              .map(el => parseInt(el))
              .includes(parseInt(currentProOrMatId))
          ) {
            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              surveyComponentId,
              currentProOrMatId,
              null,
              "SETPROORMAT",
              "isReadProductAlert",
              true
            );
          }
        }
        this.showProductAlertDialog = true;
      } catch (error) {}
      this.isLoadingSpinner = false;
    },
    onClickCompQueImage(image) {
      if (image) {
        this.selectedCompQueImage = ApiService.getImageUri(image);
        this.showCompQueImageDialog = true;
      }
    },
    onClickCorrectionAlert(worksession_survey_response_approval) {
      if (worksession_survey_response_approval) {
        console.log(
          "worksession_survey_response_approval - ",
          worksession_survey_response_approval
        );
        this.selectedWorksessionSurveyResponseApproval = worksession_survey_response_approval;
        this.showCorrectionAlertDialog = true;
      }
    },
    saveOfflineRealTimeData(formBody) {
      console.log("XXXXXXXXXXXX - SAVE OFFLINE REALTIME DATA - XXXXXXXXXXXXXX");
      this.$store.dispatch("myroute/setIsOffline", true);
      const { questionType, saveValue } = formBody;
      if ([QUESTION_TYPES.PHOTO].includes(questionType)) {
        OfflineService.saveImage(saveValue, formBody);
      } else {
        OfflineService.saveOfflineRealTimeData(formBody);
      }
    },
    async addNewImageToAttachment(
      saveValue,
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId
    ) {
      this.setValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "SETQUESTION",
        "attachments",
        saveValue
      );
      this.setValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "SETQUESTION",
        "isFromLastResponse",
        false
      );
    },
    async realtimeSaveResponse(
      saveObjectiveRealValue,
      saveValue,
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      assignTypeValue,
      questionType,
      isCondVisible,
      maximumScore,
      kpiObjectiveValue,
      isKPI,
      isSales,
      attachments
    ) {
      if (this.isEndedSession) return;
      let currentQuestionScore = this.getValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "GETQUESTION",
        "score"
      );
      let currentSurveyItem = this.surveys_response_actions.surveys[surveyId];
      const { score, isCompleted, isMandatoryCompleted } = currentSurveyItem;
      let worksessionSurveyData = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        surveyId,
        score,
        isCompleted,
        isMandatoryCompleted
      };
      let body = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        saveObjectiveRealValue,
        saveValue,
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        assignTypeValue,
        questionType,
        score: currentQuestionScore,
        worksessionSurveyData,
        isCondVisible,
        maximumScore,
        kpiObjectiveValue,
        isKPI,
        isSales,
        prevAttachments: attachments
      };
      if ([QUESTION_TYPES.PHOTO].includes(questionType)) {
        // Before uploading the image, add an image to attachment
        this.addNewImageToAttachment(
          saveValue,
          surveyId,
          componentId,
          surveyProOrMatId,
          surveyQuestionId
        );

        let uploadedPhotos = [];
        try {
          for (const attachedItem of saveValue) {
            let { data } = await ApiService.uploadImage(attachedItem, true);
            if (data) {
              let uploadedStatusPushItem = {
                photoId: data.id
              };
              uploadedPhotos.push(uploadedStatusPushItem);
            }
          }
          if (uploadedPhotos.length > 0) {
            body["uploadedPhotos"] = uploadedPhotos;
          }
        } catch (error) {
          console.log("error - ", error);
          this.saveOfflineRealTimeData(body);
          return;
        }
      }
      try {
        body["posId"] = this.posSession?.po?.id;
        body["brandId"] = parseInt(this.brandId);
        const response = await ApiService.postNotAlert(
          `worksession/realTimeSaveSurveyResponse`,
          body
        );
        if (
          [QUESTION_TYPES.PHOTO].includes(questionType) &&
          response &&
          response?.foundSurveyResponse
        ) {
          let attachments =
            response.foundSurveyResponse
              ?.worksession_survey_response_attachments;
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "attachments",
            attachments
          );
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "isFromLastResponse",
            false
          );
        }
      } catch (error) {
        console.log("error - ", error);
        this.saveOfflineRealTimeData(body);
      }
    },
    isImportedData(questionItem) {
      let ret = false;
      if (["IMPORT_DATA"].includes(questionItem?.questionImportType))
        ret = true;
      return ret;
    },
    foundImportedAnswerItem(questionItem) {
      return questionItem?.foundImportedAnswerItem;
    },
    importedObjective(questionItem) {
      let foundImportedAnswerItem = this.foundImportedAnswerItem(questionItem);
      return foundImportedAnswerItem
        ? foundImportedAnswerItem.importedObjective
        : null;
    },
    getImportedAndKPIObjectiveValue(questionItem) {
      let found = null;
      let importedObjective = this.importedObjective(questionItem);
      if (this.isImportedData(questionItem) && importedObjective !== null) {
        found = importedObjective;
      } else {
        if (questionItem.survey_question_pos_objective) {
          found = questionItem.survey_question_pos_objective?.objectiveValue;
        }
      }
      if (found !== null && !isNaN(found)) found = parseFloat(found);
      return found;
    },
    updatePhotosPortalAttributes(
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      questionType,
      update_data
    ) {
      let worksession_survey_photos_portal = this.getValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "GETQUESTION",
        "worksession_survey_photos_portal"
      );
      if (worksession_survey_photos_portal) {
        worksession_survey_photos_portal = {
          ...worksession_survey_photos_portal,
          ...update_data
        };
        this.setValueSurveyCompProOrMatQueItem(
          surveyId,
          componentId,
          surveyProOrMatId,
          surveyQuestionId,
          "SETQUESTION",
          "worksession_survey_photos_portal",
          worksession_survey_photos_portal
        );
      }
    },
    async deleteAttachmentItem(
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      questionType,
      delId
    ) {
      if (this.isEndedSession) return;
      let currentSurveyItem = this.surveys_response_actions.surveys[surveyId];
      const { score, isCompleted, isMandatoryCompleted } = currentSurveyItem;
      let worksessionSurveyData = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        surveyId
      };
      let body = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        worksessionSurveyData,
        worksessionSurveyResponseAttachmentId: delId
      };
      if ([QUESTION_TYPES.PHOTO].includes(questionType)) {
        this.isLoadingSpinner = true;
        try {
          const response = await ApiService.post(
            `worksession/deleteAttachmentItem`,
            body
          );
          let attachments = [];
          let worksession_survey_photos_portal = null;
          if (response && response?.foundSurveyResponse) {
            attachments =
              response.foundSurveyResponse
                ?.worksession_survey_response_attachments;
            worksession_survey_photos_portal =
              response.foundSurveyResponse?.worksession_survey_photos_portal;
          }
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "attachments",
            attachments
          );
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "worksession_survey_photos_portal",
            worksession_survey_photos_portal
          );
        } catch (error) {}
        this.isLoadingSpinner = false;
      }
    },
    async onClickSaveComments(
      saveData,
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      assignTypeValue,
      question
    ) {
      const { maximumScore } = question;
      let attachments = this.surveys_response_actions["surveys"][surveyId][
        "components"
      ][componentId]["proOrMatList"][surveyProOrMatId]["questions"][
        surveyQuestionId
      ]?.attachments;
      // CALL BACKEND API - should upsert worksession_survey_response for today if there is no data for today, clone today's attachments and save comments and punc-score for GPV

      if (this.isEndedSession) return;
      let currentQuestionScore = this.getValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "GETQUESTION",
        "score"
      );
      let currentSurveyItem = this.surveys_response_actions.surveys[surveyId];
      const { score, isCompleted, isMandatoryCompleted } = currentSurveyItem;
      let worksessionSurveyData = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        surveyId,
        score,
        isCompleted,
        isMandatoryCompleted
      };
      let body = {
        worksessionPosBrandId: this.worksessionPosBrandId,
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        assignTypeValue,
        score: currentQuestionScore,
        worksessionSurveyData,
        portalComments: saveData,
        maximumScore,
        prevAttachments: attachments
      };
      this.isLoadingSpinner = true;
      try {
        const response = await ApiService.post(
          `worksession/savephotosportal`,
          body
        );
        if (response && response?.foundSurveyResponse) {
          logInfo("Éxito");
          let worksession_survey_photos_portal =
            response.foundSurveyResponse?.worksession_survey_photos_portal;
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "worksession_survey_photos_portal",
            worksession_survey_photos_portal
          );
          let attachments =
            response.foundSurveyResponse
              ?.worksession_survey_response_attachments;
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "attachments",
            attachments
          );
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            surveyProOrMatId,
            surveyQuestionId,
            "SETQUESTION",
            "isFromLastResponse",
            false
          );
        }
      } catch (error) {}
      this.isLoadingSpinner = false;
    },
    persistQuestionValue(
      result,
      questionType,
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      assignTypeValue,
      question,
      referenceName,
      isCondVisible
    ) {
      let saveValue = result;
      const { maximumScore, isKPI, isSales } = question;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER
        ].includes(questionType)
      ) {
        if (result !== "") saveValue = parseFloat(result);
        else saveValue = null;
        if (isNaN(saveValue)) {
          saveValue = null;
          this.$refs[referenceName][0].result = saveValue;
        }
        if (question?.options && saveValue !== null) {
          if (
            Object.keys(question.options).includes("min") &&
            question.options.min > saveValue
          ) {
            logError(
              `el valor introducido es inferior al mínimo(${question.options.min})`
            );
            saveValue = question.options.min;
            if (this.$refs[referenceName]) {
              this.$refs[referenceName][0].result = saveValue;
            }
          }
          if (
            Object.keys(question.options).includes("max") &&
            question.options.max < saveValue
          ) {
            logError(
              `el valor introducido es superior al máximo(${question.options.max})`
            );
            saveValue = question.options.max;
            if (this.$refs[referenceName]) {
              this.$refs[referenceName][0].result = saveValue;
            }
          }
        }
      }
      this.surveys_response_actions["surveys"][surveyId]["components"][
        componentId
      ]["proOrMatList"][surveyProOrMatId]["questions"][
        surveyQuestionId
      ].value = saveValue;
      let attachments = this.surveys_response_actions["surveys"][surveyId][
        "components"
      ][componentId]["proOrMatList"][surveyProOrMatId]["questions"][
        surveyQuestionId
      ]?.attachments;
      let saveObjectiveRealValue = this.updateObjectiveRealValueForActionPush(
        saveValue,
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        assignTypeValue,
        questionType
      );
      let kpiObjectiveValue = this.getImportedAndKPIObjectiveValue(question);
      this.setVisibleByConditionalsAndCalculating();
      this.calcPoints();
      this.realtimeSaveResponse(
        saveObjectiveRealValue,
        saveValue,
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        assignTypeValue,
        questionType,
        isCondVisible,
        maximumScore,
        kpiObjectiveValue,
        isKPI,
        isSales,
        attachments
      );
      this.onChangeFilterSurveys();
    },
    updateObjectiveRealValueForActionPush(
      saveValue,
      surveyId,
      componentId,
      surveyProOrMatId,
      surveyQuestionId,
      assignTypeValue,
      questionType
    ) {
      let questionOptions = this.getValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "GETQUESTION",
        "options"
      );
      let currentObjectiveRealValueForActionPush = this.getValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "GETQUESTION",
        "objectiveRealValueForActionPush"
      );
      let saveObjectiveRealValue = 0;
      if (
        [
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.ACTION
        ].includes(questionType)
      ) {
        let objectiveRealValues = [];
        let optionKeys = [];
        if (Array.isArray(saveValue)) {
          optionKeys = saveValue;
        } else {
          optionKeys.push(saveValue);
        }
        console.log("optionKeys - ", optionKeys);
        if (questionOptions && questionOptions?.objectiveRealValues) {
          optionKeys.map(optionKeyItem => {
            if (
              Object.keys(questionOptions.objectiveRealValues).includes(
                optionKeyItem
              ) &&
              !isNaN(
                parseFloat(questionOptions.objectiveRealValues[optionKeyItem])
              )
            ) {
              objectiveRealValues.push(
                parseFloat(questionOptions.objectiveRealValues[optionKeyItem])
              );
            }
          });
        }
        console.log("objectiveRealValues - ", objectiveRealValues);
        if (objectiveRealValues.length > 0) {
          saveObjectiveRealValue = _.sum(objectiveRealValues);
        }
      } else if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER
        ].includes(questionType)
      ) {
        saveObjectiveRealValue = saveValue;
      }
      console.log("saveObjectiveRealValue - ", saveObjectiveRealValue);
      this.setValueSurveyCompProOrMatQueItem(
        surveyId,
        componentId,
        surveyProOrMatId,
        surveyQuestionId,
        "SETQUESTION",
        "objectiveRealValueForActionPush",
        saveObjectiveRealValue
      );
      return saveObjectiveRealValue;
    },
    calcPoints() {
      let totalSurveyBrandScore = 0;
      let isCompletedSurveyBrand = true;
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions.surveys
      )) {
        let totalSurveyScore = 0;
        let isCompletedSurvey = true;
        let isMandatoryCompletedSurvey = true;
        const { surveyChainId } = surveyItem;
        for (const [componentId, componentItem] of Object.entries(
          surveyItem.components
        )) {
          let totalSurveyComponentScore = 0;
          let isCompletedSurveyComponent = true;
          const { assignType } = componentItem;
          let isCondVisibleComp = componentItem.isCondVisible;
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem.proOrMatList
          )) {
            let totalSurveyComponentProOrMatScore = 0;
            let isCompletedSurveyComponentProOrMat = true;
            for (const [surveyQuestionId, questionItem] of Object.entries(
              proOrMatItem.questions
            )) {
              let isCompletedSurveyQuestion = false;
              let isMandatoryCompletedSurveyQuestion = false;
              const {
                questionType,
                optionScores,
                isSumPS,
                isCondVisible
              } = questionItem;
              let isCondVisibleQuestion = questionItem.isCondVisible;
              let surveyQuestionProOrMatScore = this.getScoreOfCurrentQuestion(
                assignType,
                questionType,
                surveyId,
                componentId,
                proOrMatId,
                surveyQuestionId,
                proOrMatItem,
                questionItem,
                surveyChainId,
                optionScores?.options,
                optionScores?.PSValues
              );
              if (isCondVisible) {
                if (isSumPS) {
                  totalSurveyComponentProOrMatScore += surveyQuestionProOrMatScore;
                }
              } else {
                surveyQuestionProOrMatScore = 0;
              }
              this.setValueSurveyCompProOrMatQueItem(
                surveyId,
                componentId,
                proOrMatId,
                surveyQuestionId,
                "SETQUESTION",
                "score",
                surveyQuestionProOrMatScore
              );

              isCompletedSurveyQuestion = this.isValueFullfilled(
                questionType,
                questionItem
              );

              this.setValueSurveyCompProOrMatQueItem(
                surveyId,
                componentId,
                proOrMatId,
                surveyQuestionId,
                "SETQUESTION",
                "isCompleted",
                isCompletedSurveyQuestion
              );
              isCompletedSurveyComponentProOrMat =
                isCompletedSurveyComponentProOrMat && isCompletedSurveyQuestion;

              if (questionItem?.isMandatory) {
                if (!isCondVisibleComp || !isCondVisibleQuestion) {
                  isMandatoryCompletedSurveyQuestion = true;
                } else {
                  isMandatoryCompletedSurveyQuestion = isCompletedSurveyQuestion;
                }
                this.setValueSurveyCompProOrMatQueItem(
                  surveyId,
                  componentId,
                  proOrMatId,
                  surveyQuestionId,
                  "SETQUESTION",
                  "isMandatoryCompleted",
                  isMandatoryCompletedSurveyQuestion
                );
                isMandatoryCompletedSurvey =
                  isMandatoryCompletedSurvey &&
                  isMandatoryCompletedSurveyQuestion;
              }
            }
            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              componentId,
              proOrMatId,
              null,
              "SETPROORMAT",
              "score",
              totalSurveyComponentProOrMatScore
            );
            totalSurveyComponentScore += totalSurveyComponentProOrMatScore;

            this.setValueSurveyCompProOrMatQueItem(
              surveyId,
              componentId,
              proOrMatId,
              null,
              "SETPROORMAT",
              "isCompleted",
              isCompletedSurveyComponentProOrMat
            );
            isCompletedSurveyComponent =
              isCompletedSurveyComponent && isCompletedSurveyComponentProOrMat;
          }
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            null,
            null,
            "SETCOMPONENT",
            "score",
            totalSurveyComponentScore
          );
          totalSurveyScore += totalSurveyComponentScore;
          this.setValueSurveyCompProOrMatQueItem(
            surveyId,
            componentId,
            null,
            null,
            "SETCOMPONENT",
            "isCompleted",
            isCompletedSurveyComponent
          );
          isCompletedSurvey = isCompletedSurvey && isCompletedSurveyComponent;
        }
        this.setValueSurveyCompProOrMatQueItem(
          surveyId,
          null,
          null,
          null,
          "SETSURVEY",
          "score",
          totalSurveyScore
        );
        totalSurveyBrandScore += totalSurveyScore;
        this.setValueSurveyCompProOrMatQueItem(
          surveyId,
          null,
          null,
          null,
          "SETSURVEY",
          "isCompleted",
          isCompletedSurvey
        );
        isCompletedSurveyBrand = isCompletedSurveyBrand && isCompletedSurvey;
        this.setValueSurveyCompProOrMatQueItem(
          surveyId,
          null,
          null,
          null,
          "SETSURVEY",
          "isMandatoryCompleted",
          isMandatoryCompletedSurvey
        );
      }
      if (isNaN(totalSurveyBrandScore)) {
        totalSurveyBrandScore = 0;
      } else {
        totalSurveyBrandScore = Math.round(parseFloat(totalSurveyBrandScore));
      }
      this.surveys_response_actions.surveyBrandScore = totalSurveyBrandScore;
      this.surveys_response_actions.isCompletedSurveyBrand = isCompletedSurveyBrand;
    },
    isPortalPhotosFullfilled(questionType, dataItem) {
      let ret = true;
      if (dataItem) {
        const {
          isRequiredPortalComment,
          isRequiredPortalScore,
          worksession_survey_photos_portal
        } = dataItem;
        if (!isRequiredPortalComment && !isRequiredPortalScore) return ret;
        if (worksession_survey_photos_portal) {
          const { scoreGPV, commentsGPV } = worksession_survey_photos_portal;
          if (isRequiredPortalComment && (!commentsGPV || commentsGPV == "")) {
            ret = false;
          }
          if (isRequiredPortalScore && !scoreGPV) {
            ret = false;
          }
        } else {
          ret = false;
        }
      }
      return ret;
    },
    isValueFullfilled(questionType, dataItem) {
      let isFullFilled = true;
      if (dataItem?.isCondVisible === false) {
        return isFullFilled;
      }
      if ([QUESTION_TYPES.PHOTO].includes(questionType)) {
        if (
          !dataItem.value &&
          (dataItem?.isFromLastResponse ||
            !dataItem?.attachments ||
            !dataItem?.attachments.length)
        ) {
          isFullFilled = false;
        }
      } else {
        if (Array.isArray(dataItem.value) && dataItem.value.length === 0) {
          isFullFilled = false;
        } else if (
          dataItem.value === null ||
          dataItem.value === undefined ||
          dataItem.value === ""
        ) {
          isFullFilled = false;
        }
      }
      return isFullFilled;
    },
    setValueSurveyCompProOrMatQueItem(
      surveyId,
      componentId,
      proOrMatId,
      surveyQuestionId,
      SET_DEPTH,
      setKey,
      setValue
    ) {
      switch (SET_DEPTH) {
        case "SETQUESTION":
          this.surveys_response_actions["surveys"][surveyId]["components"][
            componentId
          ]["proOrMatList"][proOrMatId]["questions"][surveyQuestionId][
            setKey
          ] = setValue;
          break;
        case "SETPROORMAT":
          this.surveys_response_actions["surveys"][surveyId]["components"][
            componentId
          ]["proOrMatList"][proOrMatId][setKey] = setValue;
          break;
        case "SETCOMPONENT":
          this.surveys_response_actions["surveys"][surveyId]["components"][
            componentId
          ][setKey] = setValue;
          break;
        case "SETSURVEY":
          this.surveys_response_actions["surveys"][surveyId][setKey] = setValue;
          break;
      }
    },
    getValueSurveyCompProOrMatQueItem(
      surveyId,
      componentId,
      proOrMatId,
      surveyQuestionId,
      SET_DEPTH,
      getKey
    ) {
      switch (SET_DEPTH) {
        case "GETQUESTION":
          return this.surveys_response_actions["surveys"][surveyId][
            "components"
          ][componentId]["proOrMatList"][proOrMatId]["questions"][
            surveyQuestionId
          ][getKey];
        case "GETPROORMAT":
          return this.surveys_response_actions["surveys"][surveyId][
            "components"
          ][componentId]["proOrMatList"][proOrMatId][getKey];
        case "GETCOMPONENT":
          return this.surveys_response_actions["surveys"][surveyId][
            "components"
          ][componentId][getKey];
        case "GETSURVEY":
          return this.surveys_response_actions["surveys"][surveyId][getKey];
      }
      return null;
    },
    getScoreOfCurrentQuestion(
      assignType,
      questionType,
      surveyId,
      componentId,
      proOrMatId,
      surveyQuestionId,
      proOrMatItem,
      questionItem,
      surveyChainId,
      currentPSOptions,
      PSValues
    ) {
      let calcValueOfScore = 0;
      if (
        [
          QUESTION_TYPES.BOOL,
          QUESTION_TYPES.RADIO,
          QUESTION_TYPES.CHECKBOX,
          QUESTION_TYPES.SELECT,
          QUESTION_TYPES.ACTION
        ].includes(questionType) &&
        PSValues
      ) {
        if (Array.isArray(questionItem.value)) {
          let selectedOptionKeys = Object.keys(PSValues).filter(el =>
            questionItem.value.includes(el)
          );
          for (const optionKey of selectedOptionKeys) {
            if (PSValues[optionKey] !== null) {
              calcValueOfScore += parseFloat(PSValues[optionKey]);
            }
          }
        } else {
          if (
            Object.keys(PSValues)
              .map(el => parseInt(el))
              .includes(parseInt(questionItem.value))
          ) {
            if (PSValues[questionItem.value] !== null) {
              calcValueOfScore += PSValues[questionItem.value];
            }
          }
        }
      } else if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.DECIMAL,
          QUESTION_TYPES.NUMBER
        ].includes(questionType) &&
        PSValues
      ) {
        if (currentPSOptions && currentPSOptions?.ranges) {
          let selectedRangeKey = null;
          for (const [rangeKey, rangeItem] of Object.entries(
            currentPSOptions.ranges
          )) {
            if (
              parseFloat(questionItem.value) >=
                parseFloat(rangeItem.fromValue) &&
              parseFloat(questionItem.value) <= parseFloat(rangeItem.toValue)
            ) {
              selectedRangeKey = parseInt(rangeKey);
              break;
            }
          }
          if (
            typeof selectedRangeKey === "number" &&
            PSValues[selectedRangeKey]
          ) {
            if (PSValues[selectedRangeKey] !== null) {
              calcValueOfScore += PSValues[selectedRangeKey];
            }
          }
        }
      } else if (PSValues) {
        if (this.isValueFullfilled(questionType, questionItem)) {
          if (Object.keys(PSValues).length > 0) {
            if (PSValues[Object.keys(PSValues)[0]] !== null) {
              calcValueOfScore = PSValues[Object.keys(PSValues)[0]];
            }
          }
        }
      }
      if (isNaN(calcValueOfScore)) {
        console.log(
          "SSSSSSSSSSSS - ",
          assignType,
          questionType,
          surveyId,
          componentId,
          proOrMatId,
          surveyQuestionId,
          proOrMatItem,
          questionItem,
          surveyChainId,
          currentPSOptions,
          PSValues
        );
        calcValueOfScore = 0;
      }
      return calcValueOfScore;
    },
    onChangeActionQuestionId() {
      if (
        this.selectedAddActionItem.surveyQuestionId &&
        this.selectedAddActionItem.surveyQuestionId === "PROMOLOCAL"
      ) {
        this.showAddActionDialog = false;
        this.selectedAddActionItem = {
          ...this.selectedAddActionItem
        };
        this.selectedAddActionItem.actionType = "PROMOLOCAL";
        this.$refs.dialogPromoLocal.showModal(this.selectedAddActionItem);
      } else {
        if (
          this.selectedAddActionItem.surveyQuestionId &&
          this.selectedAddActionItem.surveyQuestionId !== "PROMOS" &&
          this.selectedAddActionItem.surveyQuestionId !== "OTHERS"
        ) {
          this.selectedAddActionItem.actionType = "QUESTION";
          this.selectedAddActionItem.selectableActions = this.surveyActionsForBrand.filter(
            el =>
              el.surveyQuestionId ===
              this.selectedAddActionItem.surveyQuestionId
          );
        } else if (
          this.selectedAddActionItem.surveyQuestionId &&
          this.selectedAddActionItem.surveyQuestionId === "PROMOS"
        ) {
          this.selectedAddActionItem.actionType = "PROMOS";
          this.selectedAddActionItem.selectableActions = this.surveyActionsForBrand.filter(
            el => el.actionType === this.selectedAddActionItem.surveyQuestionId
          );
        } else {
          this.selectedAddActionItem.actionType = "OTHERS";
          this.selectedAddActionItem.actionType = this.selectedAddActionItem.surveyQuestionId;
          this.selectedAddActionItem.selectableActions = [];
          this.selectedAddActionItem.surveyActionId = null;
        }
      }
    },
    async onClickUpsertActions(
      surveyItem,
      surveyComponentItem,
      proOrMatItem,
      actionItem,
      actionKey
    ) {
      let appendQuestionList = [
        { label: "Promos", value: "PROMOS" },
        { label: "Promo Local", value: "PROMOLOCAL" },
        { label: "Otros", value: "OTHERS" }
      ];
      this.selectedAddActionItem = {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        actionKey,
        surveyActionId: _.get(actionItem, "surveyActionId"),
        comments: _.get(actionItem, "comments"),
        actionType: _.get(actionItem, "actionType"),
        surveyQuestionId: "PROMOS",
        selectableQuestionList: [],
        selectableActions: [],
        startDate: _.get(actionItem, "startDate"),
        endDate: _.get(actionItem, "endDate"),
        place: _.get(actionItem, "place"),
        worksession_survey_action_promo_locals: _.get(
          actionItem,
          "worksession_survey_action_promo_locals"
        )
      };
      if (!this.selectedAddActionItem.worksession_survey_action_promo_locals) {
        console.log(
          "surveyComponentItem - ",
          surveyItem.subProductMaterialsChainsThisSurvey
        );
        this.selectedAddActionItem.worksession_survey_action_promo_locals = surveyItem.subProductMaterialsChainsThisSurvey.products.map(
          el => {
            let pushItem = {
              product: el,
              productId: el.id,
              quantity: null,
              freeCharge: null
            };
            return pushItem;
          }
        );
      }
      let survey_questions = surveyComponentItem?.survey_questions;
      if (survey_questions.length > 0) {
        survey_questions.map(el => {
          let selectableActionList = this.surveyActionsForBrand.filter(
            actionsEl => actionsEl.surveyQuestionId === el.id
          );
          if (selectableActionList.length > 0) {
            this.selectedAddActionItem.selectableQuestionList.push({
              label: el.name,
              value: el.id
            });
          }
        });
      }
      this.selectedAddActionItem.selectableQuestionList = [
        ...this.selectedAddActionItem.selectableQuestionList,
        ...appendQuestionList
      ];

      if (!actionItem) {
        this.selectedAddActionItem.actionType = "PROMOS";
        this.selectedAddActionItem.surveyActionId = null;
      } else {
        if (actionItem.actionType !== "QUESTION") {
          this.selectedAddActionItem.surveyQuestionId = this.selectedAddActionItem.actionType;
        }
      }

      if (this.selectedAddActionItem?.surveyActionId) {
        let foundSurveyActionItem = this.surveyActionsForBrand.find(
          el => el.id === actionItem.surveyActionId
        );
        if (foundSurveyActionItem) {
          if (foundSurveyActionItem.actionType === "QUESTION") {
            this.selectedAddActionItem.surveyQuestionId =
              foundSurveyActionItem.surveyQuestionId;
            this.selectedAddActionItem.selectableActions = this.surveyActionsForBrand.filter(
              el =>
                el.surveyQuestionId ===
                this.selectedAddActionItem.surveyQuestionId
            );
          } else {
            this.selectedAddActionItem.surveyQuestionId =
              foundSurveyActionItem.actionType;
            this.selectedAddActionItem.selectableActions = this.surveyActionsForBrand.filter(
              el =>
                el.actionType === this.selectedAddActionItem.surveyQuestionId
            );
          }
        }
      } else {
        this.selectedAddActionItem.surveyQuestionId = this.selectedAddActionItem.actionType;
      }
      this.onChangeActionQuestionId();
      if (this.selectedAddActionItem.actionType !== "PROMOLOCAL") {
        this.showAddActionDialog = true;
      }
    },
    closeCompQueImageDialog() {
      this.showCompQueImageDialog = false;
      this.$nextTick(() => {
        this.selectedCompQueImage = null;
      });
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected_promatItem = null;
        this.selected_image = null;
      });
    },
    closeProductAlertDialog() {
      this.showProductAlertDialog = false;
      this.$nextTick(() => {
        this.selectedProductAlertList = null;
      });
    },
    closeAddActionsDialog() {
      this.showAddActionDialog = false;
      this.$nextTick(() => {
        this.selectedAddActionItem = null;
      });
    },
    get_survey_action_item(surveyActionId) {
      let found = this.surveyActionsForBrand.find(
        el => el.id === surveyActionId
      );
      if (found) return found;
      return null;
    },
    isCheckDuplicationForAddSurveyActions(selectedAddActionItem) {
      let isDuplication = false;
      const {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId,
        actionType,
        actionKey
      } = selectedAddActionItem;

      let proOrMatId = proOrMatItem?.id ? proOrMatItem.id : null;
      const { assignType } = surveyComponentItem;
      if (actionType === "QUESTION" || actionType === "PROMOS") {
        let found = this.surveys_response_actions["surveys"][surveyItem.id][
          "components"
        ][surveyComponentItem.id]["proOrMatList"][proOrMatId]["actions"].filter(
          (el, index) =>
            parseInt(index) !== parseInt(actionKey) &&
            el.surveyActionId === surveyActionId
        );
        if (found.length > 0) isDuplication = true;
      } else {
        let found = this.surveys_response_actions["surveys"][surveyItem.id][
          "components"
        ][surveyComponentItem.id]["proOrMatList"][proOrMatId]["actions"].filter(
          (el, index) =>
            parseInt(index) !== parseInt(actionKey) &&
            el.actionType === actionType
        );
        if (found.length > 0) isDuplication = true;
      }
      return isDuplication;
    },
    onClickSaveAddActions(selectedAddActionItem) {
      let isDuplication = this.isCheckDuplicationForAddSurveyActions(
        selectedAddActionItem
      );
      if (isDuplication) {
        logError("No puede agregar la acción. La acción ya ha sido añadida.");
        return;
      }
      const {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId,
        comments,
        actionType,
        actionKey,
        startDate,
        endDate,
        place,
        worksession_survey_action_promo_locals
      } = selectedAddActionItem;
      let foundActionItem = null;
      if (actionType === "QUESTION" || actionType === "PROMOS") {
        if (!surveyActionId) {
          logError("Debes elegir la acción.");
          return;
        }
        foundActionItem = this.get_survey_action_item(surveyActionId);
      } else {
        if (comments === "" || comments === null || comments === undefined) {
          logError("El comentario es obligatorio.");
          return;
        }
      }
      let pushItem = {
        surveyActionId,
        actionType,
        comments,
        isVisible: true,
        survey_action: null,
        actionLabel: "",
        startDate,
        endDate,
        place,
        worksession_survey_action_promo_locals
      };
      if (actionType === "QUESTION" || actionType === "PROMOS") {
        pushItem.survey_action = foundActionItem;
        if (pushItem.survey_action) {
          pushItem.actionLabel = foundActionItem.name;
        }
      } else {
        pushItem.actionLabel = this.actionTypes[pushItem.actionType];
      }
      let proOrMatId = proOrMatItem?.id ? proOrMatItem.id : null;
      if (actionKey !== null && !isNaN(actionKey)) {
        this.surveys_response_actions["surveys"][surveyItem.id]["components"][
          surveyComponentItem.id
        ]["proOrMatList"][proOrMatId]["actions"][actionKey] = pushItem;
      } else {
        this.surveys_response_actions["surveys"][surveyItem.id]["components"][
          surveyComponentItem.id
        ]["proOrMatList"][proOrMatId]["actions"].push(pushItem);
      }
      this.showAddActionDialog = false;
      if (!this.showAddActionDialog) {
        this.$refs.dialogPromoLocal.hideModal();
      }
      this.$nextTick(() => {
        this.selectedAddActionItem = null;
      });
    },
    async onClickEditAction(
      surveyItem,
      surveyComponentItem,
      proOrMatItem,
      actionItem,
      actionKey
    ) {
      this.selectedAddActionItem = {
        surveyItem,
        surveyComponentItem,
        proOrMatItem,
        surveyActionId: actionItem.surveyActionId,
        comments: actionItem.comments,
        actionKey,
        actionType: actionItem.actionType,
        surveyQuestionId: "PROMOS",
        selectableQuestionList: [
          { label: "Promos", value: "PROMOS" },
          { label: "Otros", value: "OTHERS" }
        ],
        selectableActions: []
      };
      let survey_questions = surveyComponentItem?.survey_questions;
      if (survey_questions.length > 0) {
        survey_questions.map(el => {
          this.selectedAddActionItem.selectableQuestionList.push({
            label: el.name,
            value: el.id
          });
        });
      }
      if (actionItem.surveyActionId) {
        let foundSurveyActionItem = this.surveyActionsForBrand.find(
          el => el.id === actionItem.surveyActionId
        );
        console.log("foundSurveyActionItem - ", foundSurveyActionItem);
        if (foundSurveyActionItem) {
          this.selectedAddActionItem.surveyQuestionId =
            foundSurveyActionItem.surveyQuestionId;
          this.selectableActions = this.surveyActionsForBrand.filter(
            el =>
              el.surveyQuestionId ===
              this.selectedAddActionItem.surveyQuestionId
          );
        }
      } else {
        this.selectedAddActionItem.surveyQuestionId = this.selectedAddActionItem.actionType;
      }
      this.showAddActionDialog = true;
    },
    onClickRemoveAction(
      surveyItem,
      surveyComponentItem,
      proOrMatItem,
      actionKey
    ) {
      let proOrMatId = proOrMatItem?.id ? proOrMatItem.id : null;
      this.surveys_response_actions["surveys"][surveyItem.id]["components"][
        surveyComponentItem.id
      ]["proOrMatList"][proOrMatId]["actions"].splice(actionKey);
    },
    isAnsweredForAllMandatoryQuestions() {
      let isAnswered = true;
      let isReadAlert = true;
      let isPortalCompleted = true;
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions.surveys
      )) {
        for (const [surveyComponentId, componentItem] of Object.entries(
          surveyItem.components
        )) {
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem.proOrMatList
          )) {
            for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
              proOrMatItem?.questions
            )) {
              if (
                !surveyQuestionItem.isMandatory ||
                !surveyQuestionItem?.isCondVisible ||
                surveyQuestionItem?.questionImportType == "IMPORT_DATA" ||
                !componentItem?.isCondVisible ||
                ["PHONE"].includes(this.currentVisitType)
              ) {
                continue;
              }
              let isCompleted = this.isValueFullfilled(
                surveyQuestionItem.questionType,
                surveyQuestionItem
              );
              if (!isCompleted) isAnswered = false;
              if (!isAnswered) break;
              if (
                [QUESTION_TYPES.PHOTO].includes(surveyQuestionItem.questionType)
              ) {
                let isPhotoPortalCompleted = this.isPortalPhotosFullfilled(
                  surveyQuestionItem.questionType,
                  surveyQuestionItem
                );
                if (!isPhotoPortalCompleted) isPortalCompleted = false;
                if (!isPortalCompleted) break;
              }
            }
            if (!isAnswered) break;
            if (
              proOrMatItem?.alertList &&
              proOrMatItem?.alertList.length > 0 &&
              proOrMatItem?.isReadProductAlert === false
            ) {
              isReadAlert = false;
            }
          }
          if (!isAnswered) break;
        }
        if (!isAnswered) break;
      }
      return { isAnswered, isReadAlert, isPortalCompleted };
    },
    getNewAttachedPhotoList() {
      let newAttachedPhotosList = [];
      for (const [surveyId, surveyItem] of Object.entries(
        this.surveys_response_actions.surveys
      )) {
        for (const [surveyComponentId, componentItem] of Object.entries(
          surveyItem.components
        )) {
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem.proOrMatList
          )) {
            for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
              proOrMatItem?.questions
            )) {
              if (
                surveyQuestionItem.questionType === QUESTION_TYPES.PHOTO &&
                surveyQuestionItem.value
              ) {
                for (let i = 0; i < surveyQuestionItem.value.length; i++) {
                  let pushItem = {
                    surveyId,
                    surveyQuestionId,
                    proOrMatId,
                    fileObject: surveyQuestionItem.value[i]
                  };
                  newAttachedPhotosList.push(pushItem);
                }
              }
            }
          }
        }
      }
      return newAttachedPhotosList;
    },
    async onSaveClick() {
      if (this.isEndedSession) {
        this.goNextPageAfterSaving();
        return;
      }
      const {
        isPortalCompleted,
        isAnswered,
        isReadAlert
      } = this.isAnsweredForAllMandatoryQuestions();
      if (!isAnswered) {
        logError("Debe responder a todas las preguntas.");
        return;
      }
      if (!isPortalCompleted) {
        logError("Debes dejar comentarios y puntuaciones para el portal.");
        return;
      }
      if (!isReadAlert) {
        logError("tienes alertas sin leer.");
        return;
      }
      this.isLoadingSpinner = true;
      this.isSaving = true;
      // let newAttachedPhotos = this.getNewAttachedPhotoList();
      let uploadedPhotos = [];
      // for (const attachedItem of newAttachedPhotos) {
      //   let { data } = await ApiService.uploadImage(attachedItem.fileObject);
      //   let uploadedStatusPushItem = {
      //     surveyId: attachedItem.surveyId,
      //     surveyQuestionId: attachedItem.surveyQuestionId,
      //     proOrMatId: attachedItem.proOrMatId,
      //     photoId: data.id
      //   };
      //   uploadedPhotos.push(uploadedStatusPushItem);
      // }
      if (!this.surveys_promos_answers) this.surveys_promos_answers = [];
      let body = {
        surveys_response_actions: {},
        surveys_promos_answers: this.surveys_promos_answers,
        uploadedPhotos,
        worksessionPosBrandId: this.worksessionPosBrandId,
        worksessionPosId: this.worksessionPosId,
        currentPosition: this.currentPosition
        // gmapAddress: this.currentGmapAddress
      };
      try {
        body.surveys_response_actions = this.parseSaveValues(
          this.surveys_response_actions
        );
        console.log(
          "body.surveys_response_actions - ",
          body.surveys_response_actions
        );
        await ApiService.post(`worksession/saveSessionSurveyDataV2`, body);
        this.isSaving = false;
        logInfo("Éxito");
        // await this.init(this.selectedBrandId);
        this.goNextPageAfterSaving();
      } catch (error) {
        console.log("error - ", error);
        logError("Network error !");
        this.isSaving = false;
        this.saveOfflineAllData(body);
      }
      this.isLoadingSpinner = false;
    },
    saveOfflineAllData(formBody) {
      console.log(
        "XXXXXXXXXXXX - SAVE OFFLINE ALL DATA - XXXXXXXXXXXXXX",
        formBody
      );
      this.$store.dispatch("myroute/setIsOffline", true);
      OfflineService.saveOfflineAllData(formBody);
      // redirect offline page !
      this.$router.push({
        name: "router_offline_page"
      });
    },
    goNextPageAfterSaving() {
      const { posId } = this.posSession;
      let isGoSalesPage = false;
      if (
        posId &&
        this.selectedBrandItem &&
        this.selectedBrandItem.brand_perfectstore_objectives.length > 0
      ) {
        let foundPSObjective = this.selectedBrandItem.brand_perfectstore_objectives.find(
          el => el.posId === posId
        );
        if (
          foundPSObjective &&
          (foundPSObjective.bpoSales === "SUGGESTED_ORDER" ||
            foundPSObjective.bpoSales === "FIRM_ORDER")
        ) {
          isGoSalesPage = true;
        }
      }

      if (!isGoSalesPage) {
        this.$router.push({
          name: "router_worksessionpospage",
          params: {
            id: this.sessionId,
            worksessionPosId: this.worksessionPosId,
            frompage: "survey",
            brandId: this.selectedBrandId
          }
        });
      } else {
        console.log("----- go sales page ! -----");
        this.$router.push({
          name: "router_worksessionsales",
          params: {
            id: this.sessionId,
            worksessionPosId: this.worksessionPosId,
            worksessionPosBrandId: this.worksessionPosBrandId
          }
        });
      }
    },
    parseSaveValues(surveys_response_actions) {
      let saveValues = {};
      const {
        isCompletedSurveyBrand,
        surveyBrandScore
      } = surveys_response_actions;
      saveValues = {
        isCompletedSurveyBrand,
        surveyBrandScore,
        surveys: {}
      };
      for (const [surveyId, surveyItem] of Object.entries(
        surveys_response_actions.surveys
      )) {
        let worksession_survey_data = {
          score: surveyItem.score,
          isCompleted: surveyItem.isCompleted,
          isMandatoryCompleted: surveyItem.isMandatoryCompleted,
          surveyId,
          components: {}
        };
        for (const [surveyComponentId, componentItem] of Object.entries(
          surveyItem.components
        )) {
          const { assignType, survey_component_options } = componentItem;
          const isCondVisibleComp = componentItem.isCondVisible;
          if (!isCondVisibleComp) continue;
          let componentItemData = {
            assignType,
            survey_component_options,
            proOrMatList: {}
          };
          for (const [proOrMatId, proOrMatItem] of Object.entries(
            componentItem.proOrMatList
          )) {
            const { actions } = proOrMatItem;
            let proOrMatItemData = {
              actions,
              questions: {}
            };
            for (const [surveyQuestionId, surveyQuestionItem] of Object.entries(
              proOrMatItem?.questions
            )) {
              const {
                isFromLastResponse,
                attachments,
                questionType,
                objectiveRealValueForActionPush,
                value,
                score,
                isCondVisible,
                maximumScore
              } = surveyQuestionItem;
              if (!isCondVisible) continue;
              let surveyQuestionItemData = {
                prevAttachments: [],
                questionType,
                objectiveRealValueForActionPush,
                value,
                score,
                isCondVisible: isCondVisibleComp && isCondVisible,
                maximumScore
              };
              if (
                [QUESTION_TYPES.PHOTO].includes(questionType) &&
                attachments &&
                attachments.length > 0 &&
                isFromLastResponse
              ) {
                attachments.map(el => {
                  let prev_attachment_item = {
                    photoId: el.photoId,
                    workSessionSurveyResponseId: el.workSessionSurveyResponseId
                  };
                  surveyQuestionItemData.prevAttachments.push(
                    prev_attachment_item
                  );
                });
              }
              surveyQuestionItemData[
                "kpiObjectiveValue"
              ] = this.getImportedAndKPIObjectiveValue(surveyQuestionItem);
              proOrMatItemData.questions[
                surveyQuestionId
              ] = surveyQuestionItemData;
            }
            componentItemData.proOrMatList[proOrMatId] = proOrMatItemData;
          }
          worksession_survey_data.components[
            surveyComponentId
          ] = componentItemData;
        }
        saveValues.surveys[surveyId] = worksession_survey_data;
      }
      this.saveValues = saveValues;
      return saveValues;
    },
    getTotalEstimatedTime(surveys_response_actions) {
      let totalSeconds = 0;
      if (
        surveys_response_actions &&
        Object.values(surveys_response_actions?.surveys)
      ) {
        Object.values(surveys_response_actions?.surveys).map(surveyItem => {
          if (surveyItem.estimatedTime) {
            let [hours, mins, seconds] = surveyItem.estimatedTime.split(":");
            hours = parseInt(hours);
            mins = parseInt(mins);
            seconds = parseInt(seconds);
            totalSeconds += hours * 3600 + mins * 60 + seconds;
          }
        });
      }
      this.totalEstimatedTimeSeconds = totalSeconds;
      this.totalEstimatedTimeDuration = this.get_duration_format(
        this.totalEstimatedTimeSeconds * 1000
      );
    },
    logTimeTick() {
      this.current_worked_time_seconds++;
      this.currentWorkedTime = this.get_duration_format(
        this.current_worked_time_seconds * 1000
      );
      if (this.isExceedWorkTime) {
        if (this.logTimeIntervalId !== null) {
          clearInterval(this.logTimeIntervalId);
        }
        window.alert(
          "Has excedido el tiempo de visita, por favor agiliza la toma de información."
        );
      }
    },
    async getSessionSurveyData() {
      const {
        // survey_list,
        surveys_response_actions,
        surveyChains,
        surveyActionsForBrand,
        worksessionPosBrandId,
        posBrandPSObjective,
        posBrandPSLastVisit,
        componentConditionals,
        questionConditionals,
        mergedFamlies,
        surveys_promos_answers,
        selectedBrandItem,
        approvalStatus,
        assignTypes
      } = await ApiService.post(
        `worksession/getSessionSurveyDataV2/${this.worksessionPosId}/${this.selectedBrandId}`,
        { posSessionChainCriteria: this.posSessionChainCriteria }
      );
      // this.survey_list = survey_list;
      this.surveys_response_actions = surveys_response_actions;
      this.surveys_promos_answers = surveys_promos_answers;
      if (!this.surveys_promos_answers) this.surveys_promos_answers = [];
      this.surveyChains = surveyChains;
      this.surveyActionsForBrand = surveyActionsForBrand;
      this.worksessionPosBrandId = worksessionPosBrandId;
      this.posBrandPSObjective = posBrandPSObjective;
      this.posBrandPSLastVisit = posBrandPSLastVisit;
      this.componentConditionals = componentConditionals;
      this.questionConditionals = questionConditionals;
      this.mergedFamlies = mergedFamlies;
      this.selectedBrandItem = selectedBrandItem;
      this.approvalStatus = approvalStatus;
      this.assignTypes = assignTypes;
      this.getTotalEstimatedTime(surveys_response_actions);
      this.logTimeIntervalId = setInterval(this.logTimeTick, 1000);

      if (
        this.surveyChains &&
        this.surveyChains.length > 0 &&
        this.posSessionChainCriteria
      ) {
        let foundSurveyChainItem = this.surveyChains.find(
          el =>
            el.chainId === this.posSessionChainCriteria.chainId &&
            el.subChainId === this.posSessionChainCriteria.subChainId &&
            el.sub2ChainId === this.posSessionChainCriteria.sub2ChainId
        );
        if (foundSurveyChainItem) {
          this.detectedSurveyChainIdForPuncDetails = foundSurveyChainItem.id;
        }
      } else {
        this.detectedSurveyChainIdForPuncDetails = null;
      }
      // this.initVisibility();
      // this.initFilterCriteria();
      this.setVisibleByConditionalsAndCalculating();
      this.calcPoints();
    },
    filterAvailablePromos(promosList) {
      let curDate = new Date();
      const today = curDate.toISOString().slice(0, 10);
      var endDate = new Date(new Date().setDate(curDate.getDate() + 30));
      const endday = endDate.toISOString().slice(0, 10);
      let filteredPromos = promosList.filter(el => {
        if (el.statusKey === "ACTIVE" || el.statusKey === "PREPARE") {
          return true;
        }
        if (el.startDate <= endday && el.endDate >= today) return true;
        return false;
      });
      return filteredPromos;
    },
    async init(activeBrandId) {
      this.totalEstimatedTimeSeconds = null;
      this.totalEstimatedTimeDuration = null;
      this.current_worked_time_seconds = 0;
      this.currentWorkedTime = 0;
      if (this.logTimeIntervalId !== null) {
        clearInterval(this.logTimeIntervalId);
      }
      const { id, worksessionPosId } = this.$route.params;
      this.sessionId = id;
      this.worksessionPosId = worksessionPosId;
      const {
        data,
        reasonTypes,
        worksessionPosBrands,
        promosTypes,
        placeTypes,
        folletoTypes,
        actionTypes,
        actionTypeList,
        IS_PRESENT
      } = await ApiService.get(`worksession/pos/${this.worksessionPosId}`);
      this.promosTypeList = promosTypes;
      this.placeTypeList = placeTypes;
      this.folletoTypeList = folletoTypes;
      this.isPresentTypeList = IS_PRESENT;
      this.actionTypes = actionTypes;
      this.actionTypeList = actionTypeList;
      this.posSession = data;
      this.worksessionPosBrands = worksessionPosBrands;

      if (this.posSession && this.posSession?.po) {
        const { chainId, subChainId, sub2ChainId } = this.posSession.po;
        this.posSessionChainCriteria = {
          chainId,
          subChainId,
          sub2ChainId
        };
      }

      if (activeBrandId) {
        this.selectedBrandId = parseInt(activeBrandId);
      } else if (this.brandId) {
        this.selectedBrandId = parseInt(this.brandId);
      }
      if (this.selectedBrandId) {
        this.selectedBrand = this.worksessionPosBrands.find(
          el => el.id === this.selectedBrandId
        );
        let foundBrandItem = this.worksessionPosBrands.find(
          el => el.id === this.selectedBrandId
        );
        if (foundBrandItem) {
          this.currentPromosForSelectedBrand = this.filterAvailablePromos(
            foundBrandItem?.brand_promos
          );
        } else {
          this.currentPromosForSelectedBrand = [];
        }
        await this.getSessionSurveyData();
      }
      this.initialFilterCriteria = JSON.parse(
        JSON.stringify(this.filterCriteria)
      );
    },
    mergeOperators(srcObject, operators) {
      for (const [operator, optLabel] of Object.entries(operators)) {
        srcObject[operator] = optLabel;
      }
      return srcObject;
    }
  },

  async mounted() {
    let savedShowType = StorageService.getToken(
      StorageService.KEY_SHOW_TYPE_IN_SURVEY
    );
    if (savedShowType) {
      this.showType = savedShowType;
    }
    this.COMPARE_OPRATORS = this.mergeOperators(
      this.COMPARE_OPRATORS,
      OP_STRING
    );
    this.COMPARE_OPRATORS = this.mergeOperators(this.COMPARE_OPRATORS, OP_BOOL);
    this.COMPARE_OPRATORS = this.mergeOperators(
      this.COMPARE_OPRATORS,
      OP_NUMBER
    );
    this.init(undefined);
  },
  destroyed() {
    if (this.logTimeIntervalId !== null) {
      clearInterval(this.logTimeIntervalId);
    }
  }
};
</script>

<style>
.title_component {
  background: #666;
  padding: 0 10px;
  color: #fff;
  font-weight: 300 !important;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.v-chip.v-size--default.added_action_chip {
  height: auto;
}
.sectionTitle {
  background: #ddd;
  padding: 10px;
  margin: 0 !important;
}
.sectionContents {
  padding: 10px;
}
.survey_pro_divider {
  background: #666;
  max-height: 10px !important;
  height: 10px;
  border: none;
}
.active_filter {
  background: #f35656;
  color: #fff;
}
.btn_color_white {
  color: #fff !important;
}
.v-chip.v-size--default.added_action_chip.chip_promo_local {
  background: #dd7575;
}
.survey_answers_tb tr th {
  background: #faebd7;
}
.position_relative {
  position: relative;
}
/* .modal_close_button {
  position: absolute !important;
  top: 16px;
  left: calc(50% - 20px);
} */
.survey_wrapper {
  position: relative;
}
.survey_wrapper_has_others_bottom {
  padding-bottom: 200px;
}
.survey_wrapper .others_bottom_wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 20;
  margin-bottom: 0;
  overflow: hidden;
  border-top: 3px solid #3f3a3a;
}
.survey_wrapper .others_bottom_wrapper .others_components_wrapper {
  max-height: 200px;
  height: 200px;
  overflow-y: scroll;
}
</style>
